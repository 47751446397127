<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="header">
          <h4 class="title">Crear Template</h4>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.first('name') }">
                <label>Nombre</label>
                <input v-model="template.name" class="form-control border-input" v-validate="'required'" name="name"
                  type="text" placeholder="Nombre" />
                <span class="help-block text-left">{{ errors.first('name') }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Tipo de plantilla</label>
                <select class="form-control border-input" v-model="template.type">
                  <option value="QUOTE">Cotizacion</option>
                  <option value="WARRANTY">Garantia</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Motor de plantillas</label>
                <select class="form-control border-input" v-model="template.engine">
                  <option value="HBS">Handlebars</option>
                  <option value="EJS">EJS</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <tabs @change="onChangeTab">
                <tab>
                  <div slot="title"><i class='ti-html5'></i> Vista previa</div>
                  <iframe 
                    :key="refreshKey"
                    v-if="id !== null" 
                    style="width: 100%; min-height: 550px"
                    v-bind:srcdoc="render(template.html) + `<style>${template.css}</style>`" 
                    frameborder="0"></iframe>
                  <!-- <div v-html="template.html" style="width: 100%; min-height: 500px"></div> -->
                </tab>
                <tab>
                  <div slot="title"><i class='ti-css3'></i> CSS</div>
                  <editor v-model="template.css" @init="editorInit" lang="css" theme="xcode" width="100%" height="550" />
                </tab>
                <tab>
                  <div slot="title"><i class='ti-pencil-alt'></i> HTML</div>
                  <editor v-model="template.html" lang="html" theme="xcode" width="100%" height="550" />
                </tab>
                <tab>
                  <div slot="title"><i class='ti-shortcode'></i> Json</div>
                  <editor v-model="template.json" lang="json" theme="json" width="100%" height="550" />
                </tab>
                <form slot="nav-right" class="hidden-sm visible-md visible-lg rh-group-buttons">
                  <btn :disabled="errors.any() || !isCompleted" @click="saveTemplate()" type="primary">
                    <i class="ti-save"></i> Guardar
                  </btn>

                  <router-link class="btn btn-default" :to="`/admin/templates`">
                    Salir
                  </router-link>
                </form>
              </tabs>
            </div>

            <div class="col-md-12">
              <br />
              <form slot="nav-right" class="visible-sm hidden-md hidden-lg">
                <btn :disabled="errors.any() || !isCompleted || saving" @click="saveTemplate()" type="primary">
                  <i class="ti-save"></i> Guardar
                </btn>

                <router-link class="btn btn-default" :to="`/admin/templates`">
                  Salir
                </router-link>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <v-style>
      {{template.css}}
    </v-style> -->
  </div>
</template>
<script>
import Editor from 'vue2-ace-editor'
import constants from '../constants'
import { Tabs, Tab } from 'uiv'
import axios from 'axios'
import * as Handlebars from 'handlebars'
import getHelper from '../utils/helpers';
//import ejs from 'ejs'

const colomnsTable = [
  'Nombre',
  'Acciones'
]

export default {
  components: {
    Tabs,
    Tab,
    Editor
  },
  data() {
    return {
      colomnsTable: [...colomnsTable],
      saving: false,
      options: {
        uploadUrl: `${constants.URL}/products/upload`,
        placeholder: {
          text: ''
        }
      },
      id: null,
      // json: {},
      template: {
        name: '',
        html: '',
        css: '',
        json: '{}',
        type: 'QUOTE',
        engine: 'HBS'
      },
      refreshKey: 0,
    }
  },
  created() {
    this.id = this.$route.params.id
    if (this.id) {
      this.fetchTemplate(this.id)
    }
  },
  computed: {
    isCompleted() {
      return this.template.name && !this.isSaved
    },
    url() {
      return constants.URL
    }
  },
  mounted() {
    this.registerFunctionsHbs();
  },
  watch: {},
  methods: {
    fetchTemplate(id) {
      this.saving = true
      axios
        .get(`${constants.URL}/templates/${id}`)
        .then(result => {
          this.template = {
            ...result.data,
            json: result.data.json || '{}'
          }
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    saveTemplate() {
      this.saving = true
      if (this.id) {
        this.updateTemplate()
      } else {
        this.addTemplate()
      }
    },
    addTemplate() {
      this.saving = true
      axios
        .post(`${constants.URL}/templates`, this.template)
        .then(result => {
          this.$notifications.notifyVue('Cambio realizado exitosamente', 'success')
          this.id = result.data._id
          this.saving = false
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    updateTemplate() {
      delete this.template._id
      delete this.template.__v
      delete this.template.createAt
      delete this.template.owner
      delete this.template.company
      this.saving = true
      axios
        .put(`${constants.URL}/templates/${this.id}`, this.template)
        .then(() => {
          this.saving = false
          this.$notifications.notifyVue('Cambio realizado exitosamente', 'success')
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    editorInit: function () {
      // require('brace/ext/language_tools') // language extension prerequsite...
      // require('brace/mode/html')
      // require('brace/mode/javascript') // language
      // require('brace/mode/less')
      // require('brace/theme/xcode')
      // require('brace/snippets/javascript') // snippet
    },
    onChangeTab(index) {
      if (index === 0) {
        this.refreshIframe();
      }
    },
    refreshIframe() {
      this.refreshKey++;
    },
    render() {
      try {
        const data = {
          ...getHelper('EJS'),
          ...JSON.parse(this.template.json),
          template: this.template
        }
        if (this.template.engine === 'HBS') {
          return Handlebars.compile(this.template.html)(data);
        } else {
          // eslint-disable-next-line
          return ejs.render(this.template.html, data);
        }
      } catch (error) {
        return error;
      }
    },
    registerFunctionsHbs() {
      const helpers = getHelper('HBS');
      Handlebars.registerHelper(helpers);
    },
  }
}
</script>
