<template>
  <div style="background: #f4f3ef; height: 100%;">
    <notifications />
    <div class="container-fluid">
      <div class="row" id="pwd-container">
        <div class="col-md-6 col-lg-4 col-md-offset-3 col-lg-offset-4 form">
          <div class="card login-form-card">
            <div class="content">
              <section class="login-form">
                <form>
                  <img :src="image" class="img-responsive" alt>
                  <h3 class="text-center">Nueva constraseña</h3>

                  <div class="form-group" :class="{ 'has-error': errors.first('password') }">
                    <label for="password">Contraseña</label>
                    <input 
                      v-model="credentials.password" 
                      class="form-control border-input" 
                      v-validate="{ 
                        required: true, 
                        min: 10, 
                        regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ 
                      }"
                      name="password" 
                      type="password" 
                      ref="password"
                      v-on:keyup.enter="submit()"
                      placeholder="" />
                    <span class="help-block text-left">{{ errors.first('password') }}</span>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('password_confirmation') }">
                    <label for="confirm_password">Confirmar contraseña</label>
                    <input 
                      v-model="credentials.passwordConfirmation" 
                      class="form-control border-input" 
                      v-validate="'required|confirmed:password'"
                      name="password_confirmation" 
                      type="password" 
                      data-vv-as="password"
                      v-on:keyup.enter="submit()"
                      placeholder="" />
                    <span class="help-block text-left">{{ errors.first('password_confirmation') }}</span>
                  </div>

                  <button 
                    type="button" 
                    @click="submit()" name="go"
                    :disabled="errors.any() || !isCompleted || isLoading" 
                    class="btn btn-lg btn-success btn-block">
                    Enviar
                  </button>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="copyright">
      <div class="pull-right">
        &copy; 2023, made with
        <i class="fa fa-heart heart"></i> by Reach lab
      </div>
    </footer>
  </div>
</template>
<style media="screen">
.progress-bar {
  color: #333;
}

.form {
  height: 100%;
}
</style>

<script>
import constants from '@/constants'
import axios from 'axios'

export default {
  data() {
    return {
      credentials: {
        password: '',
        passwordConfirmation: ''
      },
      image: 'https://quote.reachportones.com/static/img/logo.png',
      isLoading: false
    }
  },
  components: {},
  computed: {
    isCompleted() {
      return this.credentials.password && this.credentials.passwordConfirmation
    }
  },
  methods: {
    notifyVue(message, type) {
      this.$notifications.notify({
        message,
        // icon: 'ti-gift',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type
      })
    },
    async submit() {
      try {
        this.isLoading = true
        const { password } = this.credentials;
        const url = `${constants.URL}/users/reset/${this.$route.params.id}`;
        await axios.put(url, { password })
        this.notifyVue('Tu contraseña se ha cambiado exitosamente', 'success')
        this.$router.push('/login')
      } catch (error) {
        this.notifyVue(`Ocurrio un error al intentar guardar`, 'danger');
        this.isLoading = false
      }
    }
  }
}
</script>
