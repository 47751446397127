<template>
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <div class="card card-user">
        <div class="header text-center">
          <h4 class="title">{{reminder.name}}</h4> 
          <a class="cursor-pointer context-menu" @click.prevent="openCxtMenu()">
            <i class="ti-more-alt" />
          </a>
        </div>
        <div class="content">
          <p class="description text-left">
            <b>Frecuencia:</b>
            {{reminder.when}}
          </p>
          <p class="description text-left">
            <b>Tipo:</b>
            {{getType(reminder.type)}}
          </p>
          
          <div v-if="reminder.reminder">
            <p class="description text-left">
              <b>Se repite:</b>
              {{ reminder.repeat ? 'Si' : 'No'}}
            </p>
            <p class="description text-left">
              <b>Tiene recodatorio:</b>
              {{ reminder.reminder.isActive ? 'Si' : 'No'}}
            </p>
            <p v-if="reminder.reminder.isActive" class="description text-left">
              <b>Frecuencia de recodatorio:</b>
              {{ reminder.reminder.when}}
            </p>
          </div>
          <p v-if="reminder.type === 'SUPPORT'" class="description text-left">
            <b>Client:</b>
            {{reminder.client.name}}
          </p>
          <p v-if="reminder.type === 'TRACKING'" class="description text-left">
            <b>Cotizacion:</b>
            {{reminder.quote.title}}
          </p>
          <p class="description text-left">
            <b>Estado:</b>
            <span class="label" 
                  v-bind:class="{ 
                    'label-warning': reminder.status === 'WAITING', 
                    'label-danger': reminder.status === 'CANCELLED', 
                    'label-success': reminder.status === 'EXECUTED', 
                    'label-primary': reminder.status === 'ACTIVE', 
            }">{{getStatus(reminder.status)}}</span>
          </p>
          <p class="description text-left">
            <b>Fecha de creacion:</b>
            {{reminder.createAt | formatDate}}
          </p>
          <p class="description text-left">
            <b>Proximo evento:</b>
            {{reminder.whenDate | formatDate}}
          </p>
          <p class="description text-left">
            <b>Notas:</b> <br />
            {{reminder.notes}}
          </p>
        </div>
        <hr>
        <div class="text-center">
          <div class="row">
            <div >
              <h5>
                <btn v-if="reminder.status === 'WAITING'" @click="modalPostpone=true" type="warning">
                  <i class="ti-timer"></i> Posponer
                </btn>

                <btn v-if="reminder.status === 'WAITING'" @click="modalChangeState=true" type="danger">
                  <i class="ti-flag"></i> {{reminder.repeat ? 'Reiniciar' : 'Finalizar'}}
                </btn>

                <btn v-if="reminder.status === 'ACTIVE'" @click="cancelModal=true" type="danger">
                  <i class="ti-na"></i> Cancelar
                </btn>

                <btn v-if="reminder.status === 'EXECUTED' || reminder.status === 'CANCELLED'" @click="modalRemove=true" type="danger">
                  <i class="ti-trash"></i>  Eliminar
                </btn>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model="cancelModal" title="Cancelar" :header="false" :footer="false" size="sm">
      Desea cancelar este recordatorio?
      <div class="text-center">
        <br />
        <btn @click="cancelModal=false">Cancel</btn>
        <btn type="danger" :disabled="saving" @click="cancelReminder()">Aceptar</btn>
      </div>
    </modal>

    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar este recordatorio?
      <div class="text-center">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" :disabled="saving" @click="deleteReminder()">Eliminar</btn>
      </div>
    </modal>
    <modal v-model="modalChangeState" title="Reestablecer" :header="false" :footer="false" size="sm">
      Desea cambiar el estado?
      <div class="text-center">
        <br />
        <btn @click="modalChangeState=false">Cancel</btn>
        <btn :disabled="saving" type="danger" @click="restartOrFinishReminder()">Aceptar</btn>
      </div>
    </modal>

    <modal v-model="modalPostpone" title="Postponer" size="md">
      <form>
        <div class="row">
        
        <div class="col-md-4">
            <div class="form-group" :class="{'has-error': errors.first('amount')}">
              <label>Periodo</label>
              <input
                v-model="postpone.amount"
                class="form-control border-input"
                v-validate="'required'"
                min="1"
                name="amount"
                type="number"/>
              <span class="help-block text-left">{{ errors.first('amount') }}</span>
            </div>
          </div>

          <div class="col-md-8">
            <div class="form-group" :class="{'has-error': errors.first('unit')}">
              <label>Periodo</label>
              <select class="form-control border-input" v-model="postpone.unit">
                    <option value="minutes">Minutos</option>
                    <option value="hours">Horas</option>
                    <option value="days">Dias</option>
                    <option value="months">Meses</option>
                    <option value="years">Años</option>
                    <!-- <option value="custom">Personalizado</option> -->
              </select>
              <span class="help-block text-left">{{ errors.first('amount') }}</span>
            </div>
          </div>

        </div>
      </form>
      <div slot="footer">
        <btn @click="modalPostpone=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any()" @click="postponeReminder() || saving">
          Guardar
        </btn>
      </div>
    </modal>

    <ReminderForm
      ref="reminderForm"
      :clientField="true"
      :modelProp="false"
      :action="reminderFormAction"
      v-model="reminderFormModel"
      @save="saveReminder" />

      <context-menu class="cursor-pointer" id="context-menu" ref="ctxMenu">
        <li @click="editReminder()">
         <i class="ti-pencil-alt"></i> Editar
        </li>
        <li @click="restartReminder()">
         <i class="ti-timer"></i> Reprogramar
        </li>
        <li v-if="reminder.status === 'WAITING'" @click="modalChangeState=true">
          <i class="ti-flag"></i> {{reminder.repeat ? 'Reiniciar' : 'Finalizar'}}
        </li>
        <li class="text-warning" 
          v-if="reminder.status === 'WAITING' || reminder.status === 'ACTIVE'" 
          @click="cancelModal=true">
          <i class="ti-na"></i>  Cancelar
        </li>
        <li class="text-danger" @click="modalRemove=true">
          <i class="ti-trash"></i>  Eliminar
        </li>
      </context-menu>
  </div>
</template>
<style>
  .context-menu {
      position: absolute;
      top: 24px;
      right: 22px;
  }
</style>
<script>
  import axios from 'axios'
  import constants from '../constants'
  import { Modal, Btn } from 'uiv'
  import ReminderForm from '../components/Forms/ReminderForm'
  import contextMenu from 'vue-context-menu'

  export default {
    components: {
      Modal,
      Btn,
      ReminderForm,
      contextMenu
    },
    created () { },
    computed: {
      url () {
        return constants.URL
      },
      id () {
        return this.$route.params.id
      }
    },
    mounted () {
      this.getReminder()
    },
    data () {
      return {
        reminder: {},
        cancelModal: false,
        modalRemove: false,
        modalChangeState: false,
        reminderFormAction: 'CREATE',
        reminderFormModel: null,
        modalPostpone: false,
        saving: false,
        postpone: {
          amount: 3,
          unit: 'hours'
        }
      }
    },
    methods: {
      getReminder () {
        axios
          .get(`${constants.URL}/reminders/${this.id}`)
          .then(response => {
            this.loading = false
            this.reminder = response.data
          })
          .catch(error => {
            this.loading = false
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
          })
      },
      openCxtMenu () {
        this.$refs.ctxMenu.open()
      },
      restartReminder () {
        this.reminderFormModel = this.reminder
        this.reminderFormAction = 'SET'
        this.$refs.reminderForm.show(this.reminderFormAction)
      },
      editReminder () {
        this.reminderFormModel = this.reminder
        this.reminderFormAction = 'UPDATE'
        this.$refs.reminderForm.show(this.reminderFormAction)
      },
      postponeReminder () {
        const params = {
          action: 'POSTPONE',
          when: `${this.postpone.amount} ${this.postpone.unit}`
        }
        axios
          .put(`${constants.URL}/reminders/changeState/${this.reminder._id}`, params)
          .then(() => {
            this.getReminder()
            this.modalPostpone = false
          }).catch(error => {
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
          })
      },
      cancelReminder () {
        this.saving = true
        axios
          .put(`${constants.URL}/reminders/changeState/${this.reminder._id}`, { action: 'CANCEL' })
          .then(() => {
            this.reminder.status = 'CANCELLED'
            this.cancelModal = false
            this.saving = false
          }).catch(error => {
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
            this.saving = false
          })
      },
      deleteReminder () {
        this.saving = true
        axios
          .delete(`${constants.URL}/reminders/${this.reminder._id}`)
          .then(() => {
            this.saving = false
            this.modalRemove = false
            this.$notifications.notifyVue(`El recordatorio ha sido eliminado`, 'success')
            this.$router.push(`/admin/reminders`)
          }).catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
          })
      },
      restartOrFinishReminder () {
        this.saving = true
        const action = this.reminder.repeat ? 'RESTART' : 'FINISH'
        axios
          .put(`${constants.URL}/reminders/changeState/${this.reminder._id}`, { action })
          .then(() => {
            this.getReminder()
            this.$notifications.notifyVue('El recordatorio ha sido actualizado exitosamente', 'info')
            this.saving = false
          }).catch(error => {
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
            this.saving = false
          })
        this.modalChangeState = false
      },
      getType (type) {
        switch (type) {
          case 'TRACKING':
            return 'Seguimiento'
          case 'SUPPORT':
            return 'Soporte'
          case 'GENERIC':
            return 'Generico'
        }
      },
      getStatus (status) {
        switch (status) {
          case 'WAITING':
            return 'Pendiente'
          case 'CANCELLED':
            return 'Cancelado'
          case 'EXECUTED':
            return 'Realizado'
          case 'ACTIVE':
            return 'Activo'
        }
      },
      saveReminder () {
        this.getReminder()
      }
    }
  }
</script>
