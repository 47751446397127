<template>
    <div class="tabs row-no-gutters">
        <div class="col-lg-2 tabs-nav-container">
            <ul class="nav nav-pills nav-stacked">
                <li v-for="(tab, index) in tabList" 
                    :class="{ 'active': index + 1 === activeTab}"
                    :key="index">
                    <a @click="selectTab(index)" class="cursor-pointer">{{ tab }}</a>
                </li>
            </ul>
        </div>
        <div class="col-lg-10 tabs-panel-container">
            <template v-for="(tab, index) in tabList">
                <div
                    :key="index"
                    v-if="index + 1 === activeTab"
                    class="">
                    <slot :name="`tabPanel-${index + 1}`" />
                </div>
            </template>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .tabs {
        height: 100%;
        .tabs-nav-container {
            height: 100%;
            border-right: 1px solid #eaecef;
        }
        .tabs-panel-container {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .nav-pills {
        li {
            &.active {
                a, a:hover, a:focus {
                    background-color: transparent;
                    color: #3eaf7c;
                    border: none;
                    outline: none;
                    border-left: 2px solid #3eaf7c;
                }
            }
            a {
                color: #a49e93;
            }
        }
    }
    .nav-stacked {
        li {
            a {
                text-align: left;
                padding: 5px 15px;
                font-size: 16px;
                font-weight: 600;
                border-radius: 0;
                border-left: 2px solid transparent;
            }
        }
    }
</style>
<script>
export default {
    name: 'Tabs',
    props: {
        tabList: {
            type: Array,
            required: true,
        },
        tabIndex: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            activeTab: this.tabIndex,
        };
    },
    mounted() {},
    methods: {
        selectTab(index) {
            this.activeTab = index + 1;
            this.$emit('selected', this.activeTab);
        }
    },
};
</script>