<template>
  <div style="background: #f4f3ef; height: 100%;">
    <notifications />
    <div class="container-fluid">
      <div class="row" id="pwd-container">
        <div class="col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 form">
          <div class="card login-form-card">
            <div class="content">
                <template v-if="!showErrorMessage">
                  <h3 class="text-center">Tienes una invitación para colaborar en una empresa</h3>

                  <p class="text-center invite-p">
                    Has recibido una invitación para colaborar en el proyecto llamado <b>{{ companyName }}</b>, 
                    para aceptar la invitación si ya tienes una cuenta puedes iniciar sesión en este enlace
                    <router-link :to="`/login`">
                      Iniciar sesión
                    </router-link>
                    y si aún no tienes una cuenta no te preocupes puedes registrarte con este link
                    <router-link :to="`/signup`">
                      ¡Quiero registrarme!
                    </router-link>
                  </p>
                </template>
                <template v-else>
                  <h3 class="text-center">Oh no! parece que tenemos un error</h3>

                  <p class="text-center invite-p">
                    <span class="text-danger">No encontramos ninguna invitación activa, posiblemente la invitación ya ha expirado, 
                    te recomendamos que pidas una nueva e intenta de nuevo,</span>
                    si ya tienes una cuenta puedes iniciar sesión en este enlace
                    <router-link :to="`/login`">
                      Iniciar sesión
                    </router-link>
                  </p>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="copyright">
      <div class="pull-right">
        &copy; 2023, made with
        <i class="fa fa-heart heart"></i> by Reach lab
      </div>
    </footer>
  </div>
</template>
<style media="screen" scoped>
  .invite-p {
    margin-top: 30px;
    font-size: 14px;
  }

  .content {
    padding-bottom: 30px !important;
  }
  
</style>

<script>
// import constants from '@/constants'
// import axios from 'axios'

export default {
  data() {
    return {
      showErrorMessage: false,
      companyName: ''
    }
  },
  components: {},
  computed: {},
  async mounted() {
    await this.validateToken();
  },
  methods: {
    notifyVue(message, type) {
      this.$notifications.notify({
        message,
        // icon: 'ti-gift',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type
      })
    },
    async validateToken() {
      try {
        const result = await this.$store.dispatch('validateInvite', this.$route.params.token);

        if (this.$store.getters.isLoggedIn) {
          this.$router.push('/');
        }
        this.companyName = result?.company?.name
        this.showErrorMessage = false;
      } catch (error) {
        this.showErrorMessage = true;
      }
    }
  }
}
</script>
