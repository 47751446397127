<template>
  <modal v-model="modalToggle" title="Crear producto" size="sm" :header="false">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Ordenar por</label>
              <select class="form-control border-input" v-model="byModel">
                <option v-for="item in by" :value="item" :key="item.id" :selected="byValue.id == item.id">
                    {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Criterio</label>
              <select class="form-control border-input" v-model="criteoModel">
                <option v-for="item in criteo" :value="item" :key="item.id">
                    {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer">
        <btn @click="modalToggle=false">Cancel</btn>
        <btn type="primary" @click="submit()">
          Aceptar
        </btn>
      </div>
    </modal>
</template>
<script>
  import { Modal, Btn } from 'uiv'
  export default{
    components: {
      Modal,
      Btn
    },
    props: {
      modalProp: Boolean,
      by: {
        type: Array,
        required: true
      },
      byValue: {
        type: Object
      },
      byCriteo: {
        type: Object
      },
      criteo: {
        type: Array,
        default: () => [
          {
            id: 'asc',
            name: 'Ascendente'
          },
          {
            id: 'desc',
            name: 'Descendente'
          }
        ]
      }
    },
    computed: {},
    data () {
      return {
        byModel: this.byValue,
        criteoModel: this.byCriteo,
        modalToggle: this.modalProp
      }
    },
    methods: {
      show () {
        this.modalToggle = true
      },
      submit () {
        this.modalToggle = false
        this.$emit('update', { by: this.byModel.id, criteo: this.criteoModel.id })
      }
    }
  }
</script>
