import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    company: localStorage.getItem('company') || '',
    workspaceList: localStorage.getItem('workspaceList') ?? [],
    user: {},
    inviteToken: localStorage.getItem('inviteToken') || '',
    contentSize: 'padding',
    view: localStorage.getItem('view') || null
  },
  mutations,
  actions,
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  }
})
