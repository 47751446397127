<template>
  <modal v-model="modalToggle" title="Crear producto" size="lg">
    <form>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" :class="{ 'has-error': errors.first('name') }">
            <label>Nombre</label>
            <input v-model="product.name" class="form-control border-input" v-validate="'required'" name="name"
              type="text" placeholder="Nombre" />
            <span class="help-block text-left">{{ errors.first('name') }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Tipo de producto</label>
            <select class="form-control border-input" v-model="product.type">
              <option value="SERVICE">Servicio</option>
              <option value="PRODUCT">Producto fisico</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" :class="{ 'has-error': errors.first('price') }">
            <label>Precio</label>
            <input v-model="product.price" class="form-control border-input" v-validate="'required'" name="price"
              type="number" placeholder="Precio">
            <span class="help-block text-left">{{ errors.first('price') }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <media-buttons v-model="image" @change="onChangeFile" @showMedia="$refs.mediaList.show()" />
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Descripcion </label>
            <form-textarea ref="formTextarea" v-model="product.description" :cssClass="`form-control border-input`" placeholder="Descripcion"></form-textarea>
            <!-- <textarea v-model="product.description" class="form-control border-input" placeholder="Descripcion" /> -->
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <media-select :showModal="false" ref="mediaList" @selected="onImageSelected" />
    </form>
    <div slot="footer">
      <btn @click="modalToggle = false">Cancel</btn>
      <btn type="primary" :disabled="errors.any() || !isCompleted" @click="save() || saving">
        Guardar
      </btn>
    </div>
  </modal>
</template>
<script>
import { Modal, Btn } from 'uiv'
import axios from 'axios'
import constants from '../../constants'
import MediaButtons from '../../components/Media/MediaButtons.vue'
import MediaSelect from '../../components/Media/MediaSelect.vue'
import formTextarea from '../Inputs/formTextarea.vue'

const productSchema = {
  name: '',
  description: '',
  price: '',
  type: 'PRODUCT'
}

export default {
  components: {
    Modal,
    Btn,
    MediaButtons,
    MediaSelect,
    formTextarea
  },
  props: {
    modalProp: Boolean,
    value: Object
  },
  watch: {
    value(v) {
      this.product = v
    }
  },
  computed: {
    isCompleted() {
      return this.product.name &&
        this.product.price &&
        this.product.type
    }
  },
  data() {
    return {
      image: '',
      fieldName: '',
      imageFile: '',
      mediaFile: {},
      saving: false,
      modalToggle: this.modalProp,
      product: { ...productSchema }
    }
  },
  methods: {
    show(action, product) {
      if (action === 'ADD') {
        this.image = ''
        this.product = { ...productSchema }
      } else {
        this.product = product
        this.image = product?.media?.meta?.imageThumb?.url;
        this.imageFile = null;
      }
      this.$refs.formTextarea.reset()
      this.$validator.reset()
      this.modalToggle = true
    },
    async updateProduct() {
      try {
        const productReq = { ...this.product }
        const id = productReq._id
        delete productReq._id
        delete productReq.__v
        delete productReq.owner
        delete productReq.company

        productReq.description = this.text2HTML(this.product.description);

        if (this.imageFile) {
          const formData = new FormData()
          formData.append(this.fieldName, this.imageFile)
          const imageRes = await axios.post(`${constants.URL}/media/upload`, formData)

          const mediaReq = {
            path: imageRes.data.imageFullSize.url,
            name: `${productReq.name}-logo`,
            meta: imageRes.data
          }
          const mediaResponse = await axios.post(`${constants.URL}/media`, mediaReq)
          productReq.media = mediaResponse.data._id
        } else if (!this.imageFile && this.image !== '') {
          productReq.media = this.mediaFile._id
        } else {
          productReq.media = null;
        }
        await axios.put(`${constants.URL}/products/${id}`, productReq)
        this.modalToggle = false
        this.saving = false
        this.$emit('save', 'ok')
        this.$notifications.notifyVue(`El producto se actualizo correctamente`, 'success')
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Ocurrio un error ${error}`, 'danger')
      }
    },
    async save() {
      if (this.product._id) {
        await this.updateProduct()
      } else {
        await this.addProduct()
      }
    },
    async addProduct() {
      try {
        this.saving = true
        const productReq = { ...this.product }
        if (this.imageFile) {
          const formData = new FormData()
          formData.append(this.fieldName, this.imageFile)
          const imageRes = await axios.post(`${constants.URL}/media/upload`, formData)

          const mediaReq = {
            path: imageRes.data.imageFullSize.url,
            name: `${productReq.name}-logo`,
            meta: imageRes.data
          }
          const mediaResponse = await axios.post(`${constants.URL}/media`, mediaReq)
          productReq.media = mediaResponse.data._id
        } else if (!this.imageFile && this.image !== '') {
          productReq.media = this.mediaFile._id
        } else {
          delete productReq.media;
        }
        const result = await axios.post(`${constants.URL}/products`, productReq)
        this.$notifications.notifyVue(`El producto se guardo correctamente`, 'success')
        this.modalToggle = false
        this.saving = false
        this.$emit('save', result.data)
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
      }
    },
    onChangeFile(e) {
      this.fieldName = e.name;
      this.imageFile = e.file;
    },
    onImageSelected(media) {
      this.mediaFile = media;
      this.image = media?.meta?.imageThumb?.url;
    },
    createImage(file) {
      let reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    text2HTML(source) {
      // 1: Plain Text Search
      var text = source.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
      // 2: Line Breaks
      text = text.replace(/\r\n?|\n/g, '<br>')
      // 3: Paragraphs
      text = text.replace(/<br>\s*<br>/g, '</p><p>')
      // 4: Wrap in Paragraph Tags
      text = '<p>' + text + '</p>'
      return text
    },
    removeImage() {
      this.image = ''
    }
  }
}
</script>
