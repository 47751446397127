import moment from 'moment'
import Handlebars from 'handlebars'

const getHelper = function (type) {
    return {
        eq: function (v1, v2) {
            return v1 === v2;
        },
        ne: function (v1, v2) {
            return v1 !== v2;
        },
        lt: function (v1, v2) {
            return v1 < v2;
        },
        gt: function (v1, v2) {
            return v1 > v2;
        },
        lte: function (v1, v2) {
            return v1 <= v2;
        },
        gte: function (v1, v2) {
            return v1 >= v2;
        },
        and: function () {
            return Array.prototype.slice.call(arguments).every(Boolean);
        },
        or: function () {
            return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
        },
        formatCurrency(val) {
            if (!val) {
                return;
            }
            return parseFloat(val).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        ternary: function (condition1, condition2, yes, no) {
            return condition1 === condition2 ? yes : no;
        },
        totalLocal: function (quantity, price, invoice, creditCard, installments) {
            const _this = type === 'HBS' ? Handlebars.helpers : this;

            let finalPrice = _this.calcFeeds(price, invoice, creditCard, installments);
            return (parseFloat(finalPrice) * quantity)
        },
        calcFeeds: function (price, invoice, creditCard, installments) {
            const _this = type === 'HBS' ? Handlebars.helpers : this;
            let currentPrice = parseInt(price);
            if (invoice.useInvoice) {
                currentPrice += _this.percentage((5 + invoice.percentage), currentPrice);
            }

            if (creditCard.payWithCard) {
                currentPrice += _this.percentage(creditCard.percentage, currentPrice);
            }

            if (installments.payInInstallments) {
                let percentage = installments.percentage * installments.number;
                currentPrice += _this.percentage(percentage, currentPrice);
            }

            return currentPrice;
        },
        totalGlobal(fields, invoice, creditCard, installments) {
            const _this = type === 'HBS' ? Handlebars.helpers : this;
            if (!fields) {
                return;
            }

            let sum = 0;
            fields.filter(val => val.quoteType === 'PRODUCT')
                .forEach(el => {
                    sum += _this.totalLocal(el.quantity, el.price, invoice, creditCard, installments);
                });
            return sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        customField: function (items, filter) {
            if (!items && items.length == 0) {
                return;
            }
            var item = items
                .filter(val => val.quoteType === 'CUSTOM_FIELD')
                .find(val => val.customField.key === filter);

            return item ? item.customField.value : '';
        },
        imagenFilter(items, filter) {
            if (!items && items.length == 0) {
                return;
            }

            var item = items
                .filter(val => val.quoteType === 'IMAGEN')
                .find(val => val.media.key === filter);

            return item ? item.media.value.mediaPath : '';
        },
        formateDate(date) {
            return moment(date).format("DD/MM/YYYY")
        },
        currentDate() {
            return moment().format("DD/MM/YYYY")
        },
        percentage(percent, total) {
            return ((percent / 100) * total)
        }
    };
}

export default getHelper;