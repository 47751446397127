import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=af46e77a&scoped=true&"
import script from "./Tabs.vue?vue&type=script&lang=js&"
export * from "./Tabs.vue?vue&type=script&lang=js&"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=af46e77a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@2.7.14_css-loader@6.7.4_webpack@5.84.0__ejs@3.1.10_handl_ccv37ezjcaqnz27d2iikaky3s4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af46e77a",
  null
  
)

export default component.exports