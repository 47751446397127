<template>
  <div class="content" :class="{ 'content-no-padding': contentCss === 'no-padding'}">
    <div class="container-fluid">
        <transition name="fade" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </transition>
    </div>
  </div>
</template>
<script>
  export default {
    computed: {
      contentSize() {
        return this.$store.state.contentSize;
      },
    },
    mounted() {
      this.contentCss = this.contentSize
    },
    watch: {
      async contentSize (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.contentCss = newValue;
        }
      }
    },
    data() {
      return {
        contentCss: ''
      }
    }
  }
</script>
<style>
  .content-no-padding {
    padding: 0px !important;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter,
  .fade-leave-to
  /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
