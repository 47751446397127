<template>
  <div style="background: #f4f3ef;">
    <notifications />
    <div class="container-fluid">
      <div class="row" id="pwd-container">
        <div class="col-md-6 col-lg-4 col-md-offset-3 col-lg-offset-4">
          <div class="card login-form-card">
            <div class="content">
              <section class="login-form">
                <form>
                  <img :src="image" class="img-responsive" alt>
                  <h3 class="text-center"> Formulario de Registro </h3>
                  <br />

                  <div class="form-group" :class="{ 'has-error': errors.first('firstName') }">
                    <label for="firstName">Nombre</label>
                    <input v-model="credentials.firstName" class="form-control border-input" v-validate="'required'"
                      name="firstName" type="text" autocapitalize="off" autocorrect="off" placeholder="" />
                    <span class="help-block text-left">{{ errors.first('firstName') }}</span>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('lastName') }">
                    <label for="firstName">Apellido</label>
                    <input v-model="credentials.lastName" class="form-control border-input" v-validate="'required'"
                      name="lastName" type="text" autocapitalize="off" autocorrect="off" placeholder="" />
                    <span class="help-block text-left">{{ errors.first('lastName') }}</span>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('email') }">
                    <label for="email">Email</label>
                    <input v-model="credentials.email" class="form-control border-input" v-validate="'required|email'"
                      name="email" type="text" autocapitalize="off" autocorrect="off" placeholder="" />
                    <span class="help-block text-left">{{ errors.first('email') }}</span>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('password') }">
                    <label for="password">Contraseña</label>
                    <input v-model="credentials.password" class="form-control border-input" v-validate="'required'"
                      name="password" type="password" placeholder="" />
                    <span class="help-block text-left">{{ errors.first('password') }}</span>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('company') }">
                    <label for="company">Nombre de mi empresa</label>
                    <input v-model="credentials.company" class="form-control border-input" v-validate="'required'"
                      name="company" type="text" autocapitalize="off" autocorrect="off" v-on:keyup.enter="submit()"
                      placeholder="Ej. Negocio personal" />
                    <span class="help-block text-left">{{ errors.first('company') }}</span>
                  </div>

                  <button type="button" @click="submit()" name="go" :disabled="errors.any() || !isCompleted"
                    class="btn btn-lg btn-success btn-block">Registrarme</button>
                  <div>
                    Ya tengo una cuenta, quiero
                    <router-link :to="`/login`">
                      Ingresar
                    </router-link>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>

        <footer class="col-md-12">
          <div class="pull-right copyright">
            &copy; 2023, made with
            <i class="fa fa-heart heart"></i> by Reach lab
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.form-group {
  margin-bottom: 5px !important;
}

.login-form-card .login-form form img {
  margin-bottom: 15px !important;
}
.copyright {
  padding: 15px;
}
</style>

<script>
export default {
  data() {
    return {
      credentials: {
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        password: ''
      },
      image: 'https://quote.reachportones.com/static/img/logo.png'
    }
  },
  components: {},
  computed: {
    isCompleted() {
      return this.credentials.email &&
        this.credentials.password &&
        this.credentials.firstName &&
        this.credentials.lastName &&
        this.credentials.company;
    }
  },
  methods: {
    notifyVue(message) {
      this.$notifications.notify({
        message,
        // icon: 'ti-gift',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger'
      })
    },
    submit() {
      this.loggingIn = true
      this.$store
        .dispatch('register', this.credentials)
        .then(() => this.$router.push('/'))
        .catch(error => {
          const message = error.response.data.message ?? '';
          switch (message) {
            case 'A user with the given username is already registered':
              this.notifyVue('El correo ya se encuentra registrado')
              break;
            default:
              this.notifyVue('Error desconocido')
              break;
          }
        });
    }
  }
}
</script>
