import { render, staticRenderFns } from "./Media.vue?vue&type=template&id=2dc9dd56&"
import script from "./Media.vue?vue&type=script&lang=js&"
export * from "./Media.vue?vue&type=script&lang=js&"
import style0 from "./Media.vue?vue&type=style&index=0&id=2dc9dd56&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@2.7.14_css-loader@6.7.4_webpack@5.84.0__ejs@3.1.10_handl_ccv37ezjcaqnz27d2iikaky3s4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports