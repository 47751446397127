<template>
  <modal v-model="showModal" title="Invitacion" size="sm" :backdrop="false" :dismiss-btn="false">
      <form>
        <div class="row">
          <div class="col-md-12">
            ok
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn type="primary" @click="save">
          Guardar
        </btn>
      </div>
    </modal>
</template>
<script>
  import { Modal, Btn } from 'uiv'

  export default{
    components: {
      Modal,
      Btn
    },
    computed: {},
    data () {
      return {
        showModal: false
      }
    },
    methods: {
      show () {
        this.$validator.reset()
        this.showModal = true
      },
     async save() {
        try {
          await this.$store.dispatch('confirmInviteToken', this.$store.state.inviteToken);
          this.showModal = false;
        } catch (error) {
          this.$notifications.notifyVue(`Error: ${error.message}`, 'danger')
          this.showModal = false;
        }
      }
    }
  }
</script>
