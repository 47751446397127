<template>
  <div class="row">
    <div class="col-md-12 row">
      <div class="col-md-7 text-left">
        <p class="category text-left"> 
          <btn type="info" @click="createReminder()">
            <i class="ti-plus"></i> Crear evento
          </btn> 
          <btn type="primary" @click="$refs.filterBy.show()">
            <i class="ti-panel"></i> Filtrar
          </btn>
          <btn type="primary" @click="$refs.sortBy.show()">
            <i class="ti-arrows-vertical"></i> Ordenar
          </btn>
        </p>
      </div>
      <div class="col-md-5">
        <div class="input-group">
                <input 
                  type="text" 
                  class="form-control border-input" 
                  placeholder="Search" 
                  @keyup="search"
                  v-model="searchModel"
                  id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <table class="table table-hover quote-table">
        <thead>
          <tr>
            <th class="hidden-xs" >Fecha inicial</th>
            <th >Nombre</th>
            <th class="hidden-xs" >Cliente</th>
            <th class="hidden-xs" >Usuario</th>
            <th class="hidden-xs">Periodo</th>
            <th class="hidden-xs">Fecha esperada</th>
            <th >Estado</th>
            <th>
              <i class="ti-settings"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </div>
          <div v-if="reminders.length === 0">
            <tr> 
              <td colspan="7">Sin recordatorios</td>
            </tr>
          </div>
          <template v-else>
            <tr :key="index" v-for="(reminder, index) in reminders">
              <td :id="index" class="hidden-xs">{{ reminder.createAt | formatDate}} </td>
              <td :id="index"> 
                {{ reminder.name }} 
                <i class="visible-mobile cursor-pointer" @click="openDetail(index)" v-bind:class="[ reminder.isActive ? 'ti-angle-up' : 'ti-angle-down' ]" />
              </td>
              <td :id="index" class="hidden-xs"> {{ reminder.client ? reminder.client.name : 'Sin cliente' }} </td>
              <td :id="index" class="hidden-xs"> {{ reminder.userId.username }} </td>
              <td :id="index" class="hidden-xs"> {{ reminder.when }}</td>
              <td :id="index" class="hidden-xs">{{ reminder.whenDate | formatDate}} </td>
              <td :id="index" > 
                <span class="label" 
                  v-bind:class="{ 
                    'label-warning': reminder.status === 'WAITING', 
                    'label-danger': reminder.status === 'CANCELLED', 
                    'label-success': reminder.status === 'EXECUTED', 
                    'label-primary': reminder.status === 'ACTIVE', 
                }">
                  {{ getStatus(reminder.status) }} 
                </span>
              </td>
              <td class="">
                <!-- desktop -->
                <!-- <div class="hidden-xs">
                  <a @click="openDeleteModel(index)" class="text-danger cursor-pointer"> 
                    <i class="ti-trash"></i> 
                  </a>
                  <a v-if="reminder.status === 'WAITING'" @click="openCancelModal(index)" class="text-danger cursor-pointer"> 
                    <i class="ti-na"></i> 
                  </a>
                  <a @click="modalChangeState=true" class="text-danger cursor-pointer"> 
                    <i class="ti-flag"></i> 
                  </a>
                  <a @click="editReminder(reminder)" class="text-info cursor-pointer"> 
                    <i class="ti-pencil-alt"></i> 
                  </a>
                </div> -->
                
                <a class="cursor-pointer" @click.prevent="openCxtMenu(reminder, index)"><i class="ti-more-alt"></i></a>
              </td>
            </tr>
            <tr :id="`detail-${index}`" class="hide">
              <td colspan="5" class="bg-info">
                <b>Cliente: </b> {{ reminder.client ? reminder.client.name : 'Sin cliente' }} <br />
                <b>Usuario: </b> {{ reminder.userId.username }} <br />
                <b>Periodo: </b> {{ reminder.when }} <br />
                <b>Fecha final: </b> {{ reminder.whenDate | formatDate }} <br />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      </div>
      <div v-if="totalCount > limit">
        Elementos mostrados [{{((currentPage - 1) * limit) + 1}} - {{limit * currentPage}} de {{totalCount}}]
      </div>
      <div v-else-if="totalCount < limit">
        Elemento mostrados [{{totalCount}}]
      </div>
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar este recordatorio?
      <div class="text-center">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" @click="deleteReminder()">Eliminar</btn>
      </div>
    </modal>

    <modal v-model="cancelModal" title="Cancelar" :header="false" :footer="false" size="sm">
      Desea cancelar este recordatorio?
      <div class="text-center">
        <br />
        <btn @click="cancelModal=false">Cancel</btn>
        <btn type="danger" @click="cancelReminder()">Aceptar</btn>
      </div>
    </modal>

    <modal v-model="modalChangeState" title="Reestablecer" :header="false" :footer="false" size="sm">
      Desea reestablecer este recordatorio?
      <div class="text-center">
        <br />
        <btn @click="modalChangeState=false">Cancel</btn>
        <btn type="danger" @click="restartOrFinishReminder()">Aceptar</btn>
      </div>
    </modal>

      <context-menu class="cursor-pointer" id="context-menu" ref="ctxMenu">
        <li @click="openPreview()">
          <i class="ti-image"></i> Ver mas
        </li>
        <li @click="editReminder()">
         <i class="ti-pencil-alt"></i> Editar
        </li>
        <li @click="postponeReminder()">
         <i class="ti-timer"></i> Reprogramar
        </li>
        <li v-if="reminder.status === 'WAITING'" @click="modalChangeState=true">
          <i class="ti-flag"></i> {{reminder.repeat ? 'Reiniciar' : 'Finalizar'}}
        </li>
        <li class="text-warning" v-if="reminder.status === 'WAITING' || reminder.status === 'ACTIVE'" @click="openCancelModal(index)">
          <i class="ti-na"></i>  Cancelar
        </li>
        <li class="text-danger" @click="openDeleteModel(index)">
          <i class="ti-trash"></i>  Eliminar
        </li>
      </context-menu>

    <SortBy 
      ref="sortBy" 
      :modalProp="false" 
      :byValue="byModel" 
      :byCriteo="criteoValueDefault" 
      :by="sortByParams" 
      @update="sortByEvent"/>

    <FilterBy 
      ref="filterBy" 
      :modalProp="false" 
      :criteo="criteoFilterBy" 
      :options="filterByParams" 
      :get-options="getOptions"
      :criteo-default="criteoDefault"
      :option-default="filterDefault"
      @output="onOutputFilter" />

    <ReminderForm
      ref="reminderForm"
      :clientField="true"
      :modelProp="false"
      :action="reminderFormAction"
      v-model="reminderFormModel"
      @save="saveReminder" />
      
  </div>
</template>
<style>
  .add-field {
    margin-top: 20px;
  }
</style>

<script>
import constants from '../constants'
import SortBy from '../components/Filters/SortBy'
import FilterBy from '../components/Filters/FilterBy'
import ReminderForm from '../components/Forms/ReminderForm'

import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'
import contextMenu from 'vue-context-menu'
import Vue from 'vue'
import { isEmpty } from 'lodash'

const sortByParams = [
  {
    id: 'status',
    name: 'Estado'
  },
  {
    id: 'name',
    name: 'Nombre'
  },
  {
    id: 'createAt',
    name: 'Fecha de creacion'
  },
  {
    id: 'whenDate',
    name: 'Fecha de finalizacion'
  }
]

const byModel = {
  id: 'createAt',
  name: 'Fecha de creacion'
}

const criteoValueDefault = {
  id: 'desc',
  name: 'Descendente'
}

const filterByParams = [
  {
    key: 'whenDate',
    label: 'Fecha final',
    meta: 'whenDate',
    type: 'DATERANGE',
    criteo: [
      {
        key: 'range',
        label: 'Rango de valores'
      }
    ]
  },
  {
    key: 'name',
    label: 'Nombre',
    meta: 'name',
    type: 'TEXT'
  }
]

const criteoFilterBy = [
  {
    key: 'equal',
    label: 'Igual a'
  },
  {
    key: 'contains',
    label: 'Contener valor'
  }
]
const reminder = {
  name: '',
  client: null,
  amount: 1,
  unit: 'days',
  notes: ''
}

export default {
  components: {
    Modal,
    Pagination,
    Btn,
    contextMenu,
    SortBy,
    FilterBy,
    ReminderForm
  },
  created () {
    this.fetchReminders()
  },
  data () {
    return {
      loading: false,
      exporting: false,
      index: -1,
      modalRemove: false,
      cancelModal: false,
      modalChangeState: false,
      reminders: [],
      reminder: {...reminder},
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: '',
      saving: false,
      sortByParams: [...sortByParams],
      byModel,
      criteoValueDefault,
      criteoFilterBy,
      filterByParams,
      sortBy: null,
      criteoDefault: criteoFilterBy[0],
      filterDefault: filterByParams[0],
      filters: {},
      reminderFormAction: 'CREATE',
      reminderFormModel: null
    }
  },
  computed: { },
  methods: {
    search () {
      this.fetchReminders()
    },
    fetchReminders () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }

      if (this.searchModel) {
        params.q = this.searchModel
      }

      if (!isEmpty(this.filters)) {
        params.filters = this.filters
      }

      if (this.sortBy) {
        params.sort = this.sortBy
      }

      axios
        .get(`${constants.URL}/reminders`, { params })
        .then(response => {
          this.totalCount = response.data.count
          this.totalPage = Math.ceil(this.totalCount / this.limit)
          this.reminders = response.data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    async changePage () {
      await this.fetchReminders()
    },
    openPreview () {
      this.$router.push(`/admin/reminders/detail/${this.reminder._id}`)
    },
    openDeleteModel (index) {
      this.modalRemove = true
      this.index = index
    },
    openCancelModal (index) {
      this.cancelModal = true
      this.index = index
      this.reminder = this.reminders[index]
    },
    deleteReminder () {
      const reminder = this.reminders[this.index]
      axios
        .delete(`${constants.URL}/reminders/${reminder._id}`)
        .then(() => {
          this.reminders.splice(this.index, 1)
          this.modalRemove = false
        }).catch(error => {
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    createReminder () {
      this.reminderFormAction = 'CREATE'
      this.$refs.reminderForm.show(this.reminderFormAction)
    },
    editReminder () {
      this.reminderFormModel = this.reminders[this.index]
      this.reminderFormAction = 'UPDATE'
      this.$refs.reminderForm.show(this.reminderFormAction)
    },
    postponeReminder () {
      this.reminderFormModel = this.reminders[this.index]
      this.reminderFormAction = 'SET'
      this.$refs.reminderForm.show(this.reminderFormAction)
    },
    restartOrFinishReminder () {
      const reminder = this.reminders[this.index]
      const action = reminder.repeat ? 'RESTART' : 'FINISH'
      axios
        .put(`${constants.URL}/reminders/changeState/${reminder._id}`, { action })
        .then(() => {
          this.fetchReminders()
          this.$notifications.notifyVue('El recordatorio ha sido actualizado exitosamente', 'info')
        }).catch(error => {
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
      this.modalChangeState = false
    },
    cancelReminder () {
      const reminder = this.reminders[this.index]
      axios
        .put(`${constants.URL}/reminders/changeState/${reminder._id}`, { action: 'CANCEL' })
        .then(() => {
          reminder.status = 'CANCELLED'
          this.cancelModal = false
        }).catch(error => {
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    getStatus (status) {
      switch (status) {
        case 'WAITING':
          return 'Pendiente'
        case 'CANCELLED':
          return 'Cancelado'
        case 'EXECUTED':
          return 'Realizado'
        case 'ACTIVE':
          return 'Activo'
      }
    },
    openCxtMenu (reminder, index) {
      this.$nextTick(() => {
        this.reminder = reminder
        this.index = index
      })
      this.$refs.ctxMenu.open()
    },
    openDetail (index) {
      let el = document.querySelector(`#detail-${index}`)
      if (!el) {
        return
      }
      const status = el.classList.contains('hide')
      const reminder = this.reminders[index]
      reminder.isActive = status
      Vue.set(this.reminders, index, reminder)
      if (status) {
        el.classList.remove('hide')
      } else {
        el.classList.add('hide')
      }
    },
    sortByEvent (params) {
      this.sortBy = {}
      this.sortBy[params.by] = params.criteo
      this.fetchReminders()
    },
    onOutputFilter (params) {
      if (!params.filter) {
        this.filters = {}
        this.fetchReminders()
        return
      }
      const { criteo, filterBy, filter } = params
      this.filters = {
        param: filterBy.key,
        condition: criteo.key,
        value: filter
      }
      this.fetchReminders()
    },
    async getOptions (params) {
      const { meta, query, done } = params
      try {
        const params = {
          skip: 0,
          take: 10,
          q: query
        }
        const result = await axios.get(`${constants.URL}/${meta}`, { params })
        done(result.data.result)
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    saveReminder () {
      this.fetchReminders()
    }
  }
}
</script>
