<template>
  <modal v-model="modalToggle" title="Crear cliente" size="lg">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('name')}">
              <label>Nombre</label>
              <input
                v-model="client.name"
                class="form-control border-input"
                v-validate="'required'"
                name="name"
                type="text"
                placeholder="Nombre" />
              <span class="help-block text-left">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" :class="{'has-error': errors.first('address')}">
              <label>Direccion</label>
              <input
                v-model="client.address"
                class="form-control border-input"
                name="address"
                type="text"
                placeholder="Direccion" />
                <span class="help-block text-left">{{ errors.first('address') }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" :class="{'has-error': errors.first('email')}">
              <label>Correo electronico</label>
              <input
                v-model="client.email"
                class="form-control border-input"
                name="email"
                type="email"
                placeholder="Email"/>
              <span class="help-block text-left">{{ errors.first('email') }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Tipo de cliente</label>
              <select class="form-control border-input" v-model="client.type" name="type">
                <option value="PERSON">Persona</option>
                <option value="COMPANY">Empresa</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" :class="{'has-error': errors.first('phone')}">
              <label>Telefono</label>
              <input
                v-model="client.phone"
                class="form-control border-input"
                name="phone"
                type="number"
                placeholder="Telefono" />
              <span class="help-block text-left">{{ errors.first('phone') }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group" :class="{'has-error': errors.first('nit')}">
              <label>Nit</label>
                <input
                  v-model="client.nit"
                  class="form-control border-input"
                  name="nit"
                  type="text"
                  placeholder="Nit" />
              <span class="help-block text-left">{{ errors.first('nit') }}</span>
            </div>
          </div>
        </div>

        <div class="row" v-if="client.type === 'COMPANY'">
          <div class="col-md-12 row">
            <div class="col-md-12">
              <label>Resposables: </label>
            </div>
            <div v-for="(manager, index) in client.managers" class="manager-inputs" :key="index">
              <div class="col-md-3 visible-xs">
                <span class="text-muted"> - Responsable {{index + 1}} </span>
              </div>
              <div class="col-md-3">
                <div class="form-group" :class="{'has-error': errors.first('manager.name')}">
                  <input
                    v-model="manager.name"
                    class="form-control border-input"
                    v-validate="'required'"
                    name="manager.name"
                    type="text"
                    placeholder="Nombre"/>
                  <span class="help-block text-left">{{ errors.first('manager.name') }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <fg-input type="text" placeholder="Puesto" v-model="manager.position"></fg-input>
              </div>
              <div class="col-md-3">
                <fg-input type="text" placeholder="Email" v-model="manager.email"></fg-input>
              </div>
              <div class="col-md-3">
                <fg-input type="text" placeholder="Telefono" v-model="manager.phone"></fg-input>
              </div>
              <div class="col-md-12 text-right manager-action">
                <div class="text-danger" @click="deleteManager(index)">Eliminar</div>
              </div>
            </div>
            <div v-if="client.managers.length < 3" class="col-md-12">
              <span @click="addManager()" class="cursor-pointer text-primary">
                <i class="ti-plus"></i> Agregar otro
              </span>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="modalToggle=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !isCompleted || saving" @click="saveClient()">Guardar</btn>
      </div>
    </modal>
</template>
<script>
  import { Modal, Btn } from 'uiv'
  import constants from '../../constants'
  import axios from 'axios'
  const manager = {
    name: '',
    position: '',
    email: '',
    phone: ''
  }
  const client = {
    name: '',
    descripcion: '',
    email: '',
    phone: '',
    address: '',
    nit: '',
    type: 'PERSON',
    managers: [{ ...manager }]
  }

  export default{
    components: {
      Modal,
      Btn
    },
    props: {
      modalClient: Boolean,
      value: Object
    },
    watch: {
      value (v) {
        this.client = v
      }
    },
    computed: {
      isCompleted () {
        return this.client.name &&
          this.client.phone &&
          this.client.address &&
          this.client.type &&
          this.client.nit
      }
    },
    data () {
      return {
        client: { ...client },
        modalToggle: this.modalClient,
        saving: false
      }
    },
    methods: {
      show (action) {
        this.client = action === 'ADD' ? { ...client } : { ...this.value }
        this.$validator.reset()
        this.modalToggle = true
      },
      addClient () {
        let clientRequest = { ...this.client }
        clientRequest.managers = this.validateManagers(clientRequest.managers)
        axios
          .post(`${constants.URL}/clients`, clientRequest)
          .then(result => {
            this.modalToggle = false
            this.saving = false
            this.$emit('saveClient', result.data)
          })
          .catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Error: ${error}`, '')
          })
      },
      updateClient () {
        let clientRequest = { ...this.client }
        delete clientRequest._id
        delete clientRequest.__v
        delete clientRequest.userId
        clientRequest.managers = this.validateManagers(clientRequest.managers)
        axios
          .put(`${constants.URL}/clients/${this.client._id}`, clientRequest)
          .then(result => {
            this.modalToggle = false
            this.saving = false
            this.$emit('saveClient', result.data)
          })
          .catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Error: ${error}`, '')
          })
      },
      saveClient () {
        this.saving = true
        if (this.client._id) {
          this.updateClient()
        } else {
          this.addClient()
        }
      },
      addManager () {
        this.client
          .managers
          .push({ ...manager })
      },
      deleteManager (index) {
        this.client.managers.splice(index, 1)
        if (this.client.managers.length === 0) {
          this.addManager()
        }
      },
      validateManagers (managers) {
        for (let i = 0; i < managers.length; i++) {
          let manager = managers[i]
          if (manager && !manager.name) {
            managers.splice(i, 1)
          }
        }
        return managers
      }
    }
  }
</script>
