<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-md-7 text-left">
        <p class="category text-left"> 
          <btn type="primary" @click="openAddProduct()">
            <i class="ti-plus"></i> Agregar Producto
          </btn>
        </p>
      </div>
      <div class="col-md-5">
        <div class="input-group">
                <input 
                  type="text" 
                  class="form-control border-input" 
                  placeholder="Search" 
                  @keyup="search"
                  v-model="searchModel"
                  id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card car-overflow">
        <table class="table table-hover">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Tipo</th>
            <th class="hidden-xs">Imagen</th>
            <th class="hidden-xs">Descripcion</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </div>
          <template v-else>
            <tr v-for="(product, index) in products" :key="index">
            <td :id="index"> {{ product.name }} </td>
            <td :id="index"> {{ product.price }} </td>
            <td :id="index"> {{ product.type === 'SERVICE' ? 'Servicio' : 'Producto' }} </td>
            <td class="hidden-xs" :id="index">
              <img width="100px" v-bind:src="product.media? product.media?.meta?.imageThumb.url : ''" alt="">
            </td>
            <td class="hidden-xs product-desc" :id="index">
              <div v-html="product.description" />
            </td>
            <td class="text-center">
              <a class="cursor-pointer text-info" @click="editProduct(product)">
                <i class="ti-pencil-alt"></i>
              </a>
              <a class="cursor-pointer text-danger" @click="openDeleteProduct(index)">
                <i class="ti-trash"></i> 
              </a>
            </td>
          </tr>
          </template>
        </tbody>
      </table>
      </div>

      <page-conter :total="totalCount" :limit="limit" :current-page="currentPage" />
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar este producto?
      <div class="text-center rh-group-buttons">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" @click="deleteProduct()">Eliminar</btn>
      </div>
    </modal>
    <product-form v-model="product" :modal-prop="false" ref="modalProduct" @save="onSaveProduct" />
  </div>
</template>
<style lang="scss" scoped>
    .product-desc{
      ::v-deep p {
          font-size: 14px;
          padding: 0px;
          margin: 0px;
        }
    }
</style>
<script>
import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'
import PageConter from '../components/PageCounter.vue'
import ProductForm from '../components/Forms/ProductForm.vue'

import constants from '../constants'

const colomnsTable = [
  'Nombre',
  'Precio',
  'Tipo',
  'Imagen',
  'Descripcion',
  'Acciones'
]
const productSchema = {
  name: '',
  description: '',
  price: '',
  type: 'PRODUCT',
  imagenPath: ''
}

export default {
  components: {
    Modal,
    Pagination,
    Btn,
    PageConter,
    ProductForm
  },
  created () {
    this.fetchProducts()
  },
  data () {
    return {
      colomnsTable: [...colomnsTable],
      loading: false,
      saving: false,
      products: [],
      product: { ...productSchema },
      modalProduct: false,
      modalRemove: false,
      id: '',
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      image: '',
      fieldName: '',
      imageFile: '',
      previusImage: '',
      searchModel: ''
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted () {
      return this.product.name &&
        this.product.price &&
        this.product.type
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchProducts()
      }
    }
  },
  methods: {
    search () {
      this.fetchProducts()
    },
    fetchProducts () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }
      if (this.searchModel) {
        params.q = this.searchModel
      }
      axios
        .get(`${constants.URL}/products`, { params })
        .then(response => {
          this.totalCount = response.data.count
          this.totalPage = Math.ceil(this.totalCount / this.limit)
          this.products = response.data.result
          this.loading = false
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, '')
          this.loading = false
        })
    },
    async changePage () {
      await this.fetchProducts()
    },
    editProduct (product) {
      this.$refs.modalProduct.show('EDIT', product)
    },
    openAddProduct () {
      this.$refs.modalProduct.show('ADD')
    },
    async onSaveProduct() {
      await this.fetchProducts();
    },
    openDeleteProduct (index) {
      this.modalRemove = true
      this.id = index
    },
    deleteProduct () {
      const product = this.products[this.id]
      axios
        .delete(`${constants.URL}/products/${product._id}`)
        .then(() => {
          this.modalRemove = false
          this.products.splice(this.id, 1)
        })
        .catch(error => this.$notifications.notifyVue(`Error: ${error}`, ''))
    }
  }
}
</script>
