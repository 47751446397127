<template>
  <modal v-model="modalToggle" title="Crear producto" size="sm" :header="false">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Filtar por:</label>
              <select class="form-control border-input" v-model="selectedModel" @change="onChangeFilter()">
                <option v-for="item in options" :value="item" :key="item.key">
                    {{ item.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Criterio</label>
              <select class="form-control border-input" v-model="criteoModel">
                <option v-for="item in criteoValues" :value="item" :key="item.key">
                    {{ item.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-12" v-if="selectedModel && selectedModel.type === 'TEXT'">
            <div class="form-group">
              <label>Buscar</label>
              <input
                v-model="filter"
                class="form-control border-input"
                v-validate="'required'"
                name="filter"
                type="text"
                placeholder="Buscar por..." />
            </div>
          </div>

          <div class="col-md-12" v-if="selectedModel && selectedModel.type === 'SELECT'">
            <div class="form-group" >
                <label for="client-input">{{ selectedModel.label }}</label>
                <select class="form-control border-input" v-model="optionFilter">
                  <option v-for="item in selectedModel.items" :value="item" :key="item.key">
                      {{ item.name }}
                  </option>
                </select>
            </div>
          </div>

          <div class="col-md-12" v-if="selectedModel && selectedModel.type === 'DROPDOWN'">
            <div class="form-group" >
                <label for="client-input">{{ selectedModel.label }}</label>
                <input 
                  autocomplete="off"
                  id="typehead-input" 
                  class="form-control border-input" 
                  type="text" 
                  placeholder="Buscar..." />

                <typeahead 
                  v-model="selectedFilter" 
                  target="#typehead-input" 
                  :async-function="getItems" 
                  force-select 
                  @input="itemSelected"
                  item-key="name" />
            </div>
          </div>

            <div class="col-md-12" v-if="selectedModel && selectedModel.type === 'DATERANGE'">
              <div class="form-group" >
                <label for="client-input">Desde</label>
                <dropdown>
                  <div class="input-group">
                    <input class="form-control border-input" type="text" v-model="dateFilter.startOf">
                    <div class="input-group-btn ">
                      <btn class="dropdown-toggle border-input"><i class="glyphicon glyphicon-calendar"></i></btn>
                    </div>
                  </div>
                  <template slot="dropdown">
                    <li>
                      <date-picker v-model="dateFilter.startOf" format="dd-MM-yyyy"/>
                    </li>
                  </template>
                </dropdown>
              </div>
            </div>

            <div class="col-md-12" v-if="selectedModel && selectedModel.type === 'DATERANGE'">
              <div class="form-group" >
                <label for="client-input">Hasta</label>
                <dropdown >
                  <div class="input-group">
                    <input class="form-control border-input" type="text" v-model="dateFilter.endOf">
                    <div class="input-group-btn ">
                      <btn class="dropdown-toggle border-input"><i class="glyphicon glyphicon-calendar"></i></btn>
                    </div>
                  </div>
                  <template slot="dropdown">
                    <li>
                      <date-picker v-model="dateFilter.endOf" format="dd-MM-yyyy"/>
                    </li>
                  </template>
                </dropdown>
              </div>
            </div>

          <div class="col-md-12">
            <div class="form-group">
              <a @click="reset()" class="cursor-pointer text-danger">Limpiar campos</a>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer">
        <btn @click="modalToggle=false">Cancel</btn>
        <btn type="primary" @click="submit()">
          Aceptar
        </btn>
      </div>
    </modal>
</template>
<script>
  import { Modal, Btn, Typeahead, Dropdown, DatePicker } from 'uiv'
  export default{
    components: {
      Modal,
      Btn,
      Typeahead,
      Dropdown,
      DatePicker
    },
    props: {
      modalProp: Boolean,
      criteo: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array,
        default: () => []
      },
      getOptions: {
        type: Function,
        default () { }
      },
      criteoDefault: {
        type: Object,
        default: () => {}
      },
      optionDefault: {
        type: Object,
        default: () => {}
      }
    },
    computed: {},
    mounted () {
      this.reset()
    },
    data () {
      return {
        filter: '',
        criteoModel: this.criteoDefault,
        selectedModel: this.optionDefault,
        criteoValues: this.criteo,
        selectedFilter: '',
        optionFilter: {},
        dateFilter: {
          startOf: '',
          endOf: ''
        },
        modalToggle: this.modalProp
      }
    },
    methods: {
      show () {
        this.modalToggle = true
      },
      reset () {
        this.criteoModel = this.criteoDefault
        this.selectedModel = this.optionDefault
        this.filter = ''
        this.onChangeFilter()
        this.dateFilter = {
          startOf: '',
          endOf: ''
        }
      },
      getItems (query, done) {
        const params = {
          key: this.selectedModel.key,
          meta: this.selectedModel.meta,
          query,
          done
        }
        this.getOptions(params)
      },
      itemSelected (item) {
        this.filter = item
      },
      submit () {
        this.modalToggle = false
        let response = {
          criteo: this.criteoModel,
          filterBy: this.selectedModel,
          //filter: this.selectedModel.type === 'DATERANGE' ? this.dateFilter : this.filter
        }
        switch (this.selectedModel.type) {
          case 'DATERANGE':
            response.filter = this.dateFilter
            break
          case 'SELECT':
            response.filter = this.optionFilter
            break
          default:
            response.filter = this.filter
            break
        }
        this.$emit('output', response)
      },
      onChangeFilter () {
        if (this.selectedModel.type === 'DROPDOWN') {
          let el = document.querySelector(`#typehead-input`)
          this.filter = ''
          if (el) {
            el.value = ''
          }
        } else if (this.selectedModel.type === 'SELECT') {
          this.optionFilter = this.selectedModel.items[0]
        }

        if (this.selectedModel.criteo) {
          this.criteoValues = this.selectedModel.criteo
          this.criteoModel = this.criteoValues[0]
        } else {
          this.criteoValues = this.criteo
          this.criteoModel = this.criteoDefault
        }
      }
    }
  }
</script>
