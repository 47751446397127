<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card quote-container">
        <div class="header">
          <h4 class="title">Crear Cotizacion</h4>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.first('title') }">
                <label>Titulo</label>
                <input v-model="title" class="form-control border-input" v-validate="'required'" name="title" type="text"
                  placeholder="Titulo" />
                <span class="help-block text-left">{{ errors.first('title') }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.first('template') }">
                <label>Plantilla</label>
                <select class="form-control border-input" name="template" v-model="template" v-validate="'required'">
                  <option v-for="temp in templates" v-bind:value="temp._id" :key="temp._id">
                    {{ temp.name }}
                  </option>
                </select>
                <span class="help-block text-left">{{ errors.first('template') }}</span>
              </div>
            </div>
            <div class="col-md-12" v-show="!hasClient">
              <div class="form-group">
                <label for="client-input">Cliente</label>
                <input autocomplete="off" id="client-input" class="form-control border-input" type="text"
                  placeholder="Buscar cliente..." />
                <typeahead v-model="client" target="#client-input" :async-function="getClients" force-select
                  @input="clientSelected" item-key="name" />
                <a class="cursor-pointer" @click="openAddNewClient()">+ Nuevo</a>
              </div>
            </div>


            <!-- client form -->
            <div v-show="hasClient">
              <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors.first('name') }">
                  <label>Nombre</label>
                  <input v-model="client.name" class="form-control border-input" v-validate="'required'" name="name"
                    type="text" placeholder="Nombre" />
                  <span class="help-block text-left">{{ errors.first('name') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('address') }">
                  <label>Direccion</label>
                  <input v-model="client.address" class="form-control border-input" name="address" type="text"
                    placeholder="Direccion" />
                  <span class="help-block text-left">{{ errors.first('address') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('email') }">
                  <label>Correo electronico</label>
                  <input v-model="client.email" class="form-control border-input" name="email" type="email"
                    placeholder="Email" />
                  <span class="help-block text-left">{{ errors.first('email') }}</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('phone') }">
                  <label>Telefono</label>
                  <input v-model="client.phone" class="form-control border-input" name="phone" type="number"
                    placeholder="Telefono" />
                  <span class="help-block text-left">{{ errors.first('phone') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('nit') }">
                  <label>Nit</label>
                  <input v-model="client.nit" class="form-control border-input" name="nit" type="text"
                    placeholder="Nit" />
                  <span class="help-block text-left">{{ errors.first('nit') }}</span>
                </div>
              </div>
              <div class="col-md-12 text-right manager-action">
                <a class="text-danger cursor-pointer pull-right" @click="removeClientSelected()">Remover</a>
                <br />
              </div>
            </div>
            <!-- fin client -->

            <!-- conditions -->


            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <div>
                      <label>Factura</label>
                    </div>
                    <btn-group>
                      <btn input-type="radio" :input-value="true" v-model="invoice.useInvoice">
                        SI
                      </btn>
                      <btn input-type="radio" :input-value="false" v-model="invoice.useInvoice">
                        NO
                      </btn>
                    </btn-group>
                  </div>
                </div>
                <div v-if="invoice.useInvoice" class="col-md-3">
                  <div class="form-group" :class="{ 'has-error': errors.first('percentage_invoce') }">
                    <label>Porcentaje</label>
                    <input min="1" max="100" v-model="invoice.percentage" v-validate="'required'"
                      class="form-control border-input" name="percentage_invoce" type="number" placeholder="" />
                    <span class="help-block text-left">{{ errors.first('percentage_invoce') }}</span>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <div>
                      <label>Tarjeta de debito / credito</label>
                    </div>
                    <btn-group>
                      <btn input-type="radio" :input-value="true" v-model="creditCard.payWithCard">
                        SI
                      </btn>
                      <btn input-type="radio" :input-value="false" v-model="creditCard.payWithCard">
                        NO
                      </btn>
                    </btn-group>
                  </div>
                </div>
                <div v-if="creditCard.payWithCard" class="col-md-3">
                  <div class="form-group" :class="{ 'has-error': errors.first('percentage_creditcard') }">
                    <label>Porcentaje</label>
                    <input min="1" max="12" v-model="creditCard.percentage" v-validate="'required'"
                      class="form-control border-input" name="percentage_creditcard" type="number" placeholder="" />
                    <span class="help-block text-left">{{ errors.first('percentage_creditcard') }}</span>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <div>
                      <label>Pago en cuotas</label>
                    </div>
                    <btn-group>
                      <btn input-type="radio" :input-value="true" v-model="installments.payInInstallments">
                        SI
                      </btn>
                      <btn input-type="radio" :input-value="false" v-model="installments.payInInstallments">
                        NO
                      </btn>
                    </btn-group>
                  </div>
                </div>

                <template v-if="installments.payInInstallments">
                  <div class="col-md-3">
                    <div class="form-group" :class="{ 'has-error': errors.first('installments_percentage') }">
                      <label>Porcentaje por cuota</label>
                      <input min="0.5" max="100" v-model="installments.percentage" v-validate="'required'"
                        class="form-control border-input" name="installments_percentage" type="number" placeholder="" />
                      <span class="help-block text-left">{{ errors.first('installments_percentage') }}</span>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group" :class="{ 'has-error': errors.first('installments_number') }">
                      <label>Numero de cuotas</label>
                      <input v-model="installments.number" v-validate="'required'" class="form-control border-input"
                        name="installments_number" type="number" placeholder="" />
                      <span class="help-block text-left">{{ errors.first('installments_number') }}</span>
                    </div>
                  </div>
                </template>

              </div>
            </div>
            <draggable :list="fieldInputs" v-bind="dragOptions" ghost-class="ghost" handle=".grabbable">
              <div class="col-md-12 row" v-for="(field, index) in fieldInputs" :key="index">
                <template v-if="field.quoteType === 'CUSTOM_FIELD'">
                  <div class="col-md-6">
                    <span class="sort-icon ti-arrows-vertical grabbable"></span>
                    <div class="form-group" :class="{ 'has-error': errors.first(`custom-field-key-${index}`) }">
                      <label>Clave</label>
                      <input v-model="field.customField.key" class="form-control border-input" v-validate="'required'"
                        v-bind:name="`custom-field-key-${index}`" type="text" placeholder="Clave" />
                      <span class="help-block text-left">{{ errors.first(`custom-field-key-${index}`) }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" :class="{ 'has-error': errors.first(`custom-field-value-${index}`) }">
                      <label>Valor</label>
                      <input v-model="field.customField.value" class="form-control border-input" v-validate="'required'"
                        v-bind:name="`custom-field-value-${index}`" type="text" placeholder="Valor" />
                      <span class="help-block text-left">{{ errors.first(`custom-field-value-${index}`) }}</span>
                    </div>
                  </div>
                </template>

                <template v-if="field.quoteType === 'IMAGEN'">
                  <div class="col-md-6">
                    <span class="sort-icon ti-arrows-vertical grabbable"></span>
                    <div class="form-group" :class="{ 'has-error': errors.first(`custom-imagen-key-${index}`) }">
                      <label>Nombre</label>
                      <input v-model="field.media.key" class="form-control border-input" v-validate="'required'"
                        v-bind:name="`custom-imagen-key-${index}`" type="text" placeholder="Clave" />
                      <span class="help-block text-left">{{ errors.first(`custom-imagen-key-${index}`) }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <media-buttons v-model="field.media.image" @change="onChangeFile"
                      @showMedia="showMediaSelect(index)" />
                  </div>
                </template>

                <template v-if="field.quoteType === 'DIVIDER'">
                  <div class="col-md-12">
                    <span class="sort-icon ti-arrows-vertical grabbable"></span>
                    <div class="form-group" :class="{ 'has-error': errors.first(`divider-${index}`) }">
                      <label>Titulo de division</label>
                      <input v-model="field.title" class="form-control border-input" v-validate="'required'"
                        v-bind:name="`divider-${index}`" type="text" placeholder="Nombre" />
                      <span class="help-block text-left">{{ errors.first(`divider-${index}`) }}</span>
                    </div>
                  </div>
                </template>
                <template v-if="field.quoteType === 'PRODUCT'">
                  <div class="col-md-4">
                    <span class="sort-icon ti-arrows-vertical grabbable"></span>
                    <div class="form-group">
                      <label v-bind:for="`input-product-${index}`">Producto</label>
                      <input autocomplete="off" v-bind:id="`input-product-${index}`" class="form-control border-input"
                        type="text" placeholder="Buscar producto..." />

                      <typeahead v-model="field.product" v-bind:target="`#input-product-${index}`"
                        :async-function="getProducts" force-select item-key="name" @input="setPrice($event, field)" />
                      <a class="cursor-pointer" @click="addNewProduct(index)">+Nuevo</a>
                    </div>
                  </div>
                  <div class="col-md-4" v-if="field.product">
                    <div class="form-group" :class="{ 'has-error': errors.first(`quantity-${index}`) }">
                      <label for="input-product">Cantidad</label>
                      <input class="form-control border-input" v-model="field.quantity" v-validate="'required'"
                        v-bind:name="`quantity-${index}`" type="number" placeholder="Nombre" />
                      <span class="help-block text-left">{{ errors.first(`quantity-${index}`) }}</span>
                    </div>
                  </div>

                  <div class="col-md-4" v-if="field.product">
                    <div class="form-group">
                      <label for="input-product">Precio</label>
                      <input v-model="field.price" class="form-control border-input" name="name" type="number"
                        placeholder="Nombre" />
                    </div>
                  </div>
                  <div class="col-md-12" v-if="field.product">
                    <div class="form-group">
                      <label>Descripcion </label>
                      <textarea rows="4" v-model="field.product.description" class="form-control border-input"
                        placeholder="Descripcion" />

                    </div>
                  </div>

                </template>
                <div class="col-md-12 text-right manager-action">
                  <div class="text-danger cursor-pointer" @click="removeField(index)">Remover</div>
                </div>
              </div>
            </draggable>

            <div class="col-md-12 row add-field">
              <div class="col-md-3 col-xs-6 ">
                <div class="form-group">
                  <select class="form-control border-input" v-model="fieldType">
                    <option value="DIVIDER">Divison</option>
                    <option value="PRODUCT">Producto</option>
                    <option value="CUSTOM_FIELD">Campo Personalizado</option>
                    <option value="IMAGEN">Imagen</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <btn @click="addField" type="info">Agregar campo</btn>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <btn class="pull-right cancel-btn" type="primary" :disabled="errors.any() || !isCompleted || saving"
                @click="saveQuote()">Guardar</btn>

              <router-link class="btn btn-default pull-right" :to="`/admin/quotes`">
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar esta cotizacion?
      <div class="text-center">
        <br />
        <btn @click="modalRemove = false">Cancel</btn>
        <btn type="danger" @click="deleteClient()">Eliminar</btn>
      </div>
    </modal>

    <client-form v-model="client" :modal-client="false" ref="modalClient" @saveClient="saveClient" />
    <product-form v-model="client" :modal-prop="false" ref="modalProduct" @save="saveProduct" />
    <media-select :showModal="false" ref="mediaList" @selected="onImageSelected" />

  </div>
</template>
<style lang="scss" scoped>
.cancel-btn {
  margin-left: 10px;
}

.field-container {
  padding: 15px;
}

.quote-container {
  padding-left: 20px;
  padding-right: 20px;
}

.sort-icon {
  position: absolute;
  top: 4px;
  left: -3px;
  font-weight: 400;
  color: #9A9A9A;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

<script>
import constants from '../constants'
import { cloneDeep } from 'lodash'
import { Modal, Btn, Typeahead, BtnGroup } from 'uiv'
import axios from 'axios'
import draggable from 'vuedraggable'
import Vue from 'vue'

import ClientForm from '../components/Forms/ClientForm.vue'
import ProductForm from '../components/Forms/ProductForm.vue'
import MediaButtons from '../components/Media/MediaButtons.vue'
import MediaSelect from '../components/Media/MediaSelect'
import { text2HTML } from '../utils'

const { convert } = require('html-to-text');

const PRODUCT = 'PRODUCT'
const DIVIDER = 'DIVIDER'
const CUSTOM_FIELD = 'CUSTOM_FIELD'
const IMAGEN = 'IMAGEN'

const dividerSchema = {
  quoteType: DIVIDER,
  title: ''
}
const customFieldSchema = {
  quoteType: CUSTOM_FIELD,
  customField: {
    key: '',
    value: ''
  }
}

const imagenSchema = {
  quoteType: IMAGEN,
  media: {
    image: '',
    fieldName: '',
    imageFile: '',
    doc: ''
  }
}

const productSchema = {
  quoteType: PRODUCT,
  product: null,
  priceCheck: 'default',
  price: 0,
  quantity: 1
}

const manager = {
  name: '',
  position: '',
  email: '',
  phone: ''
}
const client = {
  name: '',
  descripcion: '',
  email: '',
  phone: '',
  address: '',
  nit: '',
  type: 'PERSON',
  managers: [{ ...manager }]
}

let productIndex = null

export default {
  components: {
    Modal,
    Btn,
    Typeahead,
    BtnGroup,
    ClientForm,
    ProductForm,
    draggable,
    MediaButtons,
    MediaSelect
  },
  mounted() {
    this.id = this.$route.params.id
    this.getTemplates()
    if (this.id) {
      this.hasClient = true;
      this.$validator.reset()
      this.fetchQuote()
    }
  },
  created() { },
  data() {
    return {
      id: null,
      modalQuote: false,
      modalRemove: false,
      fieldType: DIVIDER,
      saving: false,
      title: '',
      invoice: {
        useInvoice: false,
        percentage: 12
      },
      creditCard: {
        payWithCard: false,
        percentage: 6
      },
      installments: {
        payInInstallments: false,
        percentage: 0.5,
        number: 3
      },
      client: { ...client },
      templates: [],
      template: null,
      fieldInputs: [],
      hasClient: false,
      dragging: false,
      imageFile: '',
      mediaFile: {},
      image: '',
      fieldIndex: 0
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted() {
      return this.title &&
        this.client &&
        this.template
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        direction: 'horizontal'
      };
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.$router.replace('/admin/quotes')
      }
    }
  },
  methods: {
    showMediaSelect(index) {
      this.fieldIndex = index;
      this.$refs.mediaList.show()
    },
    openAddNewClient() {
      this.$refs.modalClient.show('ADD')
    },
    saveClient(client) {
      this.hasClient = true
      this.client = { ...client }
    },
    getClients(query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/clients`, { params })
        .then(response => {
          done(response.data.result)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    clientSelected(item) {
      if (item) {
        this.hasClient = true
        this.client = { ...item }
      }
    },
    removeClientSelected() {
      this.hasClient = false
      this.client = { ...client }
    },
    addNewProduct(index) {
      productIndex = index
      this.$refs.modalProduct.show('ADD')
    },
    saveProduct(product) {
      this.fieldInputs[productIndex].product = { ...product }
      this.fieldInputs[productIndex].price = product.price
    },
    async fetchQuote() {
      try {
        const response = await axios.get(`${constants.URL}/quotes/${this.id}`)
        const quote = response.data
        this.title = quote.title
        this.client = quote.client
        this.template = quote.template._id
        this.invoice = quote.invoice
        this.installments = quote.installments
        this.creditCard = quote.creditCard
        this.fieldInputs = quote.fields.map(item => {
          if (item.quoteType === PRODUCT) {
            const options = {
              wordwrap: 130
            };
            item.product.description = convert(item.product.description, options)
          }
          if (item.quoteType === IMAGEN) {
            item.media = {
              ...imagenSchema.media,
              image: item.media?.meta?.imageThumb?.url ?? '',
              doc: item.media,
              key: item.media.key
            }
          }
          return item;
        })
        this.makeInputsProduct(this.fieldInputs)
      } catch (error) {

        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
      }

    },
    makeInputsProduct(fieldInputs) { // bug - typeahead not work inside a loop
      setTimeout(() => {
        fieldInputs.forEach((val, index) => {
          if (val.quoteType === PRODUCT) {
            let el = document.querySelector(`#input-product-${index}`)
            el.value = val.product.name
          }
        })
      }, 500)
    },
    onImageSelected(media) {
      const field = this.fieldInputs[this.fieldIndex];
      field.media.doc = media;
      field.media.image = media?.meta?.imageThumb?.url;

      Vue.set(this.fieldInputs, this.fieldIndex, field);
    },
    onChangeFile(e) {
      const field = this.fieldInputs[this.fieldIndex];
      field.media.fieldName = e.name;
      field.media.imageFile = e.file;
      Vue.set(this.fieldInputs, this.fieldIndex, field);
    },
    getImages(query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/media`, { params })
        .then(response => {
          done(response.data.result)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    getProducts(query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/products`, { params })
        .then(response => {
          done(response.data.result)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    getTemplates() {
      axios
        .get(`${constants.URL}/templates/all`)
        .then(response => {
          this.templates = response.data
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    saveQuote() {
      this.saving = true
      if (this.id) {
        this.updateQuote()
      } else {
        this.addQuote()
      }
    },
    async updateQuote() {
      try {
        const client = {
          ...this.client
        }
        delete client.company;
        delete client.owner;
        const fieldInputs = cloneDeep(this.fieldInputs);

        const promises = fieldInputs.map(this.formatProductField);
        const fields = await Promise.all(promises)

        const quoteRequest = {
          client,
          fields,
          title: this.title,
          invoice: this.invoice,
          creditCard: this.creditCard,
          installments: this.installments,
          template: this.template,
        }
        await axios.put(`${constants.URL}/quotes/${this.id}`, quoteRequest)
        this.saving = false
        this.$router.replace(`/admin/quotes/preview/${this.id}`)
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
      }
    },
    async addQuote() {
      const client = {
        ...this.client
      }
      delete client.company;
      delete client.owner;
      const fieldInputs = cloneDeep(this.fieldInputs);

      const promises = fieldInputs.map(this.formatProductField);
      const fields = await Promise.all(promises)

      const quoteRequest = {
        client,
        fields,
        title: this.title,
        invoice: this.invoice,
        creditCard: this.creditCard,
        installments: this.installments,
        template: this.template,
      }
      axios
        .post(`${constants.URL}/quotes`, quoteRequest)
        .then(result => {
          const id = result.data._id
          this.saving = false
          this.$router.replace(`/admin/quotes/preview/${id}`)
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    setPrice(event, field) {
      if (event) {
        field.price = event.price;
        const options = {
          wordwrap: 130
        };
        field.product.description = convert(field.product.description, options)
      }
    },
    addField() {
      switch (this.fieldType) {
        case DIVIDER:
          this.fieldInputs.push({ ...dividerSchema })
          break
        case PRODUCT:
          this.fieldInputs.push({ ...productSchema })
          break
        case CUSTOM_FIELD:
          this.fieldInputs.push(cloneDeep(customFieldSchema, true))
          break
        case IMAGEN:
          this.fieldInputs.push(cloneDeep(imagenSchema, true))
      }
    },
    removeField(index) {
      this.fieldInputs.splice(index, 1)
    },
    async formatProductField(field) {
      const { quoteType } = field
      if (quoteType === PRODUCT) {
        delete field.product._id;
        delete field.product.__v;
        delete field.product.company;
        delete field.product.owner;
        delete field.product.imagenPath;
        field.product.description = text2HTML(field.product.description)
      }
      if (quoteType === IMAGEN) {
        const { media: { fieldName, imageFile, key, image, doc } } = field
        let media = {}
        if (imageFile) {
          const formData = new FormData()
          formData.append(fieldName, imageFile)
          const imageRes = await axios.post(`${constants.URL}/media/upload`, formData)

          const mediaReq = {
            path: imageRes.data.imageFullSize.url,
            name: `${key}-logo`,
            meta: imageRes.data
          }
          const imageResponse = await axios.post(`${constants.URL}/media`, mediaReq)
          media = imageResponse.data;

        } else if (!imageFile && image !== '') {
          media = doc;
          delete media._id
        } else {
          media = null
        }
        field.media = {
          ...media,
          key
        }
      }
      delete field._id
      return field
    }
  }
}
</script>
