<template>
  <div class="row">
    <div class="col-md-12">
      <p class="text-right">
        <router-link class="btn btn-info" :to="`/admin/templates/form`">
          <i class="ti-plus"></i> Crear Plantilla
        </router-link>
      </p>
    </div>
    <div class="col-md-12">
      <div class="card">
        <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr > 
              <td colspan="2">Loading... </td>
            </tr>
          </div>
          <template v-else>
            <tr v-show="!loading" v-for="(template, index) in templates" :key="index">
              <td :id="index"> {{ index + 1 }} </td>
              <td :id="index"> {{ template.name }} </td>
              <td> {{ getTypeStr(template.type) }} </td>
              <td class="">
                <router-link class="cursor-pointer" :to="`/admin/templates/form/${template._id}`">
                  <i class="ti-pencil-alt"></i>
                </router-link>
                <a class="text-danger cursor-pointer" @click="openDeleteTemplate(index)"> 
                  <i class="ti-trash"></i> 
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      </div>

      <div v-if="totalCount > limit">
        Elementos mostrados [{{((currentPage - 1) * limit) + 1}} - {{limit * currentPage}} de {{totalCount}}]
      </div>
      <div v-else-if="totalCount < limit">
        Elemento mostrados [{{totalCount}}]
      </div>

      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar esta plantilla?
      <div class="text-center rh-group-buttons">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" @click="deleteTemplate()">Eliminar</btn>
      </div>
    </modal>
  </div>
</template>
<script>
import constants from '../constants'
import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'

export default {
  components: {
    Modal,
    Pagination,
    Btn
  },
  created () {
    this.fetchTemplates()
  },
  data () {
    return {
      loading: true,
      templates: [],
      id: '',
      modalRemove: false,
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchTemplates()
      }
    }
  },
  methods: {
    fetchTemplates () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }

      axios
        .get(`${constants.URL}/templates`, { params })
        .then(response => {
          this.totalCount = response.data.count
          this.totalPage = Math.ceil(this.totalCount / this.limit)
          this.templates = response.data.result
          this.loading = false
        })
        .catch(error => this.$notifications.notifyVue(`Error ${error.message}`, 'danger'))
    },
    changePage () {
      this.fetchTemplates()
    },
    openDeleteTemplate (index) {
      this.modalRemove = true
      this.id = index
    },
    deleteTemplate () {
      const template = this.templates[this.id]
      axios
        .delete(`${constants.URL}/templates/${template._id}`)
        .then(() => {
          this.templates.splice(this.id, 1)
          this.modalRemove = false
          this.$notifications.notifyVue('El elemento fue eliminado exitosamente', 'success')
        })
        .catch(error => this.$notifications.notifyVue(`Error ${error.message}`, 'danger'))
    },
    getTypeStr (type) {
      switch (type) {
        case 'QUOTE':
          return 'Cotizacion'
        case 'WARRANTY':
          return 'Garantia'
        default:
          return 'unknow'
      }
    }
  }
}
</script>
