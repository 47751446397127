<template>
  <div :class="sidebarClasses" :data-background-color="backgroundColor" :data-active-color="activeColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a href="#" class="simple-text">
            <div class="logo-img">
                <img src="static/img/vue-logo.png" alt="">
            </div>
          Dashboard
        </a>
      </div>
      <slot>

      </slot>
      <ul :class="navClasses">
        <li v-for="(link, index) in sidebarLinks" :key="index" :class="{ active: index == activeLinkIndex}" @click="activeLinkIndex=index">
          <template v-if="!link.children">
            <router-link :to="link.path" :ref="link.name" :key="index">
                    <i :class="link.icon"></i>
                    <p>{{link.name}}</p>
            </router-link>
          </template>
          <template v-else-if="link.children">
            <a class="cursor-pointer" @click="toggleMenu(index)">
              <i :class="link.icon"></i>

              <p>{{link.name}}
                <b class="caret"></b>
              </p>
            </a>
            <collapse v-if="link.children" v-model="link.show">
                <ul class="nav">
                  <router-link v-for="(child,index2) in link.children" :to="child.path" tag="li" :ref="child.name" :key="index2">
                    <a>{{child.name}}</a>
                  </router-link>
                </ul>
              </collapse> 
          </template>
        </li>
      </ul>
      <moving-arrow :move-y="arrowMovePx" />
    </div>
  </div>
</template>
<script>
  import MovingArrow from './MovingArrow.vue'
  import { Collapse } from 'uiv'
  import Vue from 'vue'
  export default {
    props: {
      type: {
        type: String,
        default: 'sidebar',
        validator: (value) => {
          let acceptedValues = ['sidebar', 'navbar']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundColor: {
        type: String,
        default: 'black',
        validator: (value) => {
          let acceptedValues = ['white', 'black', 'darkblue']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      }
    },
    components: {
      MovingArrow,
      Collapse
    },
    computed: {
      sidebarClasses () {
        if (this.type === 'sidebar') {
          return 'sidebar'
        } else {
          return 'collapse navbar-collapse off-canvas-sidebar'
        }
      },
      navClasses () {
        if (this.type === 'sidebar') {
          return 'nav'
        } else {
          return 'nav navbar-nav'
        }
      },
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx () {
        return this.linkHeight * this.activeLinkIndex
      }
    },
    data () {
      return {
        linkHeight: 60,
        activeLinkIndex: 0,

        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,

        show: false,
        selected: null
      }
    },
    methods: {
      findActiveLink () {
        this.sidebarLinks.find((element, index) => {
          let found = element.path === this.$route.path
          if (found) {
            this.activeLinkIndex = index
            return found
          }
          if (element.children) {
            element.children.find(child => {
              let found = child.path === this.$route.path
              if (!found) {
                element.show = false
                return found
              }
              this.activeLinkIndex = index
              element.show = true
              Vue.set(this.sidebarLinks, index, element)
              return found
            })
          }
          return found
        })
      },
      toggleMenu (index) {
        const link = this.sidebarLinks[index]
        link.show = !link.show
        Vue.set(this.sidebarLinks, index, link)
      }
    },
    mounted () {
      this.findActiveLink()
    },
    watch: {
      $route: function () {
        this.findActiveLink()
      }
    }
  }

</script>
<style>

</style>
