export function text2HTML(source) {
    // 1: Plain Text Search
    var text = source.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    // 2: Line Breaks
    text = text.replace(/\r\n?|\n/g, '<br>')
    // 3: Paragraphs
    text = text.replace(/<br>\s*<br>/g, '</p><p>')
    // 4: Wrap in Paragraph Tags
    text = '<p>' + text + '</p>'
    return text
}