<template>
  <div v-if="total > limit">
    Elementos mostrados [{{getCurrentElDisplayed()}} - {{getTotalNum()}} de {{total}}]
  </div>
  <div v-else-if="total < limit">
    Elementos mostrados [{{total}}]
  </div>
</template>
<script>
  export default{
    components: {},
    props: {
      total: {
        type: Number,
        default: 0
      },
      limit: {
        type: Number,
        default: 10
      },
      currentPage: {
        type: Number,
        default: 1
      }
    },
    computed: {},
    data () {
      return {}
    },
    methods: {
      getCurrentElDisplayed () {
        let currentVal = this.currentPage - 1
        return (currentVal * this.limit) + 1
      },
      getTotalNum () {
        let totalVal = this.limit * this.currentPage
        return (totalVal > this.total) ? this.total : totalVal
      }
    }
  }
</script>
