<template>
  <div class="row work-description-container">
    <div class="col-lg-12 col-md-7 card workspace-description">
      <tabs :tab-list="tabsList" :tab-index="view" @selected="tabSelected">
        <template v-slot:tabPanel-1>
          <div class="panel">
            <h3>Usuarios</h3>
            <hr />
            <div class="col-md-12 row-no-gutters">
              <div class="col-md-7 text-left">
                <p class="category text-left">
                  <btn type="primary" @click="openAddUserModal">
                    <i class="ti-plus"></i>  Agregar usuario
                  </btn>
                </p>
              </div>
              <div class="col-md-5">
                <div class="input-group">
                        <input 
                          type="text" 
                          class="form-control border-input" 
                          placeholder="Search" 
                          @keyup="searchUser"
                          v-model="searchUserModel"
                          id="txtSearch"/>
                        <div class="input-group-btn">
                          <button class="btn btn-primary" type="submit">
                            <span class="glyphicon glyphicon-search"></span>
                          </button>
                        </div>
                      </div>
              </div>
            </div>
            <template v-if="users.length > 0">
              <table class="table table-hover quote-table">
                <thead>
                  <tr>
                    <th width="15">
                      #
                    </th>
                    <th class="hidden-xs">Usuario</th>
                    <th class="hidden-xs">Rol</th>
                    <th class="hidden-xs">Status</th>
                    <th>
                      <i class="ti-settings"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(user, index) in users">
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ user.email }}
                      </td>
                      <td>
                        {{ user.role.name }}
                      </td>
                      <td>
                        {{ getStatus(user.status) }}
                      </td>
                      <td>
                        <a class="text-primary cursor-pointer" @click="openEditUserModal(user)">
                          <i class="ti-pencil-alt" />
                        </a>
                        <a class="text-danger space cursor-pointer" @click="openDeleteUserModal(index)">
                          <i class="ti-trash" />
                        </a>
                      </td>
                    </tr>
                </tbody>
              </table>
              <div class="pagination-container">
                <page-conter 
                  :total="userTotalCount" 
                  :limit="userLimit" 
                  :current-page="userCurrentPage" />
          
                <pagination 
                    v-if="userTotalCount > userLimit" 
                    v-model="userCurrentPage" 
                    :total-page="userTotalPage" 
                    @change="changeUserPage" />
              </div>
            </template>
            <template v-else>
              <div class="col-lg-12 col-md-7 no-users">
                Sin usuarios
              </div>
            </template>
          </div>
        </template>
        <template v-slot:tabPanel-2>
          <div class="panel">
            <h3>Roles</h3>
            <hr />
            <div class="col-md-12 row-no-gutters">
              <div class="col-md-7 text-left">
                <p class="category text-left">
                  <router-link class="btn btn-primary" :to="`/admin/role/form/${companyId}`">
                    <i class="ti-plus"></i> Crear uno nuevo
                  </router-link> 
                </p>
              </div>
              <div class="col-md-5">
                <div class="input-group">
                        <input 
                          type="text" 
                          class="form-control border-input" 
                          placeholder="Search"
                          @keyup="searchRoles"
                          v-model="searchRolModel"
                          id="rolSearch"/>
                        <div class="input-group-btn">
                          <button class="btn btn-primary" type="submit">
                            <span class="glyphicon glyphicon-search"></span>
                          </button>
                        </div>
                      </div>
              </div>
            </div>
            <template v-if="roles.length > 0 || searchRolModel">
              <table class="table table-hover quote-table">
                <thead>
                  <tr>
                    <th width="15">
                      #
                    </th>
                    <th class="hidden-xs">Nombre</th>
                    <th class="hidden-xs">Numero de recursos</th>
                    <th>
                      <i class="ti-settings"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loading">
                    <tr>
                      <td>Loading... </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr :key="index" v-for="(rol, index) in roles">
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ rol.name }}
                      </td>
                      <td>
                        {{ rol.acRoles.length }}
                      </td>
                      <td>
                        <router-link class="text-primary" :to="`/admin/role/form/${companyId}/${rol._id}`">
                          <i class="ti-pencil-alt"></i>
                      </router-link>
                        <a class="text-danger space cursor-pointer" v-if="!rol.default" @click="openDeleteRolModal(index)">
                          <i class="ti-trash" />
                        </a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <div class="pagination-container">
                <page-conter 
                  :total="rolTotalCount" 
                  :limit="rolLimit" 
                  :current-page="rolCurrentPage" />
          
                <pagination 
                    v-if="rolTotalCount > rolLimit" 
                    v-model="rolCurrentPage" 
                    :total-page="rolTotalPage" 
                    @change="changeRolPage" />
              </div>
            </template>
            <template v-else>
              <div class="col-lg-12 col-md-7 no-users">
                Sin Roles
              </div>
            </template>
          </div>
        </template>
        
      </tabs>
    </div>

    <modal v-model="showDeleteModal" title="Eliminar" :header="false" :footer="false" size="sm">
      Deseas remover este usuario?
      <div class="text-center">
        <br />
        <btn @click="showDeleteModal = false" class="cancel-space">Cancel</btn>
        <btn type="danger" @click="deleteUser()">Eliminar</btn>
      </div>
    </modal>

    <modal v-model="showRemoveRolModal" title="Eliminar" :header="true" :footer="false" size="sm">
      <template v-if="checkingRole">
        Verificando...
      </template>
      <template v-else-if="!checkingRole && canBeDeleteThisRole">
        Deseas Eliminar este Rol?
      </template>
      <template v-else>
        <form>
        <div class="row">
          <div class="col-md-12">
            No se puede eliminar este rol porque tiene usuarios asociados, para eliminarnos debes realizar una accion.
            <br />
            <br />
          </div>
          <div class="clearfix"></div>
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('roleDeleteAction')}">
                  <div class="form-group">
                      <div>
                        <label>Tipo de accion</label>
                      </div>
                      <select class="form-control border-input" 
                        name="roleDeleteAction"
                        v-model="rolCheckInput"
                        v-validate="'required'">
                          <option value="DELETE-USERS">
                            Remover usuarios asociados
                          </option>
                          <option value="CHANGE-ROLE">
                            Asignarles un nuevo Rol
                          </option>
                      </select>
                    </div>
              <span class="help-block text-left">{{ errors.first('roleDeleteAction') }}</span>
            </div>
          </div>
          <div class="col-md-12" v-if="rolCheckInput === 'CHANGE-ROLE'">
            <div class="form-group" :class="{'has-error': errors.first('changeRoleInput')}">
                  <div class="form-group">
                      <div>
                        <label>Nuevo Rol</label>
                      </div>
                      <select class="form-control border-input" 
                        name="changeRoleInput"
                        v-model="newRolModel"
                        v-validate="'required'">
                        <option v-for="(role, index) in changeRoleList" :value="role._id" :key="index">
                          {{ role.name }}
                        </option>
                      </select>
                    </div>
              <span class="help-block text-left">{{ errors.first('changeRoleInput') }}</span>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      </template>
      <div class="text-center">
        <br />
        <btn @click="showRemoveRolModal = false" class="cancel-space">Cancel</btn>
        <btn type="danger" :disabled="errors.any() || !isValidRolDeleteForm" @click="deleteRol">Eliminar</btn>
      </div>
    </modal>

    <modal v-model="showModalAddUser" title="Crear producto" size="sm">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('email')}">
              <label>Email</label>
              <input
                v-model="user.email"
                class="form-control border-input"
                v-validate="'required|email'"
                name="email"
                type="email"
                :disabled="id"
              />
              <span class="help-block text-left">{{ errors.first('email') }}</span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('role')}">
              <label>Rol</label>
              <select class="form-control border-input" 
                  name="role"
                  v-model="user.role"
                  v-validate="'required'">
                  <option v-for="(role, index) in roleCompanyList" :value="role._id" :key="index">
                    {{ role.name }}
                  </option>
                </select>
              <span class="help-block text-left">{{ errors.first('role') }}</span>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="showModalAddUser=false">Cancel</btn>
        <btn type="primary" @click="saveUser" :disabled="saving">
          Guardar
        </btn>
      </div>
    </modal>
  </div>
</template>
<style lang="scss">
.cancel-space {
    margin-right: 10px;
}

.workspace-description {
    padding-top: 25px;
    height: 100%;
}

.panel {
    h3 {
        margin-top: 10px;
    }
}

.work-description-container {
    height: 100%;
}

.no-users {
    padding-top: 40px;
    text-align: center;
}

.pagination-container {
    padding-top: 40px;
    position: relative;
    text-align: right;
}
</style>
<script>
import { Modal, Btn, Pagination } from 'uiv';
import constants from '../constants';
import Tabs from '../components/Tabs/Tabs.vue';
import PageConter from '../components/PageCounter.vue';

const userBase = {
  email: ''
}
export default {
  components: {
    Modal, 
    Btn,
    Tabs,
    Pagination,
    PageConter
  },
  mounted() {},
  async created() {
    this.companyId = this.$route.params.id;
    this.view = this.$route.query?.view === 'roles' ? 2 : 1
    await this.getRoles();
    await this.getCompanyRoles();
    await this.getUsers();
    this.$store.dispatch('setContentSize', 'no-padding')
  },
  computed: {
    changeRoleList() {
      const currentRole = this.roles[this.index];
      return this.roleCompanyList.filter(i => i.name !== currentRole.name);
    },
    isValidRolDeleteForm() {
      if (this.canBeDeleteThisRole || this.rolCheckInput === 'DELETE-USERS') {
        return true;
      } else if (this.rolCheckInput === 'CHANGE-ROLE') {
        return !!this.newRolModel
      }

      return false;
    }
  },
  destroyed() {
    this.$store.dispatch('setContentSize', 'padding')
  },
  data() {
    return {
      tabsList: [
        'Usuarios',
        'Roles'
      ],
      companyId: null,
      loading: false,
      roles: [],
      roleCompanyList: [],
      rolLimit: 5,
      rolTotalPage: 0,
      rolCurrentPage: 1,
      rolTotalCount: 0,
      checkingRole: false,
      rolCheckInput: null,
      newRolModel: null,
      canBeDeleteThisRole: false,
      searchRolModel: '',
      users: [],
      userLimit: 5,
      userTotalPage: 0,
      userCurrentPage: 1,
      userTotalCount: 0,
      searchUserModel: '',
      showModalAddUser: false,
      showDeleteModal: false,
      showRemoveRolModal: false,
      index: 0,
      view: 1,
      saving: false,
      id: null,
      user: {
        ...userBase
      }
    }
  },
  watch: {},
  methods: {
    getStatus(status) {
      switch (status) {
        case 'PENDING':
          return 'pendiente';
        case 'ACTIVE':
          return 'activo';
        case 'INACTIVE':
          return 'inactivo';
        default:
          return 'Desconocido'
      }
    },
    async searchUser() {
      await this.getUsers();
    },
    async searchRoles() {
      await this.getRoles();
    },
    async changeRolPage () {
      await this.getRoles()
    },
    async changeUserPage () {
      await this.getUsers()
    },
    async getCompanyRoles() {
      try {
        const response = await this.$http.get(`${constants.URL}/roles/all/${this.companyId}`);
        const { result } = response.data
        this.roleCompanyList = result;
      } catch (error) {
        console.log();
      }
    },
    tabSelected(index) {
      const query = {
        view: index === 1 ? 'users' : 'roles'
      }
      this.$router.replace({ query }, () => {});
    },
    openDeleteRolModal(index) {
      const role = this.roles[index];
      this.canBeDeleteThisRole = false;
      this.index = index;
      this.showRemoveRolModal = true;
      this.checkUsersByRole(role._id);
      this.rolCheckInput = '';
      this.newRolModel = '';
    },
    openDeleteUserModal(index) {
      this.index = index;
      this.showDeleteModal = true;
    },
    openAddUserModal() {
      this.user = {
        ...userBase
      };
      this.id = null;
      this.$validator.reset();
      this.showModalAddUser = true;
    },
    openEditUserModal(user) {
      this.user = {
        ...user,
        role: user.role._id
      };
      this.id = user._id
      this.$validator.reset();
      this.showModalAddUser = true;
    },
    async getRoles() {
      try {
        this.loading = true
        const params = {
          skip: (this.rolCurrentPage - 1) * this.rolLimit,
          take: this.rolLimit
        }
        if (this.searchRolModel) {
          params.q = this.searchRolModel
        }
        const response = await this.$http.get(`${constants.URL}/roles/${this.companyId}`, { params });
        const { result: { result, count } } = response.data;
        this.rolTotalCount = count
        this.rolTotalPage = Math.ceil(this.rolTotalCount / this.rolLimit)

        this.roles = result;
        this.loading = false;
      } catch (error) {
        const { response: { data }} = error;
        this.$notifications.notifyVue(`Error: ${data.message}`, 'danger');
      }
    },
    async checkUsersByRole(id) {
      try {
        this.checkingRole = true
        const params = {
          filters: JSON.stringify({
            param: 'role',
            condition: 'equal',
            value: id
          })
        }
        const response = await this.$http.get(`${constants.URL}/company/users/${this.companyId}`, { params });
        const { count } = response.data;
        this.canBeDeleteThisRole = count === 0;
        this.checkingRole = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getUsers() {
      try {
        const params = {
          skip: (this.userCurrentPage - 1) * this.userLimit,
          take: this.userLimit
        }
        if (this.searchUserModel) {
          params.q = this.searchUserModel
        }
        const response = await this.$http.get(`${constants.URL}/company/users/${this.companyId}`, { params });
        const { result, count } = response.data;
        this.userTotalCount = count
        this.userTotalPage = Math.ceil(this.userTotalCount / this.userLimit)
        this.users = result;
      } catch (error) {
        const { response: { data }} = error;
        this.$notifications.notifyVue(`Error: ${data.message}`, 'danger');
      }
    },
    async saveUser() {
      if (!this.id) {
        await this.addUser();
      } else {
        await this.updateUser();
      }
    },
    async addUser() {
      try {
        this.saving = true;
        await this.$http.post(`${constants.URL}/company/users/share/${this.companyId}`, this.user);
        await this.getUsers();
        this.showModalAddUser = false;
        this.saving = false;
      } catch (error) {
        const { response: { data }} = error;
        this.$notifications.notifyVue(`Error: ${data.message}`, 'danger');
        this.saving = false;
      }
    },
    async updateUser() {
      try {
        this.saving = true;
        const params = {
          role: this.user.role
        }
        await this.$http.put(`${constants.URL}/company/users/${this.companyId}/${this.id}`, params);
        await this.getUsers();
        this.showModalAddUser = false;
        this.saving = false;
      } catch (error) {
        const { response: { data }} = error;
        this.$notifications.notifyVue(`Error: ${data.message}`, 'danger');
        this.saving = false;
      }
    },
    async deleteRol() {
      try {
        const role = this.roles[this.index];
        const params = {}
        if (!this.canBeDeleteThisRole) {
          params.action = this.rolCheckInput;
          if (this.rolCheckInput === 'CHANGE-ROLE') {
            params.role = this.newRolModel
          }
        }
        await this.$http.delete(`${constants.URL}/roles/${this.companyId}/${role._id}`, { data: params });
        this.roles.splice(this.index, 1);
        await this.getUsers();
        this.showRemoveRolModal = false;
      } catch (error) {
        const { response: { data } } = error;
        this.$notifications.notifyVue(`Error: ${data.message}`, 'danger');
        this.showRemoveRolModal = false;
      }
    },
    async deleteUser() {
      try {
        const user = this.users[this.index];
        await this.$http.delete(`${constants.URL}/company/users/${this.companyId}/${user._id}`);
        this.users.splice(this.index, 1);
      } catch (error) {
        const { response: { data } } = error;
        this.$notifications.notifyVue(`Error: ${data.message}`, 'danger');
      }
      this.showDeleteModal = false;
    }
  }
}

</script>
