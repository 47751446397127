<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="defaultView">

    </side-bar>
    <notifications>

    </notifications>
    <div class="main-panel">
      <top-navbar />
      <dashboard-content @click.native="toggleSidebar" ></dashboard-content>
      <content-footer />
      <pop-up-invite ref="inviteModal"></pop-up-invite>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import PopUpInvite from '../../components/PopUpInvite.vue'
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      PopUpInvite
    },
    computed: {
      defaultView() {
        let sideBar;
        if (this.$store.state.view) {
          const view = JSON.parse(this.$store.state.view);
          sideBar = view.sidebar;
        } else {
          sideBar = this.$sidebar.sidebarLinks;
        } 
        return sideBar;
      }
    },
    mounted(){
      this.hasInvite();
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      hasInvite() {
        if (this.$store.state.inviteToken) {
          console.log('true')
          console.log(this.$refs.inviteModal.show())
        }
      }
    }
  }

</script>
