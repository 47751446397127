<template>
  <div class="row">

    <div class="col-md-12">
      <div class="col-md-7 text-left">
        <p class="category text-left">
          <btn type="primary" @click="openAddWorkspace()">
            <i class="ti-plus"></i> Crear nuevo
          </btn>
        </p>
      </div>
      <div class="col-md-5">
        <div class="input-group">
          <input type="text" class="form-control border-input" placeholder="Search" @keyup="search" v-model="searchModel"
            id="txtSearch" />
          <div class="input-group-btn">
            <button class="btn btn-primary" type="submit">
              <span class="glyphicon glyphicon-search"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-7">
      <div class="card">
        <table class="table table-hover quote-table">
          <thead>
            <tr>
              <th width="15">
                <i v-tooltip.right="'Marcar como favorito'" class="fa fa-star-o text-warning" />
              </th>
              <th class="hidden-xs" width="200">Nombre</th>
              <th>Logo</th>
              <th>Cuenta</th>
              <th>Status</th>
              <th>Propietario</th>
              <th width="15">
                <i class="ti-settings"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr>
                <td>Loading... </td>
              </tr>
            </template>
            <template v-else>
              <tr :key="index" v-for="(company, index) in companies">
                <td :id="index">
                  <div class="checkbox custom-checkbox checkbox-primary">
                    <input :id="`check-id-${index}`" type="checkbox" v-model="company.checked"
                      @click="onClickFavorite($event, company)">
                    <label :for="`check-id-${index}`">
                    </label>
                  </div>
                </td>
                <td :id="index">
                  <router-link :to="`/admin/workspaces/${company._id}`">
                    {{company.name}}
                  </router-link>
                  <span v-if="defaultCompany == company._id" class="label label-default">defecto</span>
                </td>
                <td :id="index">
                  <img height="100" v-if="company.logo" :src="company.logo?.meta?.imageThumb?.url" />
                </td>
                <td :id="index">{{ company.account === 'FREE' ? 'Gratuita' : 'Otra' }}</td>
                <td :id="index">{{ company.status ? 'Activa' : 'Inactiva' }}</td>

                <td :id="index">
                  {{ company.owner ? `${company.owner.firstName} ${company.owner.lastName}` : '' }}
                </td>
                <td :id="index">
                  <a class="cursor-pointer" @click.prevent="openCxtMenu(company, index)">
                    <i class="ti-more-alt"></i>
                  </a>
                  <!-- <a v-if="company.canEdit" class="cursor-pointer text-info space" @click="edit(company)">
                    <i class="ti-pencil-alt"></i>
                  </a>
                  <a v-if="company.canDelete" class="text-danger cursor-pointer" @click="openDeleteModal(index)">
                    <i class="ti-trash" />
                  </a> -->
                </td>
              </tr>

            </template>
          </tbody>
        </table>
      </div>
      <page-conter :total="totalCount" :limit="limit" :current-page="currentPage" />
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage" />
    </div>
    <modal v-model="showDeleteModal" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar esta empresa?
      <div class="text-center">
        <br />
        <btn @click="showDeleteModal = false" class="cancel-space">Cancel</btn>
        <btn type="danger" @click="deleteItem()">Eliminar</btn>
      </div>
    </modal>
    <modal v-model="showNewModal" title="Crear empresa" size="lg">
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" :class="{ 'has-error': errors.first('name') }">
              <label>Nombre</label>
              <input v-model="workspace.name" class="form-control border-input" v-validate="'required'" name="name"
                type="text" placeholder="Nombre" />
              <span class="help-block text-left">{{ errors.first('name') }}</span>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group" :class="{ 'has-error': errors.first('address') }">
              <label>Direccion</label>
              <input v-model="workspace.address" class="form-control border-input" name="address" type="text"
                placeholder="Nombre" />
              <span class="help-block text-left">{{ errors.first('address') }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <media-buttons v-model="image" @change="onChangeFile" @showMedia="$refs.mediaList.show()" />
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Descripcion </label>
              <textarea rows="6" v-model="workspace.description" class="form-control border-input"
                placeholder="Descripcion" />
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="showNewModal = false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !isCompleted" @click="save() || saving">
          Guardar
        </btn>
      </div>
    </modal>

    <media-select :showModal="false" ref="mediaList" @selected="onImageSelected" />

    <context-menu class="cursor-pointer" id="context-menu" ref="ctxMenu">
        <li @click="makeDefault(workspace)"> <!-- logica por defacto y mostrar acciones si tienen permisos-->
          <i class="ti-check"></i> Mostrar al inicio
        </li>
        <li v-if="workspace.canEdit" @click="edit(workspace)">
          <i class="ti-pencil-alt"></i> Editar
        </li>
        <li v-if="workspace.canDelete" class="text-danger" @click="openDeleteModal()">
          <i class="ti-trash"></i> Eliminar
        </li>
      </context-menu>
  </div>
</template>
<style lang="scss">
.cancel-space {
  margin-right: 10px;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-checkbox {
  margin-bottom: 0px !important;

  label {
    font-size: 13px !important;
  }

  display: inline !important;
  padding-left: 20px !important;
}
</style>
<script>
import axios from 'axios'
import { Modal, Btn, Pagination } from 'uiv'
import constants from '../constants'
import MediaButtons from '../components/Media/MediaButtons.vue'
import MediaSelect from '../components/Media/MediaSelect'
import PageConter from '../components/PageCounter.vue'
import contextMenu from 'vue-context-menu'

const workspaceSchema = {
  name: '',
  logo: {},
  address: '',
  description: ''
}

export default {
  components: {
    Modal,
    Btn,
    MediaButtons,
    MediaSelect,
    Pagination,
    PageConter,
    contextMenu,
  },
  created() {
    this.getCompanies()
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted() {
      return this.workspace.name
    }
  },
  data() {
    return {
      fieldName: '',
      imageFile: '',
      mediaFile: {},
      image: '',
      loading: false,
      modal: false,
      workspace: { ...workspaceSchema },
      showDeleteModal: false,
      showNewModal: false,
      showImageModal: false,
      companies: [],
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: ''
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.getCompanies()
      }
    }
  },
  methods: {
    openDeleteModal() {
      this.showDeleteModal = true
      //this.id = index
    },
    deleteItem() {

    },
    makeDefault(workspace) {
      this.$store.dispatch('setDefaulCompany', workspace._id)
    },
    openCxtMenu (workspace, index) {
      this.workspace = workspace;
      this.id = index;
      this.$refs.ctxMenu.open();
    },
    async onClickFavorite(e, company) {
      const newList = this.companies.map(item => {
        if (item._id === company._id) {
          item.checked = e.target.checked
        }
        return item
      })
      const list = newList.filter(item => item.checked);
      if (list.length > 3) {
        e.preventDefault();
        return;
      }

      const workspaceList = newList
        .filter(item => item.checked)
        .map(item => item._id)
        .join('|');

      const request = {
        key: 'workspaceList',
        value: workspaceList
      }
      await this.$http.post(`${constants.URL}/users/preferences`, request)

      this.$store.dispatch('updateWorkspaceList', workspaceList)
    },
    save() {
      this.saving = true
      if (this.workspace._id) {
        this.update()
      } else {
        this.addWorkspace()
      }
    },
    openAddWorkspace() {
      this.$validator.reset()
      this.image = ''
      this.workspace = {
        ...workspaceSchema
      }
      this.showNewModal = true
    },
    onChangeFile(e) {
      this.fieldName = e.name;
      this.imageFile = e.file;
    },
    onImageSelected(media) {
      this.mediaFile = media;
      this.image = media?.meta?.imageThumb?.url;
    },
    edit(workspace) {
      this.workspace = { ...workspace }
      this.image = workspace.logo?.meta?.imageThumb?.url
      this.imageFile = null
      this.showNewModal = true;
    },
    async update() {
      try {
        const workspaceReq = {
          name: this.workspace.name,
          address: this.workspace.address,
          description: this.workspace.description
        }
        const id = this.workspace._id

        if (this.imageFile) {
          const formData = new FormData()
          formData.append(this.fieldName, this.imageFile)
          const imageRes = await axios.post(`${constants.URL}/media/upload`, formData)

          const mediaReq = {
            path: imageRes.data.imageFullSize.url,
            name: `${workspaceReq.name}-logo`,
            meta: imageRes.data
          }
          const imageResponse = await axios.post(`${constants.URL}/media`, mediaReq)
          workspaceReq.logo = imageResponse.data._id;
        } else if (!this.imageFile && this.image !== '') {
          workspaceReq.logo = this.mediaFile._id
        } else {
          workspaceReq.logo = null
        }
        await axios.put(`${constants.URL}/companies/${id}`, workspaceReq)
        this.showNewModal = false
        this.saving = false
        this.getCompanies()
        this.$notifications.notifyVue(`El espacio se actualizo correctamente`, 'success')
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Ocurrio un error ${error}`, 'danger')
      }
    },
    async addWorkspace() {
      try {
        const workspaceReq = { ...this.workspace }
        if (this.imageFile) {
          const formData = new FormData()
          formData.append(this.fieldName, this.imageFile)
          const imageRes = await axios.post(`${constants.URL}/media/upload`, formData)

          const mediaReq = {
            path: imageRes.data.imageFullSize.url,
            name: `${workspaceReq.name}-logo`,
            meta: imageRes.data
          }
          const mediaResponse = await axios.post(`${constants.URL}/media`, mediaReq)
          workspaceReq.logo = mediaResponse.data._id
        } else if (!this.imageFile && this.image !== '') {
          workspaceReq.logo = this.mediaFile._id
        } {
          delete workspaceReq.logo;
        }
        await axios.post(`${constants.URL}/companies`, workspaceReq)
        this.$notifications.notifyVue(`El espacio se guardo correctamente`, 'success')
        this.showNewModal = false
        this.saving = false
        this.getCompanies()
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
      }
    },
    async fetchPreferences() {
      const response = await axios.get(`${constants.URL}/users/preferences`)
      const preferences = response.data || [];
      const result = preferences.find(item => item.key === 'workspaceList');
      const workspaceList = result?.value ?? ''
      return workspaceList.split('|');
    },
    async changePage() {
      await this.getCompanies()
    },
    async search() {
      await this.getCompanies()
    },
    async getCompanies() {
      try {
        this.loading = true
        const params = {
          skip: (this.currentPage - 1) * this.limit,
          take: this.limit
        }
        if (this.searchModel) {
          params.q = this.searchModel
        }
        let companies = []
        //get sharedPreferences
        const sharedWorkspaceList = await this.fetchPreferences();

        //get my companies
        const myCompaniesResponse = await axios.get(`${constants.URL}/companies`, { params })
        const { result: myCompanies, count: myCompaniesCount } = myCompaniesResponse.data;

        companies = myCompanies.map(company => {
          const isFavorite = sharedWorkspaceList.some(item => item === company._id);
          company.canDelete = true;
          company.canEdit = true;
          company.checked = isFavorite;
          return company;
        });

        const { result: sharedCompanies, sharedCount } = await this.getShareCompanies(params, sharedWorkspaceList);

        this.companies = companies.concat(sharedCompanies)
        this.totalCount = myCompaniesCount + sharedCount
        this.totalPage = Math.ceil(this.totalCount / this.limit)
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    async getShareCompanies(params, sharedWorkspaceList) {
      try {
        //get shared companies
        const sharedCompaniesResponse = await axios.get(`${constants.URL}/company/users/share`, { params })
        const { result: sharedCompanies, count: sharedCount } = sharedCompaniesResponse.data
        const newArray = sharedCompanies.map(item => {
          const { company } = item;
          const isFavorite = sharedWorkspaceList.some(_item => _item === company._id);
          return {
            ...company,
            checked: isFavorite,
            canDelete: false,
            canEdit: this.defaultCompany === company._id
          }
        })
        return { result: newArray, sharedCount };
      } catch (error) {
        console.error(error);
        return { result: [], sharedCount: 0 };
      }
    }
  }
}

</script>
