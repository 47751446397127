import axios from 'axios'
import constants from '../../constants'
// share if is required
async function fetchPreferences() {
    const response = await axios.get(`${constants.URL}/users/preferences`)
    const preferences = response.data || [];
    const result = preferences.find(item => item.key === 'workspaceList');
    const workspaceList = result?.value ?? ''
    return workspaceList.split('|');
}

async function fetchCompanies(shareWorkspaces = [], defaultCompany) {

    //get my companies
    const myCompaniesResponse = await axios.get(`${constants.URL}/companies`)
    const { result: myCompanies } = myCompaniesResponse.data
    const myCompaniesList = myCompanies.map(company => {
        company.canDelete = true;
        return company;
    });
    
    const shareCompanies = await fetchShareCompanies();
    const allCompanies = myCompaniesList.concat(shareCompanies);
    let workspaces = allCompanies.filter(company => 
        shareWorkspaces.some(item => item === company._id) || 
        company._id === defaultCompany
    );

    if (!workspaces || workspaces.length === 0) {
        workspaces = allCompanies.length >= 3 ? allCompanies.slice(0, 3) : allCompanies;
    }

    return workspaces
}
async function fetchShareCompanies() {
    try {
        //get shared companies
        const sharedCompaniesResponse = await axios.get(`${constants.URL}/company/users/share`)
        const { result: sharedCompanies } = sharedCompaniesResponse.data
        const newArray = sharedCompanies.map(item => {
            const { company } = item
            return {
                ...company,
                canDelete: false
            }
        })
        return newArray;
    } catch (error) {
        return [];
    }
}

async function updateWorkspaceList({ commit }) {
        try {
            const shareWorkspaces = await fetchPreferences();
            const defaultCompany = this.state.company;
            const workspaceList = await fetchCompanies(shareWorkspaces, defaultCompany);
            const storeList = workspaceList.map(item => ({
                name: item.name,
                _id: item._id
            }))
            commit('update_workspace_list', storeList);
        } catch (error) {
            console.error(error);
            return [];
        }
}

export { updateWorkspaceList }