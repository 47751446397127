<template>
  <div class="row">
    <div class="col-md-12">
      <p class="category text-right rh-group-buttons">
        <btn 
          name="button" 
          type="danger"
          :disabled="exporting"
          @click="exportToPdf()">
          Exportar
        </btn>
        <router-link class="btn btn-info" :to="`/admin/quotes/form/${id}`">
          Editar
        </router-link>
        <router-link class="btn btn-default" :to="`/admin/quotes`">
          Salir
        </router-link>
      </p>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="header">
          <h4 class="title">Vista previa</h4>
        </div>
        <div class="content">
          <iframe 
            v-bind:src="`${url}/quotes/render/${defaultCompany}/${id}`"
            style="width: 100%; min-height: 500px"
            frameborder="0" />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
</style>

<script>
import constants from '../constants'
import { Btn } from 'uiv'
import axios from 'axios'

export default {
  components: {
    Btn
  },
  mounted () {},
  created () {
    this.getQuoteInfo()
  },
  data () {
    return {
      exporting: false,
      quote: {}
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    url () {
      return constants.URL
    },
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.$router.replace('/admin/quotes')
      }
    }
  },
  methods: {
    async getQuoteInfo () {
      try {
        const response = await axios.get(`${constants.URL}/quotes/${this.defaultCompany}/${this.id}`)
        this.quote = response.data
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    exportToPdf () {
      this.exporting = true
      const params = {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      }
      axios.get(`${constants.URL}/quotes/export/${this.defaultCompany}/${this.id}`, params)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          const fileName = this.quote.title || this.uuidv4()
          link.href = url
          link.setAttribute('download', `${fileName}.pdf`) // or any other extension
          document.body.appendChild(link)
          link.click()
          this.exporting = false
        })
        .catch(error => {
          this.exporting = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0
        let v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  }
}
</script>
