<template>
  <div class="row">
    <div class="col-md-12 row-no-gutters">
      <div class="col-md-7 text-left">
        <p class="category text-left rh-group-buttons"> 
          <btn type="primary" @click="openAddClient()">
            <i class="ti-plus"></i> Agregar cliente
          </btn>
          <btn type="default" @click="$refs.sortBy.show()">
            <i class="ti-arrows-vertical"></i> Ordenar
          </btn>
        </p>
      </div>
      <div class="col-md-5">
        <div class="input-group">
                <input 
                  type="text" 
                  class="form-control border-input" 
                  placeholder="Search" 
                  @keyup="search"
                  v-model="searchModel"
                  id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card car-overflow">
        <table class="table table-hover">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Telefono</th>
            <th class="hidden-xs">Direccion</th>
            <th class="hidden-xs">Nit</th>
            <th class="hidden-xs">Tipo</th>
            <th class="hidden-xs">Descripcion</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </template>
          <template v-else>
            <tr  v-for="(client, index) in clients" :key="index">
            <td :id="index"> {{ client.name }} </td>
            <td :id="index"> {{ client.phone }} </td>
            <td class="hidden-xs" :id="index"> {{ client.address }} </td>
            <td class="hidden-xs" :id="index"> {{ client.nit }} </td>
            <td class="hidden-xs" :id="index"> {{ client.type === 'PERSON' ? 'Persona' : 'Empresa' }} </td>
            <td class="hidden-xs" :id="index"> {{ client.descripcion }} </td>
            <td class="text-center">
              <a class="text-info cursor-pointer" @click="editClient(client)">
                <i class="ti-pencil-alt" />
              </a>
              <a class="text-danger cursor-pointer" @click="openDeleteClient(index)">
                <i class="ti-trash" />
              </a>
            </td>
          </tr>
          </template>
        </tbody>
      </table>
      </div>
      <page-conter :total="totalCount" :limit="limit" :current-page="currentPage" />
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar este cliente?
      <div class="text-center">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" @click="deleteClient()">Eliminar</btn>
      </div>

    </modal>
    <client-form v-model="client" :modal-client="false" ref="modalClient" @saveClient="saveClient" />
    <SortBy 
      ref="sortBy" 
      :modalProp="false" 
      :byValue="byModel" 
      :byCriteo="criteoValueDefault" 
      :by="sortByParams" 
      @update="sortByEvent"/>
  </div>
</template>
<script>
import constants from '../constants'
import { Modal, Btn, Pagination } from 'uiv'
import PageConter from '../components/PageCounter'
import axios from 'axios'

import ClientForm from '../components/Forms/ClientForm.vue'
import SortBy from '../components/Filters/SortBy'

// sort by parameters

const sortByParams = [
  {
    id: 'name',
    name: 'Nombre'
  },
  {
    id: 'email',
    name: 'Correo electronico'
  },
  {
    id: 'phone',
    name: 'Telefono'
  },
  {
    id: 'nit',
    name: 'Nit'
  },
  {
    id: 'createAt',
    name: 'Fecha de creacion'
  }
]
const criteoParams = [
  {
    id: 'asc',
    name: 'Ascendente'
  },
  {
    id: 'desc',
    name: 'Descendente'
  }
]
const criteoValueDefault = {
  id: 'asc',
  name: 'Ascendente'
}

const byModel = {
  id: 'name',
  name: 'Nombre'
}

// client parameters
const manager = {
  name: '',
  position: '',
  email: '',
  phone: ''
}
const client = {
  name: '',
  descripcion: '',
  email: '',
  phone: '',
  address: '',
  nit: '',
  type: 'PERSON',
  managers: [{ ...manager }]
}

export default {
  components: {
    Modal,
    Pagination,
    Btn,
    PageConter,
    ClientForm,
    SortBy
  },
  created () {
    const { limit, currentPage, sort, search } = this.$route.query
    if (limit && currentPage) {
      this.limit = parseInt(limit)
      this.currentPage = parseInt(currentPage)
    }
    if (sort) {
      let sortObj = JSON.parse(sort)
      const byModel = this.sortByParams.find(item => sortObj.by === item.id)
      const criteo = criteoParams.find(item => sortObj.criteo === item.id)
      if (byModel) {
        this.byModel = byModel
      }
      if (criteo) {
        this.criteo = criteo
      }
      if (byModel && criteo) {
        this.sortBy = {}
        this.sortBy[sortObj.by] = sortObj.criteo
      }
    }
    if (search) {
      this.searchModel = search
    }
    this.fetchClients()
  },
  data () {
    return {
      loading: false,
      saving: false,
      clients: [],
      client: { ...client },
      modalClient: false,
      modalRemove: false,
      id: '',
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: '',
      sortByParams: [...sortByParams],
      criteoValueDefault,
      sortBy: null,
      byModel
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted () {
      return this.client.name &&
        this.client.phone &&
        this.client.address &&
        this.client.type &&
        this.client.nit
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchClients()
      }
    }
  },
  methods: {
    search () {
      const params = {
        query: {
          ...this.$route.query,
          search: this.searchModel
        }
      }
      this.$router.push(params)
      this.fetchClients()
    },
    fetchClients () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit,
        sort: JSON.stringify({ name: 'asc' })
      }
      if (this.searchModel) {
        params.q = this.searchModel
      }

      if (this.sortBy) {
        params.sort = JSON.stringify(this.sortBy)
      }

      axios
        .get(`${constants.URL}/clients`, { params })
        .then(response => {
          this.totalCount = response.data.count
          this.totalPage = Math.ceil(this.totalCount / this.limit)
          this.clients = response.data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$notifications.notifyVue(`Error: ${error}`, '')
        })
    },
    async changePage () {
      const params = {
        query: {
          ...this.$route.query,
          limit: this.limit,
          currentPage: this.currentPage
        }
      }
      this.$router.push(params)
      await this.fetchClients()
    },
    openAddClient () {
      this.$refs.modalClient.show('ADD')
    },
    deleteManager (index) {
      this.client.managers.splice(index, 1)
      if (this.client.managers.length === 0) {
        this.addManager()
      }
    },
    editClient (client) {
      this.client = client
      this.$refs.modalClient.show('EDIT')
    },
    saveClient () {
      this.fetchClients()
    },
    openDeleteClient (index) {
      this.modalRemove = true
      this.id = index
    },
    deleteClient () {
      const client = this.clients[this.id]
      axios
        .delete(`${constants.URL}/clients/${client._id}`)
        .then(() => {
          this.clients.splice(this.id, 1)
          this.modalRemove = false
        })
        .catch(error => this.$notifications.notifyVue(`Error: ${error}`, ''))
    },
    sortByEvent (params) {
      this.sortBy = {}
      this.sortBy[params.by] = params.criteo

      const routeParams = {
        query: {
          ...this.$route.query,
          ...params
        }
      }
      this.$router.replace(routeParams)

      this.fetchClients()
    }
  }
}
</script>
