<template>
    <div>
        <modal v-model="visible" title="Seleccionar imagen" :header="true" :footer="true">
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group">
                        <input type="text" class="form-control border-input" placeholder="Search" @keyup="search"
                            v-model="searchModel" id="txtSearch" />
                        <div class="input-group-btn">
                            <button class="btn btn-primary" type="submit">
                                <span class="glyphicon glyphicon-search"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 card" v-for="(media, index) in mediaFiles" :key="index">
                    <div class="card text-center">
                        <div class="image-background" @click="onClick(media)"
                            v-bind:style="`background: url(${media.meta.imageThumb.url}); background-size:cover;`" />
                        <span>{{ media.name }}</span>
                    </div>
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-6 page-counter">
                        <page-conter :total="totalCount" :limit="limit" :current-page="currentPage" />
                    </div>
                    <div class="col-md-6">
                        <pagination class="text-right" v-if="totalCount > limit" v-model="currentPage"
                            :total-page="totalPage" @change="changePage" :max-size="2" />
                    </div>
                </div>
            </div>
            <div class="text-center" slot="footer">
                <btn @click="visible = false" class="cancel-space">Cancel</btn>
            </div>
        </modal>
    </div>
</template>
<style lang="scss">
.image-background {
    width: 100%;
    height: 90px;
    cursor: pointer;
}

.page-counter {
    padding-top: 26px;
}
</style>
<script>
import { Modal, Btn, Pagination } from 'uiv'
import PageConter from '../../components/PageCounter.vue'
import constants from '@/constants'
export default {
    components: { Btn, Modal, Pagination, PageConter },
    created() { },
    mounted() { },
    computed: {},
    props: {
        showModal: Boolean
    },
    data() {
        return {
            limit: 8,
            totalPage: 0,
            currentPage: 1,
            totalCount: 0,
            loading: true,
            mediaFiles: [],
            searchModel: '',
            visible: this.showModal
        }
    },
    methods: {
        show() {
            this.visible = true
            this.fetchMedia()
        },
        search () {
            this.fetchMedia()
        },
        async changePage() {
            await this.fetchMedia()
        },
        onClick(media) {
            this.$emit('selected', media);
            this.visible = false;
        },
        async fetchMedia() {
            try {
                this.loading = true
                const params = {
                    skip: (this.currentPage - 1) * this.limit,
                    take: this.limit
                }
                if (this.searchModel) {
                    params.q = this.searchModel
                }
                const response = await this.$http.get(`${constants.URL}/media`, { params })
                this.totalCount = response.data.count
                this.totalPage = Math.ceil(this.totalCount / this.limit)
                this.mediaFiles = [...response.data.result]
                this.loading = false
            } catch (error) {
                this.$notifications.notifyVue(`Error: ${error}`, '')
                this.loading = false
            }
        }
    }
}
</script>