<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <a class="navbar-brand">{{routeName}}</a>
      </div>
      <div class="navbar-right-menu">
        <ul class="nav navbar-nav navbar-right">
             <drop-down title="Mi cuenta" icon="ti-user" class="cursor-pointer">
               <li>
                <a 
                  v-for="(workspace, index) in workspaces" 
                  :key="index" 
                  :class="{'selected': workspace._id === defaultCompany}" 
                  @click="selectCompany(workspace._id)">
                  <span v-if="workspace._id === defaultCompany" class="ti-check" /> {{ workspace.name }}
                </a>
                  <hr />
                 <router-link :to="`/user/profile`">
                  Perfil
                 </router-link>
                 <router-link :to="`/admin/workspaces`">
                  Empresas
                 </router-link>
               </li>
               <li><a href="#" @click="logout()">Cerrar sesion</a></li>
             </drop-down>

        </ul>
      </div>
    </div>
  </nav>
</template>
<style lang="scss">
  .space{
    padding-left: 5px;
  }
  .navbar-nav > li > .dropdown-menu {
    border-radius: 4px;
  }
  .dropdown-menu {
    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    & > li > a {
      padding-left: 33px !important;
    }
    .selected {
      padding-left: 15px !important;
    }
  }
</style>
<script>
  export default {
    async mounted() {
      await this.$store.dispatch('updateWorkspaceList');
    },
    computed: {
      defaultCompany() {
        return this.$store.state.company;
      },
      workspaceList() {
        return this.$store.state.workspaceList;
      },
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false,
        workspaces: []
      }
    },
    watch: {
      workspaceList (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.workspaces = newValue
        }
      },
      defaultCompany (newValue, oldValue) {
        if (newValue !== oldValue) {
         // this.defaultCompany = newValue
        }
      }
    },
    methods: {
      logout () {
        this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
        .catch(() => {
          this.notifyVue('Error al intentar ingresar')
        })
      },
      selectCompany(company) {
        this.$store.dispatch('updateCompany', company)
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      }
    }
  }

</script>

