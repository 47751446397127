<template>
  <div class="row">
    <div class="col-md-12 row">
      <div class="col-md-7 text-left">
        <div class="category text-left rh-group-buttons"> 
          <router-link class="btn btn-info" :to="`/admin/quotes/form`">
            <i class="ti-plus"></i> cotizar
          </router-link> 
          <btn type="primary" @click="$refs.filterBy.show()">
            <i class="ti-panel"></i> Filtrar
          </btn>
          <btn type="primary" @click="$refs.sortBy.show()">
            <i class="ti-arrows-vertical"></i> Ordenar
          </btn>
        </div>
      </div>
      <div class="col-md-5">
        <div class="input-group">
                <input 
                  type="text" 
                  class="form-control border-input" 
                  placeholder="Search" 
                  @keyup="search"
                  v-model="searchModel"
                  id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <table class="table table-hover quote-table">
        <thead>
          <tr>
            <th class="hidden-xs" width="150">Fecha</th>
            <th>Titulo</th>
            <th class="hidden-xs" >Cliente</th>
            <th class="hidden-xs" >Vendedor</th>
            <th class="hidden-xs" >Estado</th>
            <th class="hidden-xs">Productos</th>
            <th class="total">Total</th>
            <th>
              <i class="ti-settings"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </div>
          <template v-else>
            <template v-for="(quote, index) in quotes">
              <tr :key="index" >
                <td class="hidden-xs">{{quote.date | formatDate}}</td>
                <td :id="index"> 
                  {{ quote.title }} 
                  <i class="visible-mobile" @click="openDetail(index)" v-bind:class="[ quote.isActive ? 'ti-angle-up' : 'ti-angle-down' ]" />
                </td>
                <td :id="index" class="hidden-xs"> {{ quote.client ? quote.client.name : 'Sin cliente' }} </td>
                <td :id="index" class="hidden-xs"> {{ quote.owner ? `${quote.owner.firstName} ${quote.owner.lastName}` : 'Sin vendedor' }} </td>
                <td :id="index" class="hidden-xs"> 
                  <span class="label" 
                    v-bind:class="{ 
                      'label-primary': quote.state === 'PROGRESS', 
                      'label-default': quote.state === 'CANCELLED', 
                      'label-success': quote.state === 'FINISHED',
                      'label-warning': quote.state === 'POSTPONED',
                      'label-danger': quote.state === 'REJECTED',
                  }">
                    {{ getState(quote.state) }} 
                  </span>
                </td>
                <td :id="index" class="hidden-xs" v-html="filterProducts(quote.fields)"></td>
                <td :id="index" >{{ totalProduct(quote.fields) | toCurrency }} </td>
                <td>
                  <a class="cursor-pointer" @click.prevent="openCxtMenu(quote, index)"><i class="ti-more-alt"></i></a>
                </td>
              </tr>
              <tr :key="`detail-${index}`" :id="`detail-${index}`" class="hide">
                <td colspan="5" class="bg-info">
                  <b>Cliente: </b> {{ quote.client ? quote.client.name : 'Sin cliente' }} <br />
                  <b>Productos: </b> <span v-html="filterProducts(quote.fields)"></span> <br />
                  <b>Vendedor: </b> {{ quote.owner ? quote.owner.username : 'Sin vendedor' }}  <br />
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      </div>

      <div v-if="totalCount > limit">
        Elementos mostrados [{{((currentPage - 1) * limit) + 1}} - {{limit * currentPage}} de {{totalCount}}]
      </div>
      <div v-else-if="totalCount < limit">
        Elemento mostrados [{{totalCount}}]
      </div>
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar esta cotizacion?
      <div class="text-center">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" @click="deleteQuote()">Eliminar</btn>
      </div>
    </modal>

    <modal v-model="modalDuplicate" title="Clonar" :header="false" size="sm">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('title')}">
              <label>Nuevo Titulo</label>
              <input
                v-model="title"
                class="form-control border-input"
                v-validate.continues="'required|duplicate'"
                name="title"
                type="text"
                placeholder="Nombre" />
              <span class="help-block text-left">{{ errors.first('title') }}</span>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer">
        <btn @click="modalDuplicate=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !isCompleted" @click="duplicateQuote() || saving">
          Guardar
        </btn>
      </div>
    </modal>

    <modal v-model="modalChangeState" title="Cambiar estado" size="sm">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('state')}">
              <label>Nuevo estado</label>
              <select name="state" class="form-control border-input" v-model="stateModel">
                <option value="PROGRESS">En Progreso</option>
                <option value="POSTPONED">Pospuesto</option>
                <option value="FINISHED">Finalizado</option>
                <option value="REJECTED">Rechazado</option>
                <option value="CANCELLED">Cancelado</option>
              </select>
              <span class="help-block text-left">{{ errors.first('state') }}</span>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer">
        <btn @click="modalChangeState=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !stateModel" @click="changeStateQuote() || saving">
          Guardar
        </btn>
      </div>
    </modal>

      <context-menu class="cursor-pointer" id="context-menu" ref="ctxMenu">
        <li @click="$router.push(`/admin/quotes/form/${quote._id}`)">
          <i class="ti-pencil-alt"></i> Editar
        </li>
        <li @click="$router.push(`/admin/quotes/preview/${quote._id}`)">
         <i class="ti-image"></i> Vista previa
        </li>
        <li :disabled="exporting" @click="exportToPdf(quote._id, quote.client.name)">
          <i class="ti-export"></i> Exportar
        </li>
        <li class="disabled" @click="openDuplicateModal()">
          <i class="ti-files"></i> Duplicar
        </li>
        <li @click="modalChangeState = true">
          <i class="ti-control-shuffle"></i> Cambiar Estado
        </li>
        <li class="text-default" @click="modalChangeState = true">
          <i class="ti-time"></i> Recordatorios
        </li>
        <li class="text-danger" @click="openDeleteModel(index)">
          <i class="ti-trash"></i> Eliminar
        </li>
      </context-menu>

    <SortBy 
      ref="sortBy" 
      :modalProp="false" 
      :byValue="byModel" 
      :byCriteo="criteoValueDefault" 
      :by="sortByParams" 
      @update="sortByEvent"/>

    <FilterBy 
      ref="filterBy" 
      :modalProp="false" 
      :criteo="criteoFilterBy" 
      :options="filterByParams" 
      :get-options="getOptions"
      :criteo-default="criteoDefault"
      :option-default="filterDefault"
      @output="onOutputFilter" />
      
  </div>
</template>
<style>
  .add-field {
    margin-top: 20px;
  }
</style>

<script>
import constants from '../constants'
import SortBy from '../components/Filters/SortBy'
import FilterBy from '../components/Filters/FilterBy'

import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'
import contextMenu from 'vue-context-menu'
import { Validator } from 'vee-validate'
import Vue from 'vue'
import { isEmpty } from 'lodash'

let titleExt = ''

Validator.extend('duplicate', {
  getMessage: field => `The ${field} value cannot be equal than the original`,
  validate: value => titleExt !== value
})

const sortByParams = [
  {
    id: 'client.name',
    name: 'Clientes'
  },
  {
    id: 'title',
    name: 'Titulo'
  },
  {
    id: 'fields.product.name',
    name: 'Producto'
  },
  {
    id: 'date',
    name: 'Fecha'
  }
]

const byModel = {
  id: 'date',
  name: 'Fecha'
}

const criteoValueDefault = {
  id: 'desc',
  name: 'Descendente'
}

const filterByParams = [
  {
    key: 'client.name',
    label: 'Clientes',
    meta: 'clients',
    type: 'DROPDOWN'
  },
  {
    key: 'fields.product.name',
    label: 'Productos',
    meta: 'products',
    type: 'DROPDOWN'
  },
  {
    key: 'title',
    label: 'Titulo',
    meta: 'title',
    type: 'TEXT'
  }
]

const criteoFilterBy = [
  {
    key: 'equal',
    label: 'Igual a'
  },
  {
    key: 'contains',
    label: 'Contener valor'
  }
]

export default {
  components: {
    Modal,
    Pagination,
    Btn,
    contextMenu,
    SortBy,
    FilterBy
  },
  created () {
    this.fetchQuotes()
  },
  data () {
    return {
      loading: false,
      exporting: false,
      index: -1,
      modalQuote: false,
      modalRemove: false,
      modalDuplicate: false,
      modalChangeState: false,
      fieldType: 'DIVIDER',
      quotes: [],
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: '',
      quote: {},
      title: '',
      saving: false,
      sortByParams: [...sortByParams],
      byModel,
      criteoValueDefault,
      criteoFilterBy,
      filterByParams,
      sortBy: null,
      criteoDefault: criteoFilterBy[0],
      filterDefault: filterByParams[0],
      filters: {},
      stateModel: null
    }
  },
  computed: {
    isCompleted () {
      return this.title
    },
    defaultCompany() {
      return this.$store.state.company;
    },
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchQuotes()
      }
    }
  },
  methods: {
    search () {
      this.fetchQuotes()
    },
    fetchQuotes () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }

      if (this.searchModel) {
        params.q = this.searchModel
      }

      if (!isEmpty(this.filters)) {
        params.filters = JSON.stringify(this.filters)
      }

      if (this.sortBy) {
        params.sort = JSON.stringify(this.sortBy)
      }

      axios
        .get(`${constants.URL}/quotes`, { params })
        .then(response => {
          this.totalCount = response.data.count
          this.totalPage = Math.ceil(this.totalCount / this.limit)
          this.quotes = response.data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    async changePage () {
      await this.fetchQuotes()
    },
    filterProducts (fields = []) {
      let productoList = fields
        .filter(val => val.quoteType === 'PRODUCT')
        .map(val => {
          if (val.product) {
            let price = val.priceCheck === 'custom' ? val.price : val.product.price
            return `${val.product.name}, precio c/u: ${price}, cantidad: ${val.quantity}`
          }
        })
      return productoList.join('<br />')
    },
    totalProduct (fields) {
      const sumPrice = fields
        .filter(item => item.quoteType === 'PRODUCT')
        .map(item => {
          if (item.product) {
            // const price = val.priceCheck === 'custom' ? val.price : val.product.price
            return parseInt(item.price) * item.quantity
          }
        })
      const total = sumPrice.length > 0 ? sumPrice.reduce((a, b) => a + b) : 0
      return total || 0
    },
    openDeleteModel (index) {
      this.modalRemove = true
      this.index = index
    },
    changeStateQuote () {
      this.saving = true
      axios
        .put(`${constants.URL}/quotes/${this.quote._id}`, { state: this.stateModel })
        .then(() => {
          this.modalChangeState = false
          this.fetchQuotes()
          this.saving = false
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    deleteQuote () {
      const quote = this.quotes[this.index]
      axios
        .delete(`${constants.URL}/quotes/${quote._id}`)
        .then(() => {
          this.quotes.splice(this.index, 1)
          this.modalRemove = false
        }).catch(error => {
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    exportToPdf (id, name) {
      this.exporting = true
      const params = {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      }
      axios.get(`${constants.URL}/quotes/export/${this.defaultCompany}/${id}`, params)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}.pdf`) // or any other extension
          document.body.appendChild(link)
          link.click()
          this.exporting = false
        })
        .catch(error => {
          this.exporting = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    openCxtMenu (quote, index) {
      this.quote = quote
      this.index = index
      this.$refs.ctxMenu.open()
    },
    openDuplicateModalWithQuote (quote) {
      this.quote = quote
      this.openDuplicateModal()
    },
    openDuplicateModal () {
      titleExt = this.quote.title
      this.title = `${this.quote.title} copy`
      this.modalDuplicate = true
    },
    duplicateQuote () {
      axios
        .post(`${constants.URL}/quotes/duplicate/${this.quote._id}`, { title: this.title })
        .then(result => {
          this.modalDuplicate = false
          this.$router.push(`/admin/quotes/preview/${result.data._id}`)
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    openDetail (index) {
      let el = document.querySelector(`#detail-${index}`)
      if (!el) {
        return
      }
      const status = el.classList.contains('hide')
      const quote = this.quotes[index]
      quote.isActive = status
      Vue.set(this.quotes, index, quote)
      if (status) {
        el.classList.remove('hide')
      } else {
        el.classList.add('hide')
      }
    },
    sortByEvent (params) {
      this.sortBy = {}
      this.sortBy[params.by] = params.criteo
      this.fetchQuotes()
    },
    onOutputFilter (params) {
      if (!params.filter) {
        this.filters = {}
        this.fetchQuotes()
        return
      }
      const { criteo, filterBy, filter } = params
      this.filters = {
        param: filterBy.key,
        condition: criteo.key,
        value: filterBy.type === 'DROPDOWN' ? filter.name : filter
      }
      this.fetchQuotes()
    },
    async getOptions (params) {
      const { meta, query, done } = params
      try {
        const params = {
          skip: 0,
          take: 10,
          q: query
        }
        const result = await axios.get(`${constants.URL}/${meta}`, { params })
        done(result.data.result)
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    getState (state) {
      switch (state) {
        case 'PROGRESS':
          return 'En procceso'
        case 'POSTPONED':
          return 'Pospuesto'
        case 'FINISHED':
          return 'Finalizado'
        case 'REJECTED':
          return 'Rechazado'
        case 'CANCELLED':
          return 'Cancelado'
        default:
          return 'Sin estado'
      }
    }
  }
}
</script>
