import Sidebar from './SideBar.vue'
//const view = JSON.parse(localStorage.getItem('view'));
const defaultSidebarLinks = [
  {
    name: 'Clientes',
    icon: 'ti-view-list-alt',
    path: '/admin/clients'
  },
  {
    name: 'Productos',
    icon: 'ti-map',
    path: '/admin/products'
  },
  {
    name: 'Plantillas',
    icon: 'ti-panel',
    path: '/admin/templates'
  },
  {
    name: 'Cotizaciones',
    icon: 'ti-wallet',
    path: '/admin/quotes'
  },
  {
    name: 'Mensajeria',
    icon: 'ti-email',
    //path: '/admin/shipping',
    children: [
      {
        name: 'Envios',
        icon: 'ti-image',
        path: '/admin/shipping/list',
      },
      {
        name: 'Reportes',
        icon: 'ti-image',
        path: '/admin/shipping/reports',
      },
      {
        name: 'Mensajeros',
        icon: 'ti-image',
        path: '/admin/shipping/couriers',
      }
    ]
  },
  // {
  //   name: 'Garantias',
  //   icon: 'ti-agenda',
  //   path: '/admin/warranty'
  // },
  {
    name: 'Imagenes',
    icon: 'ti-image',
    path: '/admin/media',
    // children: [
    //   {
    //     name: 'Imagenes',
    // icon: 'ti-image',
    // path: '/admin/media',
    //   }
    // ]
  },
  // {
  //   name: 'Recordatorios',
  //   icon: 'ti-agenda',
  //   path: '/admin/reminders'
  // }
];

// const SidebarStore = {
//   showSidebar: false,
//   sidebarLinks: view?.sidebar || defaultSidebarLinks,
//   displaySidebar (value) {
//     this.showSidebar = value
//   }
// }

function getLinks() {
  const view = JSON.parse(localStorage.getItem('view'));
  let links;
  if (view?.sidebar) {
    links = view.sidebar;
  } else {
    links = defaultSidebarLinks;
  }
  return links;
}

const SidebarPlugin = {

  install(Vue) {
    Vue.mixin({
      data() {
        return {
          sidebarStore: {
            showSidebar: false,
            sidebarLinks: getLinks(),
            displaySidebar(value) {

              this.sidebarLinks = getLinks();
              this.showSidebar = value
            }
          }
        }
      },
    })

    Object.defineProperty(Vue.prototype, '$sidebar', {
      get() {
        return this.$root.sidebarStore
      }
    })
    Vue.component('side-bar', Sidebar)
  }
}

export default SidebarPlugin
