<template>
    <div>
        <textarea v-bind="$props" :value="convertToPlain(value)" @input="onChange" :class="cssClass"/>
    </div>
</template>
<script>
const { convert } = require('html-to-text');
export default {
    props: {
        label: String,
        name: String,
        disabled: Boolean,
        placeholder: String,
        rows: String,
        value: [String, Number, Boolean],
        hasError: Boolean,
        cssClass: String,
    },
    data() {
        return {
            isFormatted: false
        };
    },
    created() {},
    mounted() {},
    computed: {},
    methods: {
        onChange(e) {
            this.isFormatted = true
            this.$emit('input', e.target.value)
        },
        reset() {
            this.isFormatted = false;
        },
        convertToPlain(html) {
            if (this.isFormatted) {
                return html;
            }
            const options = {
                wordwrap: 130,
                // ...
            };
            return convert(html, options)
        }
    },
};
</script>