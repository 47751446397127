<template>
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <div class="card card-user">
        <div class="content">
          <div class="author">
            <img class="avatar border-white" src="https://s.gravatar.com/avatar/b40e6c37a196c3c6154cd999899973de?size=496&default=retro" alt="...">
            <h4 class="title">
              {{user.firstName}} {{user.lastName}}
              <br>
              <a href="#">
                <small>{{user.username}}</small>
              </a>
            </h4>
          </div>
          <p class="description text-center">
            <!-- <span>Roles:</span> <br />
            <span class="text-muted" :key="role" v-for="role in user.roles">{{role}}</span><br />
            <span>Es super usuario: </span><br />
            <span class="text-muted">{{user.isRoot}}</span> -->
            <btn block size="lg" type="info" @click="modalToggle = !modalToggle">
              Editar perfil
            </btn>
            <!-- <router-link class="btn btn-block btn-info btn-lg" :to="`/user/create/${this.user._id}`">
                  Editar Perfil
            </router-link> -->
            <btn block size="lg" type="primary" @click="$refs.modalPassword.show()">Cambiar contraseña</btn>
            <!-- <btn block size="lg" @click="registeFCM">Activar notificaciones</btn> -->
          </p>
        </div>
        <hr>
        <div class="text-center hide">
          <div class="row">
            <div :key="index" v-for="(info,index) in details" :class="getClasses(index)">
              <h5>{{info.title}}
                <br>
                <small>{{info.subTitle}}</small>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <change-password :modal-prop="false" ref="modalPassword" :oldPassword="true" @save="savePassword" />

    <modal v-model="modalToggle" title="Editar Perfil" size="lg">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Email</label>
              <input
                disabled="true"
                v-model="user.username"
                class="form-control border-input"
                v-validate="'required'"
                name="username"
                type="text"
                placeholder="Nombre"/>
            </div>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                <label>Nombre</label>
                <input
                  v-model="user.firstName"
                  class="form-control border-input"
                  v-validate="'required'"
                  name="username"
                  type="text"
                  placeholder="Nombre"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Apellido</label>
                <input
                  v-model="user.lastName"
                  class="form-control border-input"
                  v-validate="'required'"
                  name="username"
                  type="text"
                  placeholder="Nombre"/>
              </div>
            </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="modalToggle=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !isCompleted" @click="updateUser() | saving">
          Guardar
        </btn>
      </div>
    </modal>
  </div>
</template>
<style lang="scss" scoped>
  .card-user {
    padding-top: 90px;
  }
</style>
<script>
  import axios from 'axios'
  import constants from '../../constants'
  import ChangePassword from '../../components/Forms/ChangePassword.vue'
  import { Modal, Btn } from 'uiv'
  export default {
    components: {
      Modal,
      Btn,
      ChangePassword
    },
    created () {
      this.getProfile()
    },
    computed: {
      isCompleted () {
        return this.user.firstName && this.user.lastName
      }
    },
    mounted () {
      // this.initFCM()
    },
    data () {
      return {
        user: {},
        loading: false,
        modalToggle: false,
        details: [
          {
            title: '12',
            subTitle: 'Files'
          },
          {
            title: '2GB',
            subTitle: 'Used'
          },
          {
            title: '24,6$',
            subTitle: 'Spent'
          }
        ]
      }
    },
    methods: {
      getProfile () {
        axios
        .get(`${constants.URL}/users/me`)
        .then(response => {
          this.user = response.data
        })
        .catch(error => {
          this.loading = false
          this.$notifications.notifyVue(`Error: ${error}`, '')
        })
      },
      getClasses (index) {
        var remainder = index % 3
        if (remainder === 0) {
          return 'col-md-3 col-md-offset-1'
        } else if (remainder === 2) {
          return 'col-md-4'
        } else {
          return 'col-md-3'
        }
      },
      initFCM () {
        console.log('initFCM')
        this.$messaging.onTokenRefresh(() => {
          console.log('tokenRefresh')
          this.$messaging.getToken().then(() => {
            console.log('Token refreshed.')
            // this.setTokenSentToServer(false)
            // this.sendTokenToServer(refreshedToken)
          }).catch(error => console.log(error))
        })
      },
      registeFCM () {
        this.$messaging.requestPermission().then(() => {
          console.log('Notification permission granted.')
          this.getToken()
        }).catch((err) => {
          console.log('Unable to get permission to notify.', err)
        })
      },
      getToken () {
        this.$messaging.getToken().then((currentToken) => {
          console.log(currentToken)
          // if (currentToken) {
          //   this.sendTokenToServer(currentToken)
          // } else {
          //   console.log('No Instance ID token available. Request permission to generate one.')
          //   // Show permission UI.
          //   this.setTokenSentToServer(false)
          // }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })
      },
      async savePassword (passwordModel) {
        try {
          const body = {
            oldPassword: passwordModel.oldPassword,
            newPassword: passwordModel.password
          }
          await axios.put(`${constants.URL}/users/changePassword`, body)
          this.$notifications.notifyVue(`Contraseña cambiada exitosamente`, 'info')
        } catch (error) {
          this.$notifications.notifyVue(`Error: ${error.message}`, 'danger')
        }
      },
      updateUser () {
        this.modalToggle = false
        const user = {
          lastName: this.user.lastName,
          firstName: this.user.firstName
        }
        this.saving = true
        axios
          .put(`${constants.URL}/users/change/${this.user._id}`, user)
          .then(() => {
            this.saving = false
            this.$notifications.notifyVue(`Cambio realizado exitosamente`, 'info')
          })
          .catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Error: ${error}`, 'danger')
          })
      }
    }
  }

</script>

