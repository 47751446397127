<template>
  <modal v-model="modalToggle" title="Cambiar contraseña" size="md">
      <form>
        <div class="row" v-if="oldPassword">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('oldPassword')}">
              <label>Contraseña actual</label>
              <input
                v-model="passModel.oldPassword"
                class="form-control border-input"
                name="oldPassword"
                v-validate="'required'"
                type="password" />
                <span class="help-block text-left">{{ errors.first('oldPassword') }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('password')}">
              <label>Nueva Contraseña</label>
              <input
                v-model="passModel.password"
                class="form-control border-input"
                v-validate="'min:6|required'"
                name="password"
                type="password"
                ref="password" />
              <span class="help-block text-left">{{ errors.first('password') }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('confirmPassword')}">
              <label>Confirmar contraseña</label>
              <input
                v-model="passModel.confirmPassword"
                class="form-control border-input"
                name="confirmPassword"
                v-validate="'required|confirmed:password'"
                type="password" />
                <span class="help-block text-left">{{ errors.first('confirmPassword') }}</span>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="modalToggle=false">Cancel</btn>
        <btn type="primary" 
          :disabled="errors.any() || !isCompleted || saving" @click="save">Guardar</btn>
      </div>
    </modal>
</template>
<script>
  import { Modal, Btn } from 'uiv'
  export default{
    components: {
      Modal,
      Btn
    },
    props: {
      oldPassword: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isCompleted () {
        const state = this.passModel.password && this.passModel.confirmPassword
        return this.oldPassword ? this.passModel.oldPassword && state : state
      }
    },
    data () {
      return {
        passModel: {
          oldPassword: '',
          password: '',
          confirmPassword: ''
        },
        modalToggle: this.modalClient,
        saving: false
      }
    },
    methods: {
      show () {
        this.$validator.reset()
        this.modalToggle = true
      },
      save () {
        this.$emit('save', this.passModel)
        this.modalToggle = false
      }
    }
  }
</script>
