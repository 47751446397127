<template>
  <div style="background: #f4f3ef; height: 100%;">
    <notifications />
    <div class="container-fluid">
      <div class="row" id="pwd-container">
        <div class="col-md-6 col-lg-4 col-md-offset-3 col-lg-offset-4 form">
          <div class="card login-form-card">
            <div class="content">
              <section class="login-form">
                <form>
                  <img :src="image" class="img-responsive" alt>
                  <h3 class="text-center">Iniciar session</h3>
                  <div class="form-group" :class="{ 'has-error': errors.first('email') }">
                    <label for="email">Email</label>
                    <input 
                      v-model="credentials.username" 
                      class="form-control border-input"
                      v-validate="'required|email'" 
                      name="email" 
                      type="text" 
                      autocapitalize="off" 
                      autocorrect="off"
                      placeholder="" />
                    <span class="help-block text-left">{{ errors.first('email') }}</span>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.first('password') }">
                    <label for="exampleInputEmail1">Contraseña</label>
                    <input 
                      v-model="credentials.password" 
                      class="form-control border-input" 
                      v-validate="'required'"
                      name="password" 
                      type="password" 
                      autocapitalize="off" 
                      autocorrect="off" 
                      v-on:keyup.enter="submit()"
                      placeholder="" />
                    <span class="help-block text-left">{{ errors.first('password') }}</span>
                  </div>

                  <div class="form-actions-links">
                    <router-link :to="`/recovery-password`">
                      Recuperar contraseña
                    </router-link>
                  </div>

                  <div class="pwstrength_viewport_progress"></div>

                  <button type="button" @click="submit()" name="go" :disabled="errors.any() || !isCompleted"
                    class="btn btn-lg btn-success btn-block">Ingresar</button>
                  <div>
                    Aun no tienes cuenta?
                    <router-link :to="`/signup`">
                      Registrarme
                    </router-link>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="copyright">
      <div class="pull-right">
        &copy; 2023, made with
        <i class="fa fa-heart heart"></i> by Reach lab
      </div>
    </footer>
  </div>
</template>
<style media="screen">
.progress-bar {
  color: #333;
}

.form {
  height: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      image: 'https://quote.reachportones.com/static/img/logo.png'
    }
  },
  components: {},
  computed: {
    isCompleted() {
      return this.credentials.username && this.credentials.password
    }
  },
  methods: {
    notifyVue(message) {
      this.$notifications.notify({
        message,
        // icon: 'ti-gift',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger'
      })
    },
    async submit() {
      this.loggingIn = true
      const credentials = {
        email: this.credentials.username,
        password: this.credentials.password
      }
      try {
        const resp = await this.$store.dispatch('login', credentials);
        const path = resp?.view?.initPath ?? '/';
        this.$router.push(path)
      } catch (error) {
        this.notifyVue('Error al intentar ingresar')
      }
    }
  }
}
</script>
