<template>
  <div class="row">
    <div class="col-md-12">
      <p class="text-right">
        <btn @click="openAddImageModa">
          <i class="ti-plus"></i> Agregar imagen
        </btn>
      </p>
    </div>
    <div class="col-md-12 clearfix">
      <media-view 
        v-for="(media, index) in mediaFiles"
        v-model="mediaFiles[index]"
        @input="updateMedia($event)"
        @delete="deleteMedia($event, index)"
        :key="index"
      />
    </div>
    <div class="col-md-12 clearfix">
      <page-conter :total="totalCount" :limit="limit" :current-page="currentPage" />
      <pagination
        class="text-left"
        v-if="totalCount > limit"
        v-model="currentPage"
        :total-page="totalPage"
        @change="changePage"
      />
    </div>
  <modal v-model="modalAddImage" title="Agregar imagen" size="lg">
      <form>
        <div class="row media-modal">
          <div class="col-md-7">
              <div v-if="!image">
                <label>Seleccionar imagen</label>
                <input type="file" name="file" accept="image/*" @change="onFileChange">
              </div>
              <div class="product-image text-center" v-else>
                <img width="100%" :src="image" />
                <a class="cursor-pointer" @click="removeImage">
                  <i class="ti-close"></i>
                </a>
              </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.first('name')}">
                  <label>Nombre</label>
                  <input
                    autocomplete="off"
                    v-model="media.name"
                    v-validate="'required'"
                    class="form-control border-input"
                    name="name"
                    type="text"
                    placeholder="Nombre"/>
                  <span class="help-block text-left">{{ errors.first('name') }}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group" :class="{'has-error': errors.first('alt')}">
                  <label>Alt</label>
                  <input
                    autocomplete="off"
                    v-validate="'required'"
                    v-model="media.alt"
                    class="form-control border-input"
                    name="alt"
                    type="text"
                    placeholder="Alt"/>
                  <span class="help-block text-left">{{ errors.first('alt') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="modalAddImage=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !isCompleted || saving" @click="addImage()">
          Guardar
        </btn>
      </div>
    </modal>

  </div>
</template>
<style lang="scss">
  .product-image {
    width: 100% !important;
  }
</style>

<script>
import PageConter from '../components/PageCounter.vue'
import constants from '../constants'
import MediaView from '../components/Media/MediaView.vue'
import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'

const mediaSchema = {
  path: '',
  name: '',
  alt: ''
}

export default {
  components: {
    Modal,
    MediaView,
    Pagination,
    Btn,
    PageConter
  },
  created () {
    this.fetchMedia()
  },
  data () {
    return {
      saving: false,
      modalAddImage: false,
      media: { ...mediaSchema },
      loading: true,
      mediaFiles: [],
      image: '',
      fieldName: '',
      imageFile: '',
      limit: 12,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted () {
      return this.media.name && this.media.alt
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchMedia()
      }
    }
  },
  methods: {
    fetchMedia () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }
      axios
        .get(`${constants.URL}/media`, { params })
        .then(response => {
          this.totalCount = response.data.count
          this.totalPage = Math.ceil(this.totalCount / this.limit)
          this.mediaFiles = [...response.data.result]
          this.loading = false
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, '')
          this.loading = false
        })
    },
    async deleteMedia (e, index) {
      axios
        .delete(`${constants.URL}/media/${e}?force=true`)
        .then(() => {
          this.mediaFiles.splice(index, 1)
        })
        .catch(error => this.$notifications.notifyVue(`Error: ${error}`, ''))
    },
    async updateMedia (e) {
      try {
        this.saving = true
        const mediaReq = {
          path: e.path,
          name: e.name,
          alt: e.alt
        }
        const id = e._id
        await axios.put(`${constants.URL}/media/${id}`, mediaReq)
        this.saving = false
        this.fetchMedia()
        this.$notifications.notifyVue(`El producto se actualizo correctamente`, 'success')
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Ocurrio un error ${error}`, 'danger')
      }
    },
    openAddImageModa () {
      this.media = {...mediaSchema}
      this.$validator.reset()
      this.removeImage()
      this.modalAddImage = true
    },
    async changePage () {
      await this.fetchMedia()
    },
    async addImage () {
      try {
        this.saving = true
        const mediaReq = { ...this.media }
        if (this.imageFile) {
          const formData = new FormData()
          formData.append(this.fieldName, this.imageFile)
          const imageRes = await axios.post(`${constants.URL}/media/upload`, formData)
          mediaReq.path = imageRes.data.imageFullSize.url
          mediaReq.meta = imageRes.data
        }
        await axios.post(`${constants.URL}/media`, mediaReq)
        this.$notifications.notifyVue(`La imagen se guardo correctamente`, 'success')
        this.modalAddImage = false
        this.saving = false
        this.fetchMedia()
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Error: ${error.message}`, 'danger')
      }
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.fieldName = e.target.name
      this.imageFile = files[0]
      this.createImage(files[0])
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage() {
      this.image = ''
    }
  }
}
</script>
