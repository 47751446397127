<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-md-7 text-left">
        <p class="category text-left"> 
          <btn type="primary" @click="openAdd()">
            <i class="ti-plus"></i> Agregar Mensajero
          </btn>
        </p>
      </div>
      <div class="col-md-5">
        <div class="input-group">
                <input 
                  type="text" 
                  class="form-control border-input" 
                  placeholder="Search" 
                  @keyup="search"
                  v-model="searchModel"
                  id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card car-overflow">
        <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </div>
          <template v-else>
            <tr v-for="(courier, index) in couriers" :key="index">
              <td :id="index"> {{ index + 1 }} </td>
              <td :id="index"> {{ courier.user.firstName + ' ' + courier.user.lastName }} </td>
              <td>
                <a class="cursor-pointer text-danger" @click="openDelete(index)">
                  <i class="ti-trash"></i> 
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      </div>

      <page-conter :total="totalCount" :limit="limit" :current-page="currentPage" />
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Deseas remover a este mensajero?
      <div class="text-center rh-group-buttons">
        <br />
        <btn type="danger" @click="deleteCourier()">Eliminar</btn>
        <btn @click="modalRemove=false">Cancel</btn>
      </div>
    </modal>

    <modal v-model="modalAdd" title="Agregar mensajero" size="sm">
    <form>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" :class="{ 'has-error': errors.first('name') }">
            <label>Nombre</label>
            <input autocomplete="off" id="user-input" class="form-control border-input" type="text"
                  placeholder="Buscar usuario..." />
                <typeahead v-model="user" target="#user-input" :async-function="getUsers" force-select
                  @input="userSelected" item-key="name" />
            <span class="help-block text-left">{{ errors.first('name') }}</span>
          </div>
        </div>
      </div>
    </form>
    <div slot="footer">
      <btn type="primary" :disabled="!isCompleted" @click="addCourier() || saving">
        Guardar
      </btn>
      <btn @click="modalAdd = false">Cancel</btn>
    </div>
  </modal>
    <!-- <product-form v-model="product" :modal-prop="false" ref="modalProduct" @save="onSaveProduct" /> -->
  </div>
</template>
<style lang="scss" scoped>
    .product-desc{
      ::v-deep p {
          font-size: 14px;
          padding: 0px;
          margin: 0px;
        }
    }
</style>
<script>
import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'
import PageConter from '../../components/PageCounter.vue'
//import ProductForm from '../../components/Forms/ProductForm.vue'

import constants from '../../constants'


export default {
  components: {
    Modal,
    Pagination,
    Btn,
    PageConter
  },
  created () {
    this.fetchCouriers()
  },
  data () {
    return {
      loading: false,
      saving: false,
      couriers: [],
      modalAdd: false,
      modalRemove: false,
      id: '',
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: '',

      user: {
        name: ''
      }
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted () {
      return !this.user || this.user.name !== ''
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchCouriers()
      }
    }
  },
  methods: {
    search () {
      this.fetchCouriers()
    },
    async fetchCouriers () {
      try {
        this.loading = true
        const params = {
          skip: (this.currentPage - 1) * this.limit,
          take: this.limit
        }

        if (this.searchModel) {
          params.q = this.searchModel
        }
        const result = await axios.get(`${constants.URL}/shipping/couriers`, { params })
        this.couriers = result.data.result
        this.totalCount = result.data.count
        this.totalPage = Math.ceil(this.totalCount / this.limit)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
      }
    },
    async changePage () {
      //await this.fetchProducts()
    },
    openAdd () {
      this.modalAdd = true;
    },
    async addCourier () {
      try {
        this.saving = true
        const courierReq = { 
          user: this.user._id
        }
        await axios.post(`${constants.URL}/shipping/courier`, courierReq)
        this.$notifications.notifyVue(`El producto se guardo correctamente`, 'success')
        this.saving = false
        this.modalAdd = false
        this.fetchCouriers()
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        this.modalAdd = false
      }
    },
    openDelete (index) {
      this.modalRemove = true
      this.id = index
    },
    async deleteCourier () {
      this.modalRemove = false
      try {
        await axios.delete(`${constants.URL}/shipping/courier/${this.couriers[this.id]._id}`)
        this.couriers.splice(this.id, 1)
        this.$notifications.notifyVue('Se elimino correctamente', 'success')
      } catch (error) {
        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        this.modalRemove = false
      }
    },

    userSelected (user) {
      this.user = user
    },  
    getUsers (query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/company/users/findUsers`, { params })
        .then(response => {
          const users = response.data.result
          const usersWithoutDuplicates = users.filter(item => {
            return !this.couriers.some(courier => courier.user._id === item.user._id);
          });
          const data = usersWithoutDuplicates.map(item => {
            const user = item.user;
            return {
              ...user,
              name: user.firstName + ' ' + user.lastName
            }
          })
          done(data)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    }
  }
}
</script>
