<template>
    <div>
        <div v-if="!value">
            <label>Seleccionar imagen</label>
            <br />
            <input class="file" id="upfile" type="file" name="file" @change="onFileChange">
            <btn class="cancel-space" type="primary" @click="openUploadFile">Subir archivo</btn>
            <a class="cursor-pointer" @click.prevent="selectMedia">Seleccionar desde medios</a>
        </div>
        <div class="product-image text-center" v-else>
            <img width="50%" :src="value" />
            <a class="cursor-pointer" @click="removeImage">
                <i class="ti-close"></i>
            </a>
        </div>
    </div>
</template>
<style lang="scss">
.file {
    display: none !important;
}
</style>
<script>
import { Btn } from 'uiv'
export default {
    components: { Btn },
    created() { },
    computed: {},
    props: {
        value: String
    },
    data() {
        return {
            fieldName: '',
            image: '',
            imageFile: '',
            showImageModal: false
        }
    },
    methods: {
        removeImage() {
            this.$emit('input','')
        },
        createImage (file) {
            const reader = new FileReader()

            reader.onload = (e) => {
                this.$emit('input',e.target.result)
            }
            reader.readAsDataURL(file)
        },
        selectMedia() {
            this.$emit('showMedia', true)
        },
        openUploadFile() {
            document.getElementById('upfile').click();
        },
        onFileChange(e) {
            const files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return
            }
            this.fieldName = e.target.name
            this.imageFile = files[0]
            this.createImage(files[0])
            this.$emit('change', { name: e.target.name, file: files[0] })
        }
    }
}
</script>