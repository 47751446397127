// Layouts
import DashboardLayout from '../layouts/dashboard/DashboardLayout'

// Pages
import NotFound from '../pages/NotFoundPage.vue'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import RecoveryPassword from '../pages/RecoveryPassword'
import InviteTransition from '../pages/InviteTransition'
import ResetPassword from '../pages/ResetPassword'
import UserCard from '../pages/UserProfile/UserCard'
import ReminderCard from '../pages/ReminderCard'
import WorkspaceList from '../pages/WorkspaceList.vue'
import WorkspaceDescription from '../pages/WorkspaceDescription.vue'
import RoleForm from '../pages/RoleForm.vue';

// Admin pages
import Overview from '../pages/Overview'
import UserProfile from '../pages/UserProfile.vue'
import CreateUser from '../pages/CreateUser.vue'
import Notifications from '../pages/Notifications.vue'
import Icons from '../pages/Icons.vue'
import Maps from '../pages/Maps.vue'
import Typography from '../pages/Typography.vue'
import Clients from '../pages/Clients.vue'
import Products from '../pages/Products.vue'
import TemplateList from '../pages/TemplateList.vue'
import TemplateForm from '../pages/TemplateForm.vue'
import QuoteList from '../pages/QuoteList.vue'
import ReminderList from '../pages/ReminderList.vue'
import QuoteForm from '../pages/QuoteForm.vue'
import QuotePreview from '../pages/QuotePreview.vue'
import Media from '../pages/Media.vue'
import ShippingList from '@/pages/shipping/ShippingList.vue'
import ShippingForm from '@/pages/shipping/ShippingForm.vue'
import Courier from '@/pages/shipping/Courier.vue'
import ShippingReport from '@/pages/shipping/ShippingReport.vue'

const view = JSON.parse(localStorage.getItem('view'));
const initPath = view?.initPath ?? '/admin/quotes';
const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: initPath
  },
  {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/list',
    children: [
      {
        path: 'list',
        name: 'Usuarios',
        component: UserProfile
      },
      {
        path: 'create/:id?',
        name: 'Usuario',
        props: { isNew: false },
        component: CreateUser
      },
      {
        path: 'profile',
        name: 'Usuario',
        component: UserCard
      }
    ]
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: 'admin/overview',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: Overview,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications
      },
      {
        path: 'icons',
        name: 'icons',
        component: Icons
      },
      {
        path: 'maps',
        name: 'maps',
        component: Maps
      },
      {
        path: 'typography',
        name: 'Configuraciones',
        component: Typography
      },
      {
        path: 'clients',
        name: 'Listado de clientes',
        component: Clients
      },
      {
        path: 'products',
        name: 'Listado de productos',
        component: Products
      },
      {
        path: 'quotes',
        name: 'Listado de cotizaciones',
        component: QuoteList
      },
      {
        path: 'quotes/form/:id?',
        name: 'Formulario de cotizacion',
        component: QuoteForm
      },
      {
        path: 'quotes/preview/:id?',
        name: 'Vista previa',
        component: QuotePreview
      },
      {
        path: 'templates',
        name: 'Listado de plantillas',
        component: TemplateList
      },
      {
        path: 'templates/form/:id?',
        name: 'Formulario',
        component: TemplateForm
      },
      {
        path: 'media',
        name: 'Media',
        component: Media
      },
      {
        path: 'reminders',
        name: 'Listado de recordatorios',
        component: ReminderList
      },
      {
        path: 'reminders/detail/:id?',
        name: 'Recordatorio',
        component: ReminderCard
      },
      {
        path: 'workspaces',
        name: 'Empresas',
        component: WorkspaceList
      },
      {
        path: 'workspaces/:id',
        name: 'Descripcion',
        component: WorkspaceDescription
      },
      {
        path: 'role/form/:company/:id?',
        name: 'Role',
        component: RoleForm
      },
      {
        path: 'shipping/list',
        name: 'Envios',
        component: ShippingList
      },
      {
        path: 'shipping/form/:id?',
        name: 'Crear envio',
        component: ShippingForm
      },
      {
        path: 'shipping/couriers',
        name: 'Mensajeros',
        component: Courier
      },
      {
        path: 'shipping/reports',
        name: 'Reportes',
        component: ShippingReport
      }
      // {
      //   path: 'warranty/form/:id?',
      //   name: 'Garantia',
      //   component: WarrantyForm
      // }
    ]
  },
  { path: '*', component: NotFound },
  { path: '/login', component: Login },
  { path: '/signup', component: Signup },
  { path: '/recovery-password', component: RecoveryPassword },
  { path: '/invite/:token', component: InviteTransition },
  { path: '/reset/:id', component: ResetPassword }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../pages/' + name + '.vue');
   return res;
};**/

export default routes
