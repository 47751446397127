<template>
    <div class="row">
      <div class="col-lg-offset-2 col-lg-8 col-md-7">
        <div class="card">
          <edit-profile-form>

          </edit-profile-form>
        </div>
      </div>
    </div>
</template>
<script>
  
  export default {
    components: {},
    data () {
      return {}
    }
  }

</script>
<style>

</style>
