<template>
  <div style="background: #f4f3ef; height: 100%;">
    <notifications />
    <div class="container-fluid">
      <div class="row" id="pwd-container">
        <div class="col-md-6 col-lg-4 col-md-offset-3 col-lg-offset-4 form">
          <div class="card login-form-card">
            <div class="content">
              <section class="login-form">
                <form>
                  <img :src="image" class="img-responsive" alt>
                  <h3 class="text-center">Recuperar contraseña</h3>

                  <div class="form-group" :class="{ 'has-error': errors.first('email') }">
                    <label for="email">Email</label>
                    <input 
                      v-model="credentials.email" 
                      class="form-control border-input"
                      v-validate="'required|email'" 
                      name="email" 
                      type="text" 
                      autocapitalize="off" 
                      autocorrect="off"
                      placeholder="" />
                    <span class="help-block text-left">{{ errors.first('email') }}</span>
                  </div>

                  <button type="button" @click="submit()" name="go" :disabled="errors.any() || !isCompleted || isLoading"
                    class="btn btn-lg btn-success btn-block">Enviar</button>
                  <div>
                    Ya recorde mi contraseña, quiero
                    <router-link :to="`/login`">
                      Ingresar
                    </router-link>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="copyright">
      <div class="pull-right">
        &copy; 2023, made with
        <i class="fa fa-heart heart"></i> by Reach lab
      </div>
    </footer>
  </div>
</template>
<style media="screen">
.progress-bar {
  color: #333;
}

.form {
  height: 100%;
}
</style>

<script>
import constants from '@/constants'
import axios from 'axios'

export default {
  data() {
    return {
      credentials: {
        email: ''
      },
      image: 'https://quote.reachportones.com/static/img/logo.png',
      isLoading: false
    }
  },
  components: {},
  computed: {
    isCompleted() {
      return this.credentials.email
    }
  },
  methods: {
    notifyVue(message, type) {
      this.$notifications.notify({
        message,
        // icon: 'ti-gift',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type
      })
    },
    async submit() {
      try {
        this.isLoading = true
        const { email } = this.credentials;
        await axios.post(`${constants.URL}/users/recover`, { email })
        this.notifyVue('Se ha enviado un link a tu correo', 'success')
        this.$router.push('/login')
      } catch (error) {
        const { message } = error.response.data
        this.notifyVue(`Error: ${message}`, 'danger');
        this.isLoading = false
      }
    }
  }
}
</script>
