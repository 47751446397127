<template>
  <modal v-model="modalToggle" title="Recordatorio" size="md">
      <form>
        <div class="row">
        <template v-if="action === 'CREATE' || action === 'UPDATE'">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('name')}">
              <label>Nombre del recordatorio</label>
              <input
                autocomplete="off"
                v-model="reminder.name"
                class="form-control border-input"
                v-validate="'required'"
                name="name"
                type="text"
                placeholder="Nombre" />
              <span class="help-block text-left">{{ errors.first('name') }}</span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('type')}">
              <label>Tipo</label>
              <select class="form-control border-input" name="type" v-model="reminder.type">
                    <option value="TRACKING">Seguimiento</option>
                    <option value="SUPPORT">Soporte</option>
                    <option value="GENERIC">Simple</option>
              </select>
              <span class="help-block text-left">{{ errors.first('type') }}</span>
            </div>
          </div>

          <div class="col-md-12" v-if="reminder.type === 'SUPPORT'">
            <div class="form-group">
              <label>Cliente</label>
              <input 
                  autocomplete="off"
                  id="client-input" 
                  class="form-control border-input" 
                  type="text" 
                  placeholder="Buscar cliente..." />
              <typeahead 
                  v-model="reminder.client" 
                  target="#client-input" 
                  :async-function="getClients" 
                  force-select 
                  @input="clientSelected"
                  item-key="name" />
            </div>
          </div>

          <div class="col-md-12" v-else-if="reminder.type === 'TRACKING'">
            <div class="form-group" >
              <label>Cotizacion</label>
              <input 
                  autocomplete="off"
                  id="quote-input" 
                  class="form-control border-input" 
                  type="text" 
                  placeholder="Buscar cotizacion..." />
              <typeahead 
                  v-model="reminder.quote" 
                  target="#quote-input" 
                  :async-function="getQuotes" 
                  force-select 
                  @input="quoteSelected"
                  item-key="title" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group" >
              <label class="radio-inline" for="inlineRadio1">
                <input type="radio" v-model="reminder.typeSchedule" 
                  name="inlineRadioOptions" id="inlineRadio1" value="RANGE"> Por rangos
              </label>
              <label class="radio-inline" for="inlineRadio2">
                <input type="radio" v-model="reminder.typeSchedule" 
                  name="inlineRadioOptions" id="inlineRadio2" value="CUSTOM"> Por fecha
              </label>
            </div>
          </div>

          <template v-if="reminder.typeSchedule === 'CUSTOM'">
            <div class="col-md-7">
              <template>
                  <dropdown class="form-group">
                    <label>Fecha</label>
                    <div class="input-group">
                      <input class="form-control border-input" type="text" :value="reminder.whenDate.date | formatDateNoTime">
                      <div class="input-group-btn">
                        <btn class="dropdown-toggle rh-border-btn"><i class="glyphicon glyphicon-calendar"></i></btn>
                      </div>
                    </div>
                    <template slot="dropdown">
                      <li>
                        <date-picker v-model="reminder.from"/>
                      </li>
                    </template>
                  </dropdown>
              </template>
            </div>

            <div class="col-md-5" >
              <template>
                  <dropdown class="form-group">
                    <label>Hora</label>
                    <div class="input-group">
                      <input class="form-control border-input" type="text" :value="reminder.whenDate.time.toTimeString()">
                      <div class="input-group-btn">
                        <btn class="dropdown-toggle rh-border-btn"><i class="glyphicon glyphicon-time"></i></btn>
                      </div>
                    </div>
                    <template slot="dropdown">
                      <li style="padding: 10px">
                        <time-picker v-model="reminder.whenDate.time"/>
                      </li>
                    </template>
                  </dropdown>
              </template>
            </div>
          </template>

          <template v-if="reminder.typeSchedule === 'RANGE'">
            <div class="col-md-4">
              <div class="form-group" :class="{'has-error': errors.first('amount')}">
                <label>Numero</label>
                <input
                  v-model="reminder.whenRange.amount"
                  class="form-control border-input"
                  v-validate="'required'"
                  min="1"
                  name="amount"
                  type="number"/>
                <span class="help-block text-left">{{ errors.first('amount') }}</span>
              </div>
            </div>

            <div class="col-md-8">
              <div class="form-group" :class="{'has-error': errors.first('unit')}">
                <label>Periodo</label>
                <select class="form-control border-input" v-model="reminder.whenRange.unit">
                      <option value="minutes">Minutos</option>
                      <option value="hours">Horas</option>
                      <option value="days">Dias</option>
                      <option value="months">Meses</option>
                      <option value="years">Años</option>
                </select>
                <span class="help-block text-left">{{ errors.first('amount') }}</span>
              </div>
            </div>
          </template>
          

        </template>
        <template v-if="action === 'CREATE' || action === 'SET'">

          <div class="col-md-12">
            <div class="form-group">
              <div class="checkbox checkbox-primary">
                  <input id="repeat" type="checkbox" v-model="reminder.repeat">
                  <label for="repeat">
                      Repetir
                  </label>
              </div>
            </div>
          </div>

          <template v-if="reminder.repeat && reminder.typeSchedule === 'CUSTOM'">
            <div class="col-md-4">
            <div class="form-group" :class="{'has-error': errors.first('amount')}">
              <label>Numero</label>
              <input
                v-model="reminder.whenRange.amount"
                class="form-control border-input"
                v-validate="'required'"
                min="1"
                name="amount"
                type="number"/>
              <span class="help-block text-left">{{ errors.first('amount') }}</span>
            </div>
          </div>

          <div class="col-md-8">
            <div class="form-group" :class="{'has-error': errors.first('unit')}">
              <label>Periodo</label>
              <select class="form-control border-input" v-model="reminder.whenRange.unit">
                    <option value="minutes">Minutos</option>
                    <option value="hours">Horas</option>
                    <option value="days">Dias</option>
                    <option value="months">Meses</option>
                    <option value="years">Años</option>
              </select>
              <span class="help-block text-left">{{ errors.first('amount') }}</span>
            </div>
          </div>
          </template>

          <div class="col-md-12">
            <div class="form-group">
              <div class="checkbox checkbox-primary">
                  <input id="postpone" type="checkbox" v-model="reminder.postpone.isActive">
                  <label for="postpone">
                      Recordar cada
                  </label>
              </div>
            </div>
          </div>

          <template v-if="reminder.postpone.isActive">
            <div class="col-md-4">
              <div class="form-group" :class="{'has-error': errors.first('postpone-amount')}">
                <label>Numero</label>
                <input
                  v-model="reminder.postpone.amount"
                  class="form-control border-input"
                  v-validate.continues="'required'"
                  min="1"
                  name="postpone-amount"
                  type="number"/>
                <span class="help-block text-left">{{ errors.first('postpone-amount') }}</span>
              </div>
            </div>

            <div class="col-md-8">
              <div class="form-group" :class="{'has-error': errors.first('postpone-unit')}">
                <label>Periodo</label>
                <select class="form-control border-input" v-model="reminder.postpone.unit">
                      <option value="minutes">Minutos</option>
                      <option value="hours">Horas</option>
                      <option value="days">Dias</option>
                      <option value="months">Meses</option>
                      <option value="years">Años</option>
                </select>
                <span class="help-block text-left">{{ errors.first('postpone-unit') }}</span>
              </div>
            </div>
          </template>
        </template>
        <template v-if="action === 'CREATE' || action === 'UPDATE'">
          <div class="col-md-12">
            <div class="form-group">
              <label>Notas </label>
              <textarea 
                v-model="reminder.notes"
                class="form-control border-input"
                placeholder="Descripcion" />
            </div>
          </div>
        </template>
        </div>
      </form>
      <div slot="footer">
        <btn @click="modalToggle=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !isCompleted" @click="saveReminder() || saving">
          Guardar
        </btn>
      </div>
    </modal>
</template>
<script>
  import { Modal, Btn, Typeahead, Dropdown, DatePicker, TimePicker } from 'uiv'
  import constants from '../../constants'
  import axios from 'axios'
  import moment from 'moment'

  const reminder = {
    name: '',
    client: null,
    quote: null,
    typeSchedule: 'RANGE',
    whenRange: {
      amount: 1,
      unit: 'days'
    },
    notes: '',
    type: 'SUPPORT',
    whenDate: {
      date: new Date(),
      time: new Date()
    },
    repeat: true,
    postpone: {
      isActive: true,
      amount: 3,
      unit: 'hours'
    }
  }

  export default{
    components: {
      Modal,
      Typeahead,
      Btn,
      Dropdown,
      DatePicker,
      TimePicker
    },
    props: {
      modalProp: Boolean,
      clientField: {
        type: Boolean,
        default: false
      },
      typeReminder: {
        type: String,
        default: 'GENERIC'
      },
      action: {
        type: String,
        default: 'CREATE'
      },
      value: {
        type: Object,
        default: null
      }
    },
    mounted () {
      this.setModel()
    },
    computed: {
      isCompleted () {
        switch (this.action) {
          case 'CREATE':
          case 'UPDATE':
            return this.reminder.name && this.reminder.amount &&
              this.reminder.unit
          case 'SET':
            return true
        }
        return false
      }
    },
    data () {
      return {
        reminder: { ...reminder },
        modalToggle: this.modalProp,
        actionModel: this.action,
        saving: false
      }
    },
    methods: {
      show (action) {
        if (action === 'CREATE') {
          this.$nextTick(() => {
            this.$validator.reset()
            this.reminder = { ...reminder }
            this.$validator.resume()
          })
        }
        this.modalToggle = true
      },
      setModel () {
        this.$validator.pause()
        if (this.value) {
          const time = this.value.when.split(' ')
          let customReminder = { ...this.value }
          delete customReminder.when
          delete customReminder.reminder
          customReminder.postpone = {}
          customReminder.amount = time[0]
          customReminder.unit = time[1]
          if (this.value.reminder && this.value.reminder.isActive) {
            const reminderTime = this.value.reminder.when.split(' ')
            customReminder.postpone = {
              amount: reminderTime[0],
              unit: reminderTime[1],
              isActive: this.value.reminder.isActive
            }
          } else {
            customReminder.postpone = this.reminder.postpone
          }
          this.$nextTick(() => {
            this.$validator.reset()
            this.$validator.resume()
            this.reminder = customReminder
          })
        }
      },
      saveReminder () {
        let time = moment(this.reminder.fromTime).format('HH:mm:s')
        let date = moment(this.reminder.from).format('YYYY-MM-DD')
        console.log(moment(date + ' ' + time))
        // switch (this.action) {
        //   case 'CREATE':
        //     this.addReminder()
        //     break
        //   case 'UPDATE':
        //     this.updateReminder()
        //     break
        //   case 'SET':
        //     this.setReminder()
        //     break
        // }
      },
      addReminder () {
        if (this.reminder.type === 'SUPPORT' && !this.reminder.client) {
          this.$notifications.notifyVue(`Cliente es requerido`, 'danger')
          return
        } else if (this.reminder.type === 'TRACKING' && !this.reminder.quote) {
          this.$notifications.notifyVue(`Cotizacion es requerida`, 'danger')
          return
        }
        this.saving = true

        let reminder = {
          name: this.reminder.name,
          when: `${this.reminder.amount} ${this.reminder.unit}`,
          notes: this.reminder.notes,
          type: this.reminder.type,
          repeat: this.reminder.repeat,
          reminder: {
            isActive: this.reminder.postpone.isActive,
            when: `${this.reminder.postpone.amount} ${this.reminder.postpone.unit}`
          }
        }

        if (this.reminder.type === 'SUPPORT') {
          reminder.client = this.reminder.client._id
        }

        if (this.reminder.type === 'TRACKING') {
          reminder.quote = this.reminder.quote._id
        }

        axios
          .post(`${constants.URL}/reminders`, reminder)
          .then(result => {
            this.saving = false
            this.modalToggle = false
            this.$notifications.notifyVue(`El recordatorio ha sido guardado exitosamente`, 'info')
            this.$emit('save', result.data)
          })
          .catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
          })
      },
      updateReminder () {
        if (this.reminder.type === 'SUPPORT' && !this.reminder.client) {
          this.$notifications.notifyVue(`Cliente es requerido`, 'danger')
          return
        } else if (this.reminder.type === 'TRACKING' && !this.reminder.quote) {
          this.$notifications.notifyVue(`Cotizacion es requerida`, 'danger')
          return
        }
        this.saving = true

        let reminder = {
          name: this.reminder.name,
          notes: this.reminder.notes,
          type: this.reminder.type
        }

        if (this.reminder.type === 'SUPPORT') {
          reminder.client = this.reminder.client._id
        }

        if (this.reminder.type === 'TRACKING') {
          reminder.quote = this.reminder.quote._id
        }

        axios
          .put(`${constants.URL}/reminders/${this.reminder._id}`, reminder)
          .then(result => {
            this.saving = false
            this.modalToggle = false
            this.$notifications.notifyVue(`El recordatorio ha sido guardado exitosamente`, 'info')
            this.$emit('save', result.data)
          })
          .catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
          })
      },
      setReminder () {
        this.saving = true
        const { postpone } = this.reminder
        const params = {
          action: 'SET',
          repeat: this.reminder.repeat,
          when: `${this.reminder.amount} ${this.reminder.unit}`,
          reminder: {
            isActive: postpone.isActive,
            when: `${postpone.amount} ${postpone.unit}`
          }
        }

        axios
          .put(`${constants.URL}/reminders/changeState/${this.reminder._id}`, params)
          .then(result => {
            this.saving = false
            this.modalToggle = false
            this.$notifications.notifyVue(`El recordatorio ha sido guardado exitosamente`, 'info')
            this.$emit('save', result.data)
          })
          .catch(error => {
            this.saving = false
            this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
          })
      },
      clientSelected (item) {
        if (item) {
          this.reminder.client = {...item}
        }
      },
      getClients (query, done) {
        const params = {
          q: query
        }
        axios
          .get(`${constants.URL}/clients`, { params })
          .then(response => {
            done(response.data.result)
          })
          .catch(error => {
            this.$notifications.notifyVue(`Error: ${error}`, 'danger')
          })
      },
      getQuotes (query, done) {
        const filters = {
          param: 'title',
          condition: 'contains',
          value: query
        }
        axios
          .get(`${constants.URL}/quotes`, { params: { filters } })
          .then(response => {
            done(response.data.result)
          })
          .catch(error => {
            this.$notifications.notifyVue(`Error: ${error}`, 'danger')
          })
      },
      quoteSelected (item) {
        if (item) {
          this.reminder.quote = {...item}
        }
      }
    },
    watch: {
      value: function () {
        this.setModel()
      }
    }
  }
</script>
