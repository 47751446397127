<template>
<div class="col-md-3">
  <div class="card text-center" @click="modal = true">
    <div class="image-view" v-bind:style="`background: url(${value.meta.imageThumb.url}); background-size:cover;`"></div>
    <span>{{value.name}}</span>
  </div>
  <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar esta imagen?
      <div class="text-center">
        <br />
        <btn @click="modalRemove=false">Cancel</btn>
        <btn type="danger" @click="deleteMedia()">Eliminar</btn>
      </div>
  </modal>
  <modal v-model="modal" title="Detalles" size="lg">
      <form>
        <div class="row media-modal">
          <div class="col-md-7 text-center">
              <img class="image-modal" v-bind:src="value.path" alt="">
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>URL</label>
                  <input
                    :value="value.meta.imageFullSize.url"
                    disabled
                    class="form-control border-input"
                    name="name"
                    type="text"
                    placeholder="URL"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Nombre</label>
                  <input
                    v-model="name"
                    class="form-control border-input"
                    name="name"
                    type="text"
                    placeholder="Nombre"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Alt</label>
                  <input
                    v-model="alt"
                    class="form-control border-input"
                    name="name"
                    type="text"
                    placeholder="Alt"/>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <a class="text-danger cursor-pointer" @click="modalRemove = true">Eliminar</a>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="modal=false">Cancel</btn>
        <btn type="primary" @click="changeModel()">
          Guardar
        </btn>
      </div>
    </modal>
</div>
</template>
<style lang="scss">
  .media-img {
    width: 100%;
    max-height: 344px;
    cursor: pointer;
  }
  .media-modal {
    min-height: 400px;
  }
  .image-modal {
    width: 100%;
  }
  .image-view {
    width: 100%;
    height: 200px;
    cursor: pointer;
  }
</style>


<script>
import { Modal, Btn } from 'uiv'
export default {
  components: {
    Modal,
    Btn
  },
  name: 'media-view',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          _id: null,
          path: '',
          name: '',
          alt: ''
        }
      }
    }
  },
  data () {
    return {
      modal: false,
      modalRemove: false,
      name: this.value.name,
      alt: this.value.alt
    }
  },
  methods: {
    changeModel () {
      const newValue = {
        ...this.value
      }
      newValue.name = this.name;
      newValue.alt = this.alt
      this.$emit('input', newValue)
      this.modal = false
    },
    deleteMedia () {
      this.modalRemove = false
      this.modal = false
      this.$emit('delete', this.value._id)
    }
  },
  mounted () {
    // console.log(this.media)
  }
}

</script>
<style>
</style>
