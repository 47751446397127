<template>
  <div class="row">
    <div class="col-md-12 row">
      <div class="col-md-7 text-left">
        <div class="category text-left rh-group-buttons"> 
          <router-link v-if="module.create" class="btn btn-info" :to="`/admin/shipping/form`">
            <i class="ti-plus"></i> Crear nuevo
          </router-link> 
          <btn type="primary" @click="$refs.filterBy.show()">
            <i class="ti-panel"></i> Filtrar
          </btn>
          <btn type="primary" @click="$refs.sortBy.show()">
            <i class="ti-arrows-vertical"></i> Ordenar
          </btn>

          <router-link v-if="module.create" class="btn btn-default" :to="`/admin/shipping/reports`">
            <i class="ti-panel"></i> Generar reporte
          </router-link> 
        </div>
      </div>
      <div class="col-md-5">
        <div class="input-group">
                <input 
                  type="text" 
                  class="form-control border-input" 
                  placeholder="Search" 
                  @keyup="search"
                  v-model="searchModel"
                  id="txtSearch"/>
                <div class="input-group-btn">
                  <button class="btn btn-primary" type="submit">
                    <span class="glyphicon glyphicon-search"></span>
                  </button>
                </div>
              </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <table class="table table-hover shipping-table">
        <thead>
          <tr>
            <th >Cliente</th>
            <th width="150" class="hidden-xs">Fecha</th>
            <th class="hidden-xs" >Vendedor</th>
            <th class="hidden-xs" >Repartidor</th>
            <th >Estado</th>
            <th class="hidden-xs">Productos</th>
            <th class="hidden-xs">Historial</th>
            <th class="total" style="width: 100px;">Total</th>
            <th>
              <i class="ti-settings"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </div>
          <template v-else>
            <template v-for="(shipping, index) in shippingList">
            <tr :key="index" >
              <td :id="index"> 
                <a @click="openDetail(index)">{{ shipping.client ? shipping.client.name : 'Sin cliente' }} </a>
                <!-- <i class="visible-mobile" @click="openDetail(index)" v-bind:class="[ shipping.isActive ? 'ti-angle-up' : 'ti-angle-down' ]" /> -->
              </td>
              <td class="hidden-xs" > {{shipping.date | formatDate}}</td>
              <td :id="index" class="hidden-xs"> {{ shipping.owner ? `${shipping.owner.firstName} ${shipping.owner.lastName}` : 'Sin vendedor' }} </td>
              <td :id="index" class="hidden-xs"> {{ shipping.courier ? `${shipping.courier.user.firstName} ${shipping.courier.user.lastName}` : 'Sin vendedor' }} </td>
              <td :id="index"> 
                <span class="label" 
                  v-bind:class="{ 
                    'label-primary': shipping.state === 'CREATED', 
                    'label-default': shipping.state === 'IN_ROUTE', 
                    'label-success': shipping.state === 'FINISHED' || shipping.state === 'DELIVERED',
                    'label-warning': shipping.state === 'REJECTED',
                    'label-danger': shipping.state === 'CANCELLED',
                }">
                  {{ getState(shipping.state) }} 
                </span>
              </td>
              <td :id="index" class="hidden-xs">{{  filterProducts(shipping.products) }}</td>
              <td :id="index" class="hidden-xs" >
                <span v-html="formatHistories(shipping.history)"></span>
              </td>
              <td :id="index" >{{ totalProduct(shipping.products) | toCurrency }}</td>
              <td>
                <a class="cursor-pointer" @click.prevent="openCxtMenu(shipping, index)"><i class="ti-more-alt"></i></a>
              </td>
            </tr>
            <tr :key="`detail-${index}`" :id="`detail-${index}`" class="hide">
              <td colspan="5" class="bg-info">
                <b>Fecha: </b> {{shipping.date | formatDate}} <br />
                <b>Cliente: </b> {{ shipping.client ? shipping.client.name : 'Sin cliente' }} <br />
                <b>Telefono: </b> {{ shipping.client ? shipping.client.phone : 'Sin telefono' }} <br />
                <b>Direccion: </b> {{ shipping.client ? shipping.client.address : 'Sin direccion' }} <br />
                <b>Solo envio:</b>{{ shipping.onlyShipping ? 'Si' : 'No' }} <br />

                <template v-if="!shipping.onlyShipping">
                  <b>Productos:</b> <br /> <span v-html="filterProducts(shipping.products)"></span> <br />
                  <b>Metodo de pago:</b> {{ getPaymentMethod(shipping.paymentMethod) }} <br />
                </template>


                <b>Nota inicial:</b> {{ shipping.notes }} <br />
                <b>Costo de envio:</b> {{ shipping.shippingCost ?? 0 | toCurrency }} <br />
                <b>Costos adicionales + costo de envio:</b> {{ shipping.proposalShippingCost ?? 0 | toCurrency }} <br />
                <b>Total: </b> {{ totalProduct(shipping.products) | toCurrency }} <br />
                <b>Estado: </b> 
                <span class="label" 
                v-bind:class="{ 
                      'label-primary': shipping.state === 'CREATED', 
                      'label-default': shipping.state === 'IN_ROUTE', 
                      'label-success': shipping.state === 'FINISHED' || shipping.state === 'DELIVERED',
                      'label-warning': shipping.state === 'REJECTED',
                      'label-danger': shipping.state === 'CANCELLED',
                  }">
                    {{ getState(shipping.state) }} 
                  </span> <br />
                <b>Vendedor: </b> {{ shipping.owner ? `${shipping.owner.firstName} ${shipping.owner.lastName}` : 'Sin vendedor' }} <br />
                <b>Repartidor: </b> {{ shipping.courier ? `${shipping.courier.user.firstName} ${shipping.courier.user.lastName}` : 'Sin vendedor' }} <br />
                <b>Historial: </b> 
                <span v-html="formatHistories(shipping.history)"></span>
              </td>
            </tr>
          </template>
          </template>
        </tbody>
      </table>
      </div>

      <div v-if="totalCount > limit">
        Elementos mostrados [{{((currentPage - 1) * limit) + 1}} - {{limit * currentPage}} de {{totalCount}}]
      </div>
      <div v-else-if="totalCount < limit">
        Elemento mostrados [{{totalCount}}]
      </div>
      <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
    </div>

    <modal v-model="modalDetails" title="Detalles" :footer="true" size="sm">
      <div>
        <b>Fecha: </b> {{shipping.date | formatDate}} <br />
                <b>Cliente: </b> {{ shipping.client ? shipping.client.name : 'Sin cliente' }} <br />
                <b>Telefono: </b> {{ shipping.client ? shipping.client.phone : 'Sin telefono' }} <br />
                <b>Direccion: </b> {{ shipping.client ? shipping.client.address : 'Sin direccion' }} <br />
                <b>Solo envio:</b> {{ shipping.onlyShipping ? 'Si' : 'No' }} <br />
                <template v-if="!shipping.onlyShipping">
                  <b>Productos:</b> <br /> <span v-html="filterProducts(shipping.products)"></span> <br />
                  <b>Metodo de pago:</b> {{ getPaymentMethod(shipping.paymentMethod) }} <br />
                </template>
                <b>Nota inicial:</b> {{ shipping.notes }} <br />
                <b>Costo de envio:</b> {{ shipping.shippingCost ?? 0 | toCurrency }} <br />
                <b>Costos adicionales + envio:</b> {{ shipping.proposalShippingCost ?? 0 | toCurrency }} <br />
                <b>Total: </b> {{ totalProduct(shipping.products ?? []) | toCurrency }} <br />
                <b>Estado: </b> 
                <span class="label" 
                v-bind:class="{ 
                      'label-primary': shipping.state === 'CREATED', 
                      'label-default': shipping.state === 'IN_ROUTE', 
                      'label-success': shipping.state === 'DELIVERED' || shipping.state === 'FINISHED',
                      'label-warning': shipping.state === 'REJECTED',
                      'label-danger': shipping.state === 'CANCELLED',
                  }">
                    {{ getState(shipping.state) }} 
                  </span> <br />
                <b>Vendedor: </b> {{ shipping.owner ? `${shipping.owner.firstName} ${shipping.owner.lastName}` : 'Sin vendedor' }} <br />
                <b>Repartidor: </b> {{ shipping.courier ? `${shipping.courier.user.firstName} ${shipping.courier.user.lastName}` : 'Sin vendedor' }} <br />
                <b>Historial: </b> 
                <span v-html="formatHistories(shipping.history ?? [])"></span>
      </div>
      <div slot="footer">
        <btn @click="modalDetails = false">Cerrar</btn>
      </div>
    </modal>

    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar este envio?
      <div class="text-center">
        <br />
        <btn type="danger" @click="deleteShipping()">Eliminar</btn> 
        <btn @click="modalRemove=false">Cancel</btn>
      </div>
    </modal>

    <modal v-model="modalChangeState" title="Cambiar estado" size="sm">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('state')}">
              <label>Nuevo estado</label>
              <select name="state" class="form-control border-input" v-model="stateModel" v-validate="'required'">
                <option value="CREATED">Creado</option>
                <option value="IN_ROUTE">En ruta</option>
                <option value="DELIVERED">Entregado</option>
                <option value="REJECTED">Rechazado</option>
                <option value="CANCELLED">Cancelado</option>
                <option value="FINISHED">Finalizado</option>
              </select>
              <span class="help-block text-left">{{ errors.first('state') }}</span>
            </div>
          </div>
          <div class="col-md-12" v-if="stateModel === 'DELIVERED'">
              <div class="form-group">
                  <label>Nuevo costo de envio</label>
                  <input v-model="proposalShippingCost" class="form-control border-input" name="proposalShippingCost" type="number"
                    placeholder="Proponer nuevo costo" />
                    <span class="text-info">Dejarlo en blanco si no hubieron costos adicionales en el proceso de envio</span>
                </div>
            </div>

          <div class="col-md-12" v-if="stateModel === 'FINISHED'">
            <div class="form-group">
              <div class="checkbox checkbox-primary">
                  <input id="accept" type="checkbox" v-model="additionalCost">
                  <label for="accept">
                      Aceptar costos adicionales
                  </label>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-if="stateModel === 'REJECTED' || stateModel === 'CANCELLED' || stateModel === 'FINISHED'">
            <div class="form-group">
              <label>Notas </label>
              <form-textarea name="notes" v-model="notes" ref="formTextarea" :cssClass="`form-control border-input`" v-validate="'required'"></form-textarea>
              <span class="help-block text-left">{{ errors.first('notes') }}</span>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer">
        <btn @click="modalChangeState=false">Cancel</btn>
        <btn type="primary" :disabled="errors.any() || !stateModel" @click="changeState() || saving">
          Guardar
        </btn>
      </div>
    </modal>

      <context-menu class="cursor-pointer" id="context-menu" ref="ctxMenu">
        <li @click="openDetail(index)">
          <i class="ti-notepad"></i> Detalles
        </li>
        <li v-if="module.update" @click="$router.push(`/admin/shipping/form/${shipping._id}`)">
          <i class="ti-pencil-alt"></i> Editar
        </li>
        <li v-if="module.meta?.changeStatus" @click="modalChangeState = true">
          <i class="ti-control-shuffle"></i> Cambiar Estado
        </li>
        <li v-if="module.delete" class="text-danger" @click="openDeleteModel(index)">
          <i class="ti-trash"></i> Eliminar
        </li>
      </context-menu>

    <SortBy 
      ref="sortBy" 
      :modalProp="false" 
      :byValue="byModel" 
      :byCriteo="criteoValueDefault" 
      :by="sortByParams" 
      @update="sortByEvent"/>

    <FilterBy 
      ref="filterBy" 
      :modalProp="false" 
      :criteo="criteoFilterBy" 
      :options="filterByParams" 
      :get-options="getOptions"
      :criteo-default="criteoDefault"
      :option-default="filterDefault"
      @output="onOutputFilter" />
      
  </div>
</template>
<style>
  .add-field {
    margin-top: 20px;
  }
  .list-histories {
    margin-left: -18px !important;
  }
</style>

<script>
import constants from '../../constants'
import SortBy from '../../components/Filters/SortBy'
import FilterBy from '../../components/Filters/FilterBy'

import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'
import contextMenu from 'vue-context-menu'
import { Validator } from 'vee-validate'
import { isEmpty } from 'lodash'
import formTextarea from '../../components/Inputs/formTextarea.vue'
import moment from 'moment'
import Vue from 'vue'

let titleExt = ''

Validator.extend('duplicate', {
  getMessage: field => `The ${field} value cannot be equal than the original`,
  validate: value => titleExt !== value
})

const sortByParams = [
  {
    id: 'client.name',
    name: 'Clientes'
  },
  {
    id: 'date',
    name: 'Fecha'
  }
]

const byModel = {
  id: 'date',
  name: 'Fecha'
}

const criteoValueDefault = {
  id: 'desc',
  name: 'Descendente'
}

const filterByParams = [
  {
    key: 'client.name',
    label: 'Clientes',
    meta: 'clients',
    type: 'DROPDOWN'
  },
  {
    key: 'date',
    label: 'Fechas',
    meta: 'shipping',
    type: 'DATERANGE',
    criteo: [
      {
        key: 'range',
        label: 'Rango de valores'
      }
    ]
  }
]

const criteoFilterBy = [
  {
    key: 'equal',
    label: 'Igual a'
  },
  {
    key: 'contains',
    label: 'Contener valor'
  }
]

export default {
  components: {
    Modal,
    Pagination,
    Btn,
    contextMenu,
    SortBy,
    FilterBy,
    formTextarea
  },
  created () {
    this.fetchShippings()
  },
  data () {
    return {
      loading: false,
      exporting: false,
      index: -1,
      modalRemove: false,
      modalChangeState: false,
      modalDetails: false,
      additionalCost: false,
      proposalShippingCost: 0,
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: '',
      title: '',
      saving: false,
      sortByParams: [...sortByParams],
      byModel,
      criteoValueDefault,
      criteoFilterBy,
      filterByParams,
      sortBy: {
        date: 'desc'
      },
      criteoDefault: criteoFilterBy[0],
      filterDefault: filterByParams[0],
      filters: [],
      stateModel: null,
      shipping: {},
      shippingList: [],
      notes: '',
    }
  },
  computed: {
    isCompleted () {
      return this.title
    },
    defaultCompany() {
      return this.$store.state.company;
    },
    module() {
        let module;
        if (this.$store.state.view) {
          const view = JSON.parse(this.$store.state.view);
          const actions = view.actions;
          const moduleView = actions.find(action => action.module === 'shipping');
          module = moduleView;
        } else {
          module = {
            create: true,
            update: true,
            delete: true,
            read: true,
            meta: {
              changeStatus: true
            }
          }
        } 
        return module;
      }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchShippings()
      }
    }
  },
  methods: {
    search () {
      this.fetchShippings()
    },
    async fetchShippings () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }

      if (this.searchModel) {
        params.q = this.searchModel
      }

      if (!isEmpty(this.filters)) {
        params.filters = JSON.stringify(this.filters)
      }

      if (this.sortBy) {
        params.sort = JSON.stringify(this.sortBy)
      }
      try {
        const response = await axios.get(`${constants.URL}/shipping`, { params })
        this.totalCount = response.data.count
        this.totalPage = Math.ceil(this.totalCount / this.limit)
        this.shippingList = response.data.result
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    async changePage () {
      await this.fetchShippings()
    },
    openDeleteModel (index) {
      this.modalRemove = true
      this.index = index
    },
    deleteShipping () {
      const shipping = this.shippingList[this.index]
      axios
        .delete(`${constants.URL}/shipping/${shipping._id}`)
        .then(() => {
          this.shippingList.splice(this.index, 1)
          this.modalRemove = false
        }).catch(error => {
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    changeState() {
      this.saving = true
      const req = { 
        state: this.stateModel, 
        notes: this.notes 
      };
      if (this.proposalShippingCost > 0) {
        req.proposalShippingCost = this.proposalShippingCost
      }

      if (this.additionalCost) {
        req.additionalCost = this.additionalCost
      }
      axios
        .put(`${constants.URL}/shipping/change-state/${this.shipping._id}`, req)
        .then(() => {
          this.modalChangeState = false
          this.fetchShippings()
          this.saving = false
        })
        .catch(error => {
          const message = error.response.data.error ?? 'Error al cambiar estado'
          this.saving = false
          this.$notifications.notifyVue(`Error: <b>${message}</b>`, 'danger')
        })
    },
    openCxtMenu (shipping, index) {
      this.shipping = shipping
      this.index = index
      this.$refs.ctxMenu.open()
    },
    sortByEvent (params) {
      this.sortBy = {}
      this.sortBy[params.by] = params.criteo
      this.fetchShippings()
    },
    onOutputFilter (params) {
      if (!params.filter) {
        this.filters = []
        this.fetchShippings()
        return
      }
      const { criteo, filterBy, filter } = params
      this.filters.push({
        param: filterBy.key,
        condition: criteo.key,
        value: filterBy.type === 'DROPDOWN' ? filter.name : filter
      })
      this.fetchShippings()
    },
    async getOptions (params) {
      const { meta, query, done } = params
      try {
        const params = {
          skip: 0,
          take: 10,
          q: query
        }
        const result = await axios.get(`${constants.URL}/${meta}`, { params })
        done(result.data.result)
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    getState (state) {
      switch (state) {
        case 'CREATED':
          return 'Creado'
        case 'IN_ROUTE':
          return 'En ruta'
        case 'DELIVERED':
          return 'Entregado'
        case 'FINISHED':
          return 'Finalizado'
        case 'REJECTED':
          return 'Rechazado'
        case 'CANCELLED':
          return 'Cancelado'
        default:
          return 'Sin estado'
      }
    },
    getPaymentMethod (method) {
      switch (method) {
        case 'CASH':
          return 'Efectivo'
        case 'CARD':
          return 'Tarjeta'
        case 'TRANSFER':
          return 'Transferencia'
        default:
          return 'Sin estado'
      }
    },
    totalProduct (products) {
      const priceList = products
        .map(item => parseInt(item.price) * item.quantity)
      const total = priceList.length > 0 ? priceList.reduce((a, b) => a + b) : 0
      return total || 0
    },
    filterProducts (fields = []) {
      let productoList = fields
        .map(val => {
            return `${val.product.name}, precio c/u: ${val.price}, cantidad: ${val.quantity}`
        })
      return productoList.join('<br />')
    },
    openDetail (index) {
      const shipping = this.shippingList[index]
      if (window.innerWidth < 768) {
        let el = document.querySelector(`#detail-${index}`)
        if (!el) {
          return
        }
        const status = el.classList.contains('hide')
        shipping.isActive = status
        Vue.set(this.shippingList, index, shipping)
        if (status) {
          el.classList.remove('hide')
        } else {
          el.classList.add('hide')
        }
      } else {
        this.modalDetails = true
        this.shipping = shipping
      }

      
    },
    formatHistories (histories) {
      let format = '<ul class="list-histories">';
      const list = histories
        .map(history => {
          const date = moment(history.createAt).format('DD/MM/YYYY HH:mm:ss')
          return `<li>${date} - ${this.getState(history.status)} - ${history.notes} </li>`
        })
      format += list.join('')
      format += '</ul>'
      return format
    }
  }
}
</script>
