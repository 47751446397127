<template>
  <div class="row">
    <div class="col-md-12 row">
      <div class="col-md-12">
        <h5 class="text-left">Reporte de envios</h5>
      </div>
      <div class="col-md-12">
        <div class="category text-left rh-group-buttons"> 
          <b>Filtros: </b>
          <template v-for="(filter, index) in filters">
            <span :key="filter.label" class="label filter-label-default filter-label">
              {{ filter.label }}: {{ filter.text }}
              <i class="ti-close text-primary filter-close" @click="removeFilter(index)"></i>
            </span>
          </template>
            <btn type="primary" @click="$refs.filterBy.show()">
              <i class="ti-plus"></i>
            </btn>
          </div>
        </div>
      <div class="col-md-12 buttons-section">
        <btn 
          type="info" 
          class="btn-section" 
          @click="fetchShippings()"
          :disabled="loading" 
        >
          Generar reporte
        </btn>

        <btn type="default" class="btn-section" @click="clearFilters()">
          Limpiar
        </btn>
      </div>
    </div>
    <div class="col-md-12" v-if="shippingList.length > 0">
      <div class="row">
        <div class="col-lg-12 col-sm-6">
          <div class="card">
            <div class="content">
              <div class="row">
                <div class="col-md-3 row">
                  <div class="col-xs-4">
                    <div class="numbers text-left">
                      <p class="text-center">Suma total</p>
                      Q{{ summary.total }}
                    </div>
                  </div>
                </div>

                <div class="col-md-3 row">
                  <div class="col-xs-5">
                    <div class="numbers text-left">
                      <p class="text-center">Efectivo</p>
                      Q{{ summary.totalCash }}
                    </div>
                  </div>
                </div>

                <div class="col-md-3 row">
                  <div class="col-xs-6">
                    <div class="numbers">
                      <p class="text-center">Depositos</p>
                      Q{{ summary.totalCredit }}
                    </div>
                  </div>
                </div>

                <div class="col-md-3 row">
                  <div class="col-xs-6">
                    <div class="numbers">
                      <p>Costo de envio</p>
                      Q{{ summary.totalShipping }}
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="header row">
          <div class="col-md-6">
            <h4><i class="ti-layout"></i> Listado de envios</h4>
          </div>
          <div class="col-md-6 text-right">
            <btn type="primary" class="btn-section" @click="$refs.sortBy.show()">
              <i class="ti-arrows-vertical"></i>
            </btn>

            <btn type="default" class="btn-section" @click="$refs.filterBy.show()">
              <i class="ti-panel"></i>
            </btn>
          </div>
        </div>
        <table class="table table-hover shipping-table">
        <thead>
          <tr>
            <th >Cliente</th>
            <th width="150" class="hidden-xs">Fecha</th>
            <th class="hidden-xs" >Vendedor</th>
            <th class="hidden-xs" >Repartidor</th>
            <th >Estado</th>
            <th class="hidden-xs">Productos</th>
            <th class="hidden-xs">Historial</th>
            <th class="total" style="width: 100px;">Total</th>
          </tr>
        </thead>
        <tbody>
          <div v-if="loading">
            <tr> 
              <td>Loading... </td>
            </tr>
          </div>
           <template v-else>
            <template v-for="(shipping, index) in shippingList">
            <tr :key="index" >
              <td :id="index"> 
                <a @click="openDetail(index)">{{ shipping.client ? shipping.client.name : 'Sin cliente' }} </a>
              </td>
              <td class="hidden-xs" > {{shipping.date | formatDate}}</td>
              <td :id="index" class="hidden-xs"> {{ shipping.owner ? `${shipping.owner.firstName} ${shipping.owner.lastName}` : 'Sin vendedor' }} </td>
              <td :id="index" class="hidden-xs"> {{ shipping.courier ? `${shipping.courier.user.firstName} ${shipping.courier.user.lastName}` : 'Sin vendedor' }} </td>
              <td :id="index"> 
                <span class="label" 
                  v-bind:class="{ 
                    'label-primary': shipping.state === 'CREATED', 
                    'label-default': shipping.state === 'IN_ROUTE', 
                    'label-success': shipping.state === 'FINISHED' || shipping.state === 'DELIVERED',
                    'label-warning': shipping.state === 'REJECTED',
                    'label-danger': shipping.state === 'CANCELLED',
                }">
                  {{ getState(shipping.state) }} 
                </span>
              </td>
              <td :id="index" class="hidden-xs">{{  filterProducts(shipping.products) }}</td>
              <td :id="index" class="hidden-xs" >
                <span v-html="formatHistories(shipping.history)"></span>
              </td>
              <td :id="index" >{{ totalProduct(shipping.products) | toCurrency }}</td>
            </tr>
            <tr :key="`detail-${index}`" :id="`detail-${index}`" class="hide">
              <td colspan="5" class="bg-info">
                <b>Fecha: </b> {{shipping.date | formatDate}} <br />
                <b>Cliente: </b> {{ shipping.client ? shipping.client.name : 'Sin cliente' }} <br />
                <b>Telefono: </b> {{ shipping.client ? shipping.client.phone : 'Sin telefono' }} <br />
                <b>Direccion: </b> {{ shipping.client ? shipping.client.address : 'Sin direccion' }} <br />
                <b>Solo envio:</b>{{ shipping.onlyShipping ? 'Si' : 'No' }} <br />

                <template v-if="!shipping.onlyShipping">
                  <b>Productos:</b> <br /> <span v-html="filterProducts(shipping.products)"></span> <br />
                  <b>Metodo de pago:</b> {{ getPaymentMethod(shipping.paymentMethod) }} <br />
                </template>


                <b>Nota inicial:</b> {{ shipping.notes }} <br />
                <b>Costo de envio:</b> {{ shipping.shippingCost ?? 0 | toCurrency }} <br />
                <b>Costos adicionales + costo de envio:</b> {{ shipping.proposalShippingCost ?? 0 | toCurrency }} <br />
                <b>Total: </b> {{ totalProduct(shipping.products) | toCurrency }} <br />
                <b>Estado: </b> 
                <span class="label" 
                v-bind:class="{ 
                      'label-primary': shipping.state === 'CREATED', 
                      'label-default': shipping.state === 'IN_ROUTE', 
                      'label-success': shipping.state === 'FINISHED' || shipping.state === 'DELIVERED',
                      'label-warning': shipping.state === 'REJECTED',
                      'label-danger': shipping.state === 'CANCELLED',
                  }">
                    {{ getState(shipping.state) }} 
                  </span> <br />
                <b>Vendedor: </b> {{ shipping.owner ? `${shipping.owner.firstName} ${shipping.owner.lastName}` : 'Sin vendedor' }} <br />
                <b>Repartidor: </b> {{ shipping.courier ? `${shipping.courier.user.firstName} ${shipping.courier.user.lastName}` : 'Sin vendedor' }} <br />
                <b>Historial: </b> 
                <span v-html="formatHistories(shipping.history)"></span>
              </td>
            </tr>
          </template>
          </template> 
        </tbody>
      </table>
      </div>
      <div class="pagination-container text-left">
        <div v-if="totalCount > limit">
          Elementos mostrados [{{((currentPage - 1) * limit) + 1}} - {{limit * currentPage}} de {{totalCount}}]
        </div>
        <div v-else-if="totalCount < limit">
          Elemento mostrados [{{totalCount}}]
        </div>
        <pagination v-if="totalCount > limit" v-model="currentPage" :total-page="totalPage" @change="changePage"/>
      </div>
      
    </div>

    <modal v-model="modalDetails" title="Detalles" :footer="true" size="sm">
      <div>
        <b>Fecha: </b> {{shipping.date | formatDate}} <br />
                <b>Cliente: </b> {{ shipping.client ? shipping.client.name : 'Sin cliente' }} <br />
                <b>Telefono: </b> {{ shipping.client ? shipping.client.phone : 'Sin telefono' }} <br />
                <b>Direccion: </b> {{ shipping.client ? shipping.client.address : 'Sin direccion' }} <br />
                <b>Solo envio:</b> {{ shipping.onlyShipping ? 'Si' : 'No' }} <br />
                <template v-if="!shipping.onlyShipping">
                  <b>Productos:</b> <br /> <span v-html="filterProducts(shipping.products)"></span> <br />
                  <b>Metodo de pago:</b> {{ getPaymentMethod(shipping.paymentMethod) }} <br />
                </template>
                <b>Nota inicial:</b> {{ shipping.notes }} <br />
                <b>Costo de envio:</b> {{ shipping.shippingCost ?? 0 | toCurrency }} <br />
                <b>Costos adicionales + envio:</b> {{ shipping.proposalShippingCost ?? 0 | toCurrency }} <br />
                <b>Total: </b> {{ totalProduct(shipping.products ?? []) | toCurrency }} <br />
                <b>Estado: </b> 
                <span class="label" 
                v-bind:class="{ 
                      'label-primary': shipping.state === 'CREATED', 
                      'label-default': shipping.state === 'IN_ROUTE', 
                      'label-success': shipping.state === 'DELIVERED' || shipping.state === 'FINISHED',
                      'label-warning': shipping.state === 'REJECTED',
                      'label-danger': shipping.state === 'CANCELLED',
                  }">
                    {{ getState(shipping.state) }} 
                  </span> <br />
                <b>Vendedor: </b> {{ shipping.owner ? `${shipping.owner.firstName} ${shipping.owner.lastName}` : 'Sin vendedor' }} <br />
                <b>Repartidor: </b> {{ shipping.courier ? `${shipping.courier.user.firstName} ${shipping.courier.user.lastName}` : 'Sin vendedor' }} <br />
                <b>Historial: </b> 
                <span v-html="formatHistories(shipping.history ?? [])"></span>
      </div>
      <div slot="footer">
        <btn @click="modalDetails = false">Cerrar</btn>
      </div>
    </modal>

    <SortBy 
      ref="sortBy" 
      :modalProp="false" 
      :byValue="byModel" 
      :byCriteo="criteoValueDefault" 
      :by="sortByParams" 
      @update="sortByEvent"/>

    <FilterBy 
      ref="filterBy" 
      :modalProp="false" 
      :criteo="criteoFilterBy" 
      :options="filterByParams" 
      :get-options="getOptions"
      :criteo-default="criteoDefault"
      :option-default="filterDefault"
      @output="onOutputFilter" />
      
  </div>
</template>
<style lang="scss">
  .add-field {
    margin-top: 20px;
  }
  .list-histories {
    margin-left: -18px !important;
  }
  .buttons-section {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .clear-filters {
    padding-top: 5px;
    padding-left: 61px;
  }
  .btn-section {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
  }
  .card .header {
    & h4 {
      margin-top: 0;
      font-weight: 400;
    }
  }
  .pagination-container{
    padding-bottom: 20px;
  }
</style>

<script>
import constants from '../../constants'
import SortBy from '../../components/Filters/SortBy'
import FilterBy from '../../components/Filters/FilterBy'

import { Modal, Btn, Pagination } from 'uiv'
import axios from 'axios'
import { Validator } from 'vee-validate'
import { isEmpty } from 'lodash'
import moment from 'moment'
import Vue from 'vue'

let titleExt = ''

Validator.extend('duplicate', {
  getMessage: field => `The ${field} value cannot be equal than the original`,
  validate: value => titleExt !== value
})

const sortByParams = [
  {
    id: 'client.name',
    name: 'Cliente'
  },
  {
    id: 'date',
    name: 'Fecha'
  }
]

const byModel = {
  id: 'date',
  name: 'Fecha'
}

const criteoValueDefault = {
  id: 'desc',
  name: 'Descendente'
}

const filterByParams = [
  {
    key: 'client.name',
    label: 'Clientes',
    meta: 'clients',
    type: 'DROPDOWN'
  },
  {
    key: 'courier',
    label: 'Repartidor',
    meta: 'shipping/couriers',
    type: 'DROPDOWN'
  },
  {
    key: 'owner',
    label: 'Vendedor',
    meta: 'company/users/findUsers',
    type: 'DROPDOWN'
  },
  {
    key: 'state',
    label: 'Estado',
    meta: '',
    type: 'SELECT',
    items: [
      {
        key: 'CREATED',
        name: 'Creado'
      },
      {
        key: 'IN_ROUTE',
        name: 'En ruta'
      },
      {
        key: 'DELIVERED',
        name: 'Entregado'
      },
      {
        key: 'FINISHED',
        name: 'Finalizado'
      },  
      {
        key: 'REJECTED',
        name: 'Rechazado'
      },
      {
        key: 'CANCELLED',
        name: 'Cancelado'
      }
    ]
  },
  {
    key: 'date',
    label: 'Fechas',
    meta: 'shipping',
    type: 'DATERANGE',
    criteo: [
      {
        key: 'range',
        label: 'Rango de valores'
      }
    ]
  }
]

const criteoFilterBy = [
  {
    key: 'equal',
    label: 'Igual a'
  },
  {
    key: 'contains',
    label: 'Contener valor'
  }
]

export default {
  components: {
    Modal,
    Pagination,
    Btn,
    SortBy,
    FilterBy,
  },
  created () {
    const { filters } = this.$route.query
    if (filters && filters.length > 0) {
      this.filters = JSON.parse(filters)
      this.fetchShippings()
    }
    //this.fetchShippings()
  },
  data () {
    return {
      loading: false,
      exporting: false,
      index: -1,
      modalRemove: false,
      modalChangeState: false,
      modalDetails: false,
      additionalCost: false,
      proposalShippingCost: 0,
      limit: 10,
      totalPage: 0,
      currentPage: 1,
      totalCount: 0,
      searchModel: '',
      title: '',
      saving: false,
      sortByParams: [...sortByParams],
      byModel,
      criteoValueDefault,
      criteoFilterBy,
      filterByParams,
      sortBy: null,
      criteoDefault: criteoFilterBy[0],
      filterDefault: filterByParams[0],
      filters: [],
      stateModel: null,
      shipping: {},
      shippingList: [],
      summary: {},
      notes: '',
    }
  },
  computed: {
    isCompleted () {
      return this.title
    },
    defaultCompany() {
      return this.$store.state.company;
    },
    module() {
        let module;
        if (this.$store.state.view) {
          const view = JSON.parse(this.$store.state.view);
          const actions = view.actions;
          const moduleView = actions.find(action => action.module === 'shipping');
          module = moduleView;
        } else {
          module = {
            create: true,
            update: true,
            delete: true,
            read: true,
            meta: {
              changeStatus: true
            }
          }
        } 
        return module;
      }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.fetchShippings()
      }
    }
  },
  methods: {
    search () {
      this.fetchShippings()
    },
    async fetchShippings () {
      this.loading = true
      const params = {
        skip: (this.currentPage - 1) * this.limit,
        take: this.limit
      }

      if (this.searchModel) {
        params.q = this.searchModel
      }

      if (!isEmpty(this.filters)) {
        params.filters = JSON.stringify(this.filters.map(filter => {
          return {
            param: filter.param,
            condition: filter.condition,
            value: filter.value
          }
        }))
      }

      if (this.sortBy) {
        params.sort = JSON.stringify(this.sortBy)
      }
      try {
        const response = await axios.get(`${constants.URL}/shipping`, { params })
        this.totalCount = response.data.count
        this.totalPage = Math.ceil(this.totalCount / this.limit)
        this.shippingList = response.data.result
        this.summary = response.data.summary
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    async changePage () {
      await this.fetchShippings()
    },
    openDeleteModel (index) {
      this.modalRemove = true
      this.index = index
    },
    sortByEvent (params) {
      this.sortBy = {}
      this.sortBy[params.by] = params.criteo
      this.fetchShippings()
    },
    removeFilter (index) {
      this.filters.splice(index, 1)
      const routeParams = {
        query: {
          ...this.$route.query,
          filters: JSON.stringify(this.filters)
        }
      }
      this.$router.replace(routeParams)
    },
    clearFilters () {
      this.filters = []
      this.shippingList = []
      const routeParams = {
        query: {
          ...this.$route.query,
          filters: []
        }
      }
      this.$router.replace(routeParams)
    },
    onOutputFilter (params) {
      if (!params.filter) {
        this.filters = []
        return
      }
      const {criteo, filterBy, filter } = params
      let value = '';
      if (filterBy.type === 'SELECT') {
            value = filterBy.key;
          } else if (filterBy.type === 'DROPDOWN') {
            if (filterBy.key.includes('client')) {
              value = filter['name']
            } else if (filterBy.key.includes('courier')) {
              value = filter?._id
            } else {
              value = filter?.user?._id
            }
          } else {
            value = filter;
          }
      if (this.filters.some(filter => {
        return filter.param === filterBy.key && filter.value === value
      })) {
        return;
      }
      this.filters.push({
        param: filterBy.key,
        condition: criteo.key,
        label: filterBy.label,
        text: this.getFilterValue(filterBy.key, filter),
        value
      })
      const routeParams = {
        query: {
          ...this.$route.query,
          filters: JSON.stringify(this.filters)
        }
      }
      this.$router.replace(routeParams)
    },
    async getOptions (params) {
      const { meta, query, done } = params
      try {
        const params = {
          skip: 0,
          take: 10,
          q: query
        }
        const result = await axios.get(`${constants.URL}/${meta}`, { params })

        if (meta.includes('couriers') || meta.includes('findUsers')) {
          done(result.data.result.map(item => {
            return {
              ...item,
              name: item.user.firstName + ' ' + item.user.lastName
            }
          }))
        } else {
          done(result.data.result)
        }
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    getFilterValue(key, filter) {
      switch (key) {
        case 'date':
          return `${filter.startOf} - ${filter.endOf}`
        case 'client.name':
        case 'courier':
        case 'owner':
        case 'state':
          return filter.name
        default:
          return ''
      }
    },
    getState (state) {
      switch (state) {
        case 'CREATED':
          return 'Creado'
        case 'IN_ROUTE':
          return 'En ruta'
        case 'DELIVERED':
          return 'Entregado'
        case 'FINISHED':
          return 'Finalizado'
        case 'REJECTED':
          return 'Rechazado'
        case 'CANCELLED':
          return 'Cancelado'
        default:
          return 'Sin estado'
      }
    },
    getPaymentMethod (method) {
      switch (method) {
        case 'CASH':
          return 'Efectivo'
        case 'CARD':
          return 'Tarjeta'
        case 'TRANSFER':
          return 'Transferencia'
        default:
          return 'Sin estado'
      }
    },
    totalProduct (products) {
      const priceList = products
        .map(item => parseInt(item.price) * item.quantity)
      const total = priceList.length > 0 ? priceList.reduce((a, b) => a + b) : 0
      return total || 0
    },
    filterProducts (fields = []) {
      let productoList = fields
        .map(val => {
            return `${val.product.name}, precio c/u: ${val.price}, cantidad: ${val.quantity}`
        })
      return productoList.join('<br />')
    },
    openDetail (index) {
      const shipping = this.shippingList[index]
      if (window.innerWidth < 768) {
        let el = document.querySelector(`#detail-${index}`)
        if (!el) {
          return
        }
        const status = el.classList.contains('hide')
        shipping.isActive = status
        Vue.set(this.shippingList, index, shipping)
        if (status) {
          el.classList.remove('hide')
        } else {
          el.classList.add('hide')
        }
      } else {
        this.modalDetails = true
        this.shipping = shipping
      }

      
    },
    formatHistories (histories) {
      let format = '<ul class="list-histories">';
      const list = histories
        .map(history => {
          const date = moment(history.createAt).format('DD/MM/YYYY HH:mm:ss')
          return `<li>${date} - ${this.getState(history.status)} - ${history.notes} </li>`
        })
      format += list.join('')
      format += '</ul>'
      return format
    }
  }
}
</script>
