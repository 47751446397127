<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="header">
          <h4 class="title">Crear Rol</h4>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group" :class="{'has-error': errors.first('name')}">
                <label>Nombre</label>
                <input
                  v-model="role.name"
                  class="form-control border-input"
                  v-validate="'required'"
                  name="name"
                  type="text"
                  placeholder="Titulo" />
                <span class="help-block text-left">{{ errors.first('name') }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <table class="table table-hover quote-table">
          <thead>
            <tr>
              <th width="15">
                #
              </th>
              <th class="hidden-xs">Recurso</th>
              <th>Accion</th>
              <th>Permiso</th>
              <th>Atributtos</th>
              <th>
                <i class="ti-settings"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr>
                <td>Loading... </td>
              </tr>
            </template>
            <template v-else>
              <tr :key="index" v-for="(ac, index) in role.acRoles">
                <td>{{ index + 1 }}</td>
                <td>{{ ac.resource }}</td>
                <td>{{ getAction(ac.action) }}</td>
                <td>{{ getPermission(ac.action) }}</td>
                <td>{{ ac.attributes }}</td>
                <td>
                  <a class="cursor-pointer" @click="openEditResource(ac, index)">
                    <i class="ti-pencil-alt"></i>
                  </a>
                  <a class="text-danger space cursor-pointer" @click="openDeleteModal(index)">
                    <i class="ti-trash" />
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <a class="pull-left cursor-pointer" @click="openAddModal">
          <i class="ti-plus" /> Agregar
        </a>
            </div>
            <div class="col-md-12">
                <btn 
                  class="pull-right cancel-btn"
                  :disabled="errors.any() || !isCompleted || saving"
                  @click="saveRole"
                  type="primary">Guardar</btn>
                
                <router-link class="btn btn-default pull-right" :to="`/admin/quotes`">
                  Cancelar
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model="showFormModal" title="Agregar recurso" size="sm">
      <form>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('resource')}">
              <label>Recurso</label>
              <select class="form-control border-input" 
                  name="resource"
                  v-model="resource.resource"
                  v-validate="'required'">
                  <option v-for="(resource, index) in resourceList" v-bind:value="resource" :key="index">
                    {{ resource }}
                  </option>
                </select>
              <span class="help-block text-left">{{ errors.first('resource') }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('action')}">
              <label>Accion</label>
              <select class="form-control border-input" 
                  name="action"
                  v-model="resource.action"
                  v-validate="'required'">
                  <option value="read">Lectura</option>
                  <option value="create">Crear</option>
                  <option value="update">Actualizar</option>
                  <option value="delete">Eliminar</option>
                </select>
              <span class="help-block text-left">{{ errors.first('action') }}</span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('permission')}">
              <label>Permiso</label>
              <select class="form-control border-input" 
                  name="permission"
                  v-model="resource.permission"
                  v-validate="'required'">
                  <option value="any">Todos</option>
                  <option value="own">Propios</option>
                </select>
              <span class="help-block text-left">{{ errors.first('permission') }}</span>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group" :class="{'has-error': errors.first('attributes')}">
              <label>Atributos</label>
              <input
                class="form-control border-input"
                v-validate="'required'"
                v-model="resource.attributes"
                name="attributes"
                type="text"
              />
              <span class="help-block text-left">{{ errors.first('attributes') }}</span>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
      <div slot="footer">
        <btn @click="showFormModal=false">Cancel</btn>
        <btn 
          @click="saveResource" 
          :disabled="errors.any() || !isFormValid"
          type="primary">
          Guardar
        </btn>
      </div>
    </modal>

    <modal v-model="showDeleteModal" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar este recurso?
      <div class="text-center space-btn">
        <br />
        <btn @click="showDeleteModal=false">Cancel</btn>
        <btn type="danger" @click="deleteResource()">Eliminar</btn>
      </div>
    </modal>
  </div>
</template>
<style>
  .cancel-btn {
    margin-left: 10px;
  }
</style>

<script>
import { Btn, Modal } from 'uiv'
import constants from '../constants'
import Vue from 'vue'

const resourceBase = {
  resource: '',
  action: '',
  permission: '',
  attributes: '*'
}

const roleBase = {
  name: '',
  acRoles: []
}

export default {
  components: {
    Btn,
    Modal
  },
  async mounted () {
    this.id = this.$route.params.id;
    this.companyId = this.$route.params.company;
    await this.fetchDefaultRole()
    if (this.id) {
      await this.fetchRole();
    } else {
      
      const acRoles = this.defaultRole.map(item => {
        const newItem = {
          ...item,
          action: `${item.action}:own`,
          attributes: '*'
        }
        return newItem
      });
      this.role = {
        name: '',
        acRoles
      }
    }
  },
  created () {},
  data () {
    return {
      id: null,
      companyId: null,
      role: { ...roleBase },
      defaultRole: {},
      loading: false,
      saving: false,
      showFormModal: false,
      resource: { 
        ...resourceBase
      },
      resourceList: [],
      acIndex: -1,
      showDeleteModal: false
    }
  },
  computed: {
    isCompleted () {
      return this.role.name;
    },
    isFormValid () {
      return this.resource.action && 
      this.resource.action &&
      this.resource.permission &&
      this.resource.attributes; 
    }
  },
  methods: {
    openAddModal() {
      this.resource = {
        ...resourceBase
      };
      this.showFormModal = true;
      this.acIndex = -1;
    },
    openDeleteModal(index) {
      this.acIndex = index;
      this.showDeleteModal = true;
    },
    getAction(action) {
      const sp = action.split(':');
      const strAction = sp[0];
      switch (strAction) {
        case 'read':
          return 'Lectura';
        case 'create':
          return 'Crear';
        case 'update':
          return 'Actualizar';
        case 'delete':
          return 'Eliminar'
        default:
          return 'Desconocido'
      }
    },
    getPermission(action) {
      const sp = action.split(':');
      const permission = sp[1] ?? 'unknow';
      switch (permission) {
        case 'any':
          return 'Todos'
        case 'own':
          return 'Propios'
        default:
          return 'Desconocido'
      }
    },
    openEditResource(item, index) {
      const { resource, action, attributes } = item;
      const sp = action.split(':');
      this.resource = {
        resource,
        attributes,
        action: sp[0],
        permission: sp[1]
      }
      this.showFormModal = true;
      this.acIndex = index;
    },
    saveResource() {
      if (this.acIndex !== -1) {
        this.updateResource()
      } else {
        this.addResource();
      }
      this.showFormModal = false;
      this.$validator.reset();
    },
    updateResource() {
      const { resource, action, permission, attributes } = this.resource;
      const updatedResource = {
        resource,
        action: `${action}:${permission}`,
        attributes
      }
      Vue.set(this.role.acRoles, this.acIndex, updatedResource);
    },
    addResource() {
      const { resource, action, permission, attributes } = this.resource;
      const newResource = {
        resource,
        action: `${action}:${permission}`,
        attributes
      }
      this.role.acRoles.push(newResource);
      this.resource = { ...resourceBase }
      this.showFormModal = false;
    },
    deleteResource() {
      this.role.acRoles.splice(this.acIndex, 1);
      this.showDeleteModal = false;
    },
    async saveRole() {
      if (this.id) {
        await this.updateRole();
      } else {
        await this.addRole();
      }
    },
    async addRole() {
      try {
        this.saving = true;
        await this.$http.post(`${constants.URL}/roles/${this.companyId}`, this.role)
        this.$router.push({ path: `/admin/workspaces/${this.companyId}`, query: { view: 'roles' } })
        this.saving = false;
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    async updateRole() {
      try {
        this.saving = true;
        const requestParams = {
          ...this.role
        };
        delete requestParams._id;
        delete requestParams.__v;
        delete requestParams.company;
        delete requestParams.default;
        await this.$http.put(`${constants.URL}/roles/${this.companyId}/${this.id}`, requestParams)
        this.$router.push({ path: `/admin/workspaces/${this.companyId}`, query: { view: 'roles' } })
        this.saving = false;
      } catch (error) {
        this.saving = false
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    async fetchRole() {
      try {
        const response = await this.$http.get(`${constants.URL}/roles/${this.companyId}/${this.id}`)
        const { result } = response.data
        this.role = result;
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    },
    async fetchDefaultRole() {
      try {
        const response = await this.$http.get(`${constants.URL}/roles/param/resources`)
        const { result } = response.data
        this.defaultRole = result;
        this.resourceList = [...new Set(result.map(item => item.resource))];
      } catch (error) {
        this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
      }
    }
  }
}
</script>
