<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card shipping-container">
        <!-- <div class="header">
          <h4 class="title">Crear Cotizacion</h4>
        </div> -->
        <div class="content">
          <div class="row">
            <div class="col-md-12" v-show="!hasClient">
              <div class="form-group">
                <label for="client-input">Cliente</label>
                <input autocomplete="off" id="client-input" class="form-control border-input" type="text"
                  placeholder="Buscar cliente..." />
                <typeahead v-model="client" target="#client-input" :async-function="getClients" force-select
                  @input="clientSelected" item-key="name" />
                <a class="cursor-pointer" @click="openAddNewClient()">+ Nuevo</a>
              </div>
            </div>
            
            <!-- client form -->
            <div v-show="hasClient">
              <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('name') }">
                  <label>Nombre del cliente</label>
                  <input v-model="client.name" class="form-control border-input" v-validate="'required'" name="name"
                    type="text" placeholder="Nombre" />
                  <span class="help-block text-left">{{ errors.first('name') }}</span>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('address') }">
                  <label>Direccion</label>
                  <input v-model="client.address" class="form-control border-input" name="address" type="text"
                    placeholder="Direccion" />
                  <span class="help-block text-left">{{ errors.first('address') }}</span>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('email') }">
                  <label>Correo electronico</label>
                  <input v-model="client.email" class="form-control border-input" name="email" type="email"
                    placeholder="Email" />
                  <span class="help-block text-left">{{ errors.first('email') }}</span>
                </div>
              </div> -->

              <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('phone') }">
                  <label>Telefono</label>
                  <input v-model="client.phone" class="form-control border-input" name="phone" type="number"
                    placeholder="Telefono" />
                  <span class="help-block text-left">{{ errors.first('phone') }}</span>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group" :class="{ 'has-error': errors.first('nit') }">
                  <label>Nit</label>
                  <input v-model="client.nit" class="form-control border-input" name="nit" type="text"
                    placeholder="Nit" />
                  <span class="help-block text-left">{{ errors.first('nit') }}</span>
                </div>
              </div> -->
              <div class="col-md-12 text-right manager-action">
                <a class="text-danger cursor-pointer pull-right" @click="removeClientSelected()">Remover</a>
                <br />
              </div>
            </div>
            <!-- fin client -->
            <div class="col-md-12">
              <div class="form-group" :class="{ 'has-error': errors.first('title') }">
                <label>Direccion</label>
                <form-textarea v-model="address" ref="formTextarea" :cssClass="`form-control border-input`" placeholder="direccion"></form-textarea>
                <span class="help-block text-left">{{ errors.first('title') }}</span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.first('title') }">
                <label>Repartidor</label>
                <input 
                  autocomplete="off" 
                  name="user-input"
                  id="user-input" 
                  class="form-control border-input" 
                  type="text"
                  placeholder="Buscar usuario..." />

                <typeahead 
                  v-model="courier" 
                  target="#user-input" 
                  :async-function="getUsers" 
                  force-select
                  @input="userSelected" 
                  item-key="name" />


                <span class="help-block text-left">{{ errors.first('user-input') }}</span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group" :class="{ 'has-error': errors.first('shippingCost') }">
                  <label>Costo de envio</label>
                  <input v-model="shippingCost" class="form-control border-input" name="shippingCost" type="number"
                    placeholder="Costo de envio" />
                  <span class="help-block text-left">{{ errors.first('shippingCost') }}</span>
                </div>
            </div>

            <div class="col-md-12">
              <div class="form-group" :class="{ 'has-error': errors.first('title') }">
                <label>Notas</label>
                <form-textarea v-model="notes" ref="formTextarea" :cssClass="`form-control border-input`" placeholder="Notes"></form-textarea>
                <span class="help-block text-left">{{ errors.first('title') }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <div>
                      <label>Solo envio <i v-tooltip.right="'No se necesita entregar productos ni realizar ningun cobro'" class="fa fa-question-circle text-default" /></label>
                    </div>
                    <btn-group>
                      <btn input-type="radio" :input-value="false" v-model="onlyShipping">
                        NO
                      </btn>
                      <btn input-type="radio" :input-value="true" v-model="onlyShipping">
                        SI
                      </btn>
                    </btn-group>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="!onlyShipping">
            <div class="col-md-12">
              <div class="form-group" >
                <label>Metodo de pago</label>
                <br />
                <label class="radio-inline" for="inlineRadio1">
                  <input type="radio" v-model="paymentMethod" 
                    name="paymentType" id="inlineRadio1" value="CASH"> Efectivo
                </label>
                <label class="radio-inline" for="inlineRadio2">
                  <input type="radio" v-model="paymentMethod" 
                    name="paymentType" id="inlineRadio2" value="CARD"> Pago con tarjeta
                </label>
                <label class="radio-inline" for="inlineRadio3">
                  <input type="radio" v-model="paymentMethod" 
                    name="paymentType" id="inlineRadio3" value="TRANSFER"> Transferencia
                </label>
              </div>
            </div>
            
            <div class="col-md-12" >
              <h3>Productos</h3>
              <hr>
            </div>

            <draggable :list="products" v-bind="dragOptions" ghost-class="ghost" handle=".grabbable">
              <div class="col-md-12 row" v-for="(field, index) in products" :key="index">
                <template>
                  <div class="col-md-4">
                    <span class="sort-icon ti-arrows-vertical grabbable"></span>
                    <div class="form-group">
                      <label v-bind:for="`input-product-${index}`">Producto</label>
                      <input autocomplete="off" v-bind:id="`input-product-${index}`" class="form-control border-input"
                        type="text" placeholder="Buscar producto..." />

                      <typeahead v-model="field.product" v-bind:target="`#input-product-${index}`"
                        :async-function="getProducts" force-select item-key="name" @input="setPrice($event, field)" />
                      <a class="cursor-pointer" @click="addNewProduct(index)">+Nuevo</a>
                    </div>
                  </div>
                  <div class="col-md-4" v-if="field.product">
                    <div class="form-group" :class="{ 'has-error': errors.first(`quantity-${index}`) }">
                      <label for="input-product">Cantidad</label>
                      <input class="form-control border-input" v-model="field.quantity" v-validate="'required'"
                        v-bind:name="`quantity-${index}`" type="number" placeholder="Nombre" />
                      <span class="help-block text-left">{{ errors.first(`quantity-${index}`) }}</span>
                    </div>
                  </div>

                  <div class="col-md-4" v-if="field.product">
                    <div class="form-group">
                      <label for="input-product">Precio</label>
                      <input v-model="field.price" class="form-control border-input" name="name" type="number"
                        placeholder="Nombre" />
                    </div>
                  </div>

                </template>
                <div class="col-md-12 text-right manager-action">
                  <div class="text-danger cursor-pointer" @click="removeField(index)">Remover</div>
                </div>
              </div>
            </draggable>

            <div class="col-md-12 add-field">
              <a class="pull-left cursor-pointer" @click="addField">
                <i class="ti-plus" /> Agregar
              </a>
            </div>

            </template>
            <div class="col-md-12">
              <btn class="pull-right cancel-btn" type="primary" :disabled="errors.any() || !isCompleted || saving"
                @click="save()">Guardar</btn>

              <router-link class="btn btn-default pull-right" :to="`/admin/shipping/list`">
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-model="modalRemove" title="Eliminar" :header="false" :footer="false" size="sm">
      Desea eliminar esta cotizacion?
      <div class="text-center">
        <br />
        <btn @click="modalRemove = false">Cancel</btn>
        <btn type="danger" @click="deleteClient()">Eliminar</btn>
      </div>
    </modal>

    <client-form v-model="client" :modal-client="false" ref="modalClient" @saveClient="saveClient" />
    <product-form v-model="client" :modal-prop="false" ref="modalProduct" @save="saveProduct" />

  </div>
</template>
<style lang="scss" scoped>
.cancel-btn {
  margin-left: 10px;
}

.field-container {
  padding: 15px;
}

.shipping-container {
  padding-left: 20px;
  padding-right: 20px;
}

.sort-icon {
  position: absolute;
  top: 4px;
  left: -3px;
  font-weight: 400;
  color: #9A9A9A;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.radio-inline{
  color: rgba(0, 0, 0, 0.3);
}
</style>

<script>
import constants from '../../constants'
import { cloneDeep } from 'lodash'
import { Modal, Btn, Typeahead } from 'uiv'
import axios from 'axios'
import draggable from 'vuedraggable'
//import Vue from 'vue'

import ClientForm from '../../components/Forms/ClientForm.vue'
import ProductForm from '../../components/Forms/ProductForm.vue'
import { text2HTML } from '../../utils'
import formTextarea from '@/components/Inputs/formTextarea.vue'

const { convert } = require('html-to-text');

const productSchema = {
  product: null,
  price: 0,
  quantity: 1
}

const manager = {
  name: '',
  position: '',
  email: '',
  phone: ''
}
const client = {
  name: '',
  descripcion: '',
  email: '',
  phone: '',
  address: '',
  nit: '',
  type: 'PERSON',
  managers: [{ ...manager }]
}

let productIndex = null

export default {
  components: {
    Modal,
    Btn,
    Typeahead,
    ClientForm,
    ProductForm,
    draggable,
    formTextarea
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.hasClient = true;
      this.$validator.reset()
      this.fetchShipping()
    }
  },
  created() { },
  data() {
    return {
      id: null,
      modalRemove: false,
      saving: false,
      client: { ...client },
      products: [],
      address: '',
      notes: '',
      shippingCost: 0,
      proposalShippingCost: 0,
      paymentMethod: 'CASH',
      hasClient: false,
      dragging: false,
      onlyShipping: false,
      fieldIndex: 0,
      courier: {
        name: ''
      }
    }
  },
  computed: {
    defaultCompany() {
      return this.$store.state.company;
    },
    isCompleted() {
      if (this.onlyShipping) {
        return this.courier.name && this.address && this.client;
      }

      return this.address && 
      this.products.length > 0 && 
      this.courier;
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        direction: 'horizontal'
      };
    }
  },
  watch: {
    defaultCompany(newCount, oldCount) {
      if (newCount !== oldCount) {
        this.$router.replace('/admin/shipping/list')
      }
    }
  },
  methods: {
    showMediaSelect(index) {
      this.fieldIndex = index;
      this.$refs.mediaList.show()
    },
    openAddNewClient() {
      this.$refs.modalClient.show('ADD')
    },
    saveClient(client) {
      this.hasClient = true
      this.client = { ...client }
    },
    getClients(query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/clients`, { params })
        .then(response => {
          done(response.data.result)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    clientSelected(item) {
      if (item) {
        this.hasClient = true
        this.client = { ...item }
        this.address = this.client.address
      }
    },
    removeClientSelected() {
      this.hasClient = false
      this.client = { ...client }
      this.address = ''
    },
    addNewProduct(index) {
      productIndex = index
      this.$refs.modalProduct.show('ADD')
    },
    saveProduct(product) {
      this.products[productIndex].product = { ...product }
      this.products[productIndex].price = product.price
    },
    async fetchShipping() {
      try {
        const response = await axios.get(`${constants.URL}/shipping/find/${this.id}`)
        const shipping = response.data
        this.client = shipping.client
        this.address = shipping.address
        this.notes = shipping.notes
        this.courier = {
          ...shipping.courier,
          name: shipping.courier.user.firstName + ' ' + shipping.courier.user.lastName
        }
        this.products = shipping.products;
        this.makeInputsProduct(this.products)
      } catch (error) {

        this.$notifications.notifyVue(`Error: ${error}`, 'danger')
      }

    },
    makeInputsProduct(products) { // bug - typeahead not work inside a loop
      setTimeout(() => {
        products.forEach((val, index) => {
            let el = document.querySelector(`#input-product-${index}`)
            el.value = val.product.name
        })
      }, 500)
    },
    getProducts(query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/products`, { params })
        .then(response => {
          done(response.data.result)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    },
    save() {
      this.saving = true
      if (this.id) {
        this.update()
      } else {
        this.add()
      }
    },
    async update() {

      const client = {
        ...this.client
      }
      delete client.company;
      delete client.owner;
      const fieldInputs = cloneDeep(this.products);

      const products = fieldInputs.map(this.formatProductField);

      const req = {
        client,
        products,
        courier: this.courier._id,
        address: this.address,
        notes: this.notes,
        onlyShipping: this.onlyShipping,
      }
      axios
        .put(`${constants.URL}/shipping/${this.id}`, req)
        .then(() => {
          //const id = result.data._id
          this.saving = false
          this.$router.replace(`/admin/shipping/list`)
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    async add() {
      const client = {
        ...this.client
      }
      delete client.company;
      delete client.owner;
      const fieldInputs = cloneDeep(this.products);

      const products = fieldInputs.map(this.formatProductField);

      const req = {
        client,
        products,
        courier: this.courier._id,
        address: this.address,
        shippingCost: this.shippingCost,
        paymentMethod: this.paymentMethod,
        onlyShipping: this.onlyShipping,
        notes: this.notes,
      }
      axios
        .post(`${constants.URL}/shipping`, req)
        .then(() => {
          //const id = result.data._id
          this.saving = false
          this.$router.replace(`/admin/shipping/list`)
        })
        .catch(error => {
          this.saving = false
          this.$notifications.notifyVue(`Ocurrio un error <b>${error}</b>`, 'danger')
        })
    },
    setPrice(event, field) {
      if (event) {
        field.price = event.price;
        const options = {
          wordwrap: 130
        };
        field.product.description = convert(field.product.description, options)
      }
    },
    addField() {
      this.products.push({ ...productSchema })
    },
    removeField(index) {
      this.products.splice(index, 1)
    },
    formatProductField(field) {
      delete field.product._id;
      delete field.product.__v;
      delete field.product.company;
      delete field.product.owner;
      delete field.product.imagenPath
      field.product.description = text2HTML(field.product.description)
      delete field._id
      return field
    },

    //
    userSelected (courier) {
      this.courier = courier
      console.log(courier)
    },
    getUsers (query, done) {
      const params = {
        q: query
      }
      axios
        .get(`${constants.URL}/shipping/couriers`, { params })
        .then(response => {
          const users = response.data.result
          const data = users.map(courier => {
            return {
              ...courier,
              name: courier.user.firstName + ' ' + courier.user.lastName
            }
          })
          done(data)
        })
        .catch(error => {
          this.$notifications.notifyVue(`Error: ${error}`, 'danger')
        })
    }
  }
}
</script>
