<template>
  <div :class="{'nav-open': $sidebar.showSidebar}" style="height: 100%;">
    <router-view></router-view>
    <!--This sidebar appears only for screens smaller than 992px-->
    <side-bar type="navbar" :sidebar-links="$sidebar.sidebarLinks">
    </side-bar>
  </div>
</template>

<script>
  import '../static/css/themify-icons.css'
  import '../static/css/font-awesome.min.css';
  export default {}
</script>

<style lang="scss"></style>
