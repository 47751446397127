<template>
    <div class="row">
      <div class="col-md-7 text-left">
        <p class="category text-left"> 
          <router-link class="btn btn-info" :to="{ path: '/user/create', query: { isNew: true }}">
            <i class="ti-plus"></i> Crear Usuario
          </router-link>
        </p>
      </div>

      <div class="col-lg-12 col-md-7">
        <div class="card">
          <table class="table table-hover quote-table">
            <thead>
              <tr>
                <th class="title">Email</th>
                <th class="hidden-xs" >Nombre</th>
                <th class="hidden-xs">Apellido</th>
                <th class="total hidden-xs" >super usuario</th>
                <th class="total">Rol</th>
                <th>
                  <span class="hidden-xs">
                    Acciones
                  </span>
                  <span class="visible-ms">
                    <i class="ti-settings"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <div v-if="loading">
                <tr> 
                  <td>Loading... </td>
                </tr>
              </div>
              <tr v-else v-for="(user, index) in users" :key="index">
                <td> {{ user.username }} </td>
                <td class="hidden-xs"> {{ user.firstName }} </td>
                <td class="hidden-xs"> {{ user.lastName }} </td>
                <td class="hidden-xs"> {{ user.isRoot }} </td>
                <td> {{ user.roles.join(',') }} </td>
                <td>
                  <router-link class="text-info" :to="`/user/create/${user._id}`">
                    <i class="ti-pencil-alt"></i>
                  </router-link>
                  <a class="text-danger cursor-pointer" @click="openDeleteClient(index)">
                    <i class="ti-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>
<script>

  import axios from 'axios'
  import constants from '../constants'

  export default {
    components: {},
    created: function () {
      this.fetchUser()
    },
    data () {
      return {
        users: [],
        loading: false,
        limit: 10,
        totalPage: 0,
        currentPage: 1,
        totalCount: 0,
        searchModel: ''
      }
    },
    methods: {
      fetchUser () {
        this.loading = true
        const params = {
          skip: (this.currentPage - 1) * this.limit,
          take: this.limit
        }
        if (this.searchModel) {
          params.q = this.searchModel
        }
        axios
          .get(`${constants.URL}/users`, { params })
          .then(response => {
            this.totalCount = response.data.count
            this.totalPage = Math.ceil(this.totalCount / this.limit)
            this.users = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.$notifications.notifyVue(`Error: ${error}`, '')
          })
      }
    }
  }

</script>
<style>

</style>
