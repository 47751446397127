import Notifications from './Notifications.vue'

const NotificationStore = {
  state: [], // here the notifications will be added

  removeNotification (index) {
    this.state.splice(index, 1)
  },
  notify (notification) {
    this.state.push(notification)
  },
  notifyVue (message, type) {
    let icon = ''
    switch (type) {
      case 'success':
        icon = 'ti-check'
        break
      case 'danger':
        icon = 'ti-na'
        break
    }

    this.notify({
      message,
      icon,
      horizontalAlign: 'right',
      verticalAlign: 'top',
      type
    })
  }
}

var NotificationsPlugin = {

  install (Vue) {
    Object.defineProperty(Vue.prototype, '$notifications', {
      get () {
        return NotificationStore
      }
    })
    Vue.component('Notifications', Notifications)
  }
}

export default NotificationsPlugin
