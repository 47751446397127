import axios from 'axios'
import constants from '../../constants'
import { updateWorkspaceList } from '../actions/updateWorkspaceList'

const actions = {
  updateWorkspaceList,
  validateInvite({ commit }, token) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.get(`${constants.URL}/company/users/validate/${token}`)
        .then(resp => {
          commit('set_invite_token', token);
          localStorage.setItem('inviteToken', token)
          resolve(resp.data)
        })
        .catch(err => {
          commit('set_invite_token', '')
          reject(err)
        })
    })
  },
  confirmInviteToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post(`${constants.URL}/company/users/confirm/${token}`)
        .then(resp => {
          commit('set_invite_token', '');
          localStorage.removeItem('inviteToken');
          resolve(resp.data)
        })
        .catch(err => {
          commit('set_invite_token', '');
          localStorage.removeItem('inviteToken');
          reject(err)
        })
    })
  },
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post(`${constants.URL}/auth/login`, user)
        .then(resp => {
          const token = resp.data.token
          const company = resp.data.company
          // Add the following line:
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          axios.defaults.headers.common['Company'] = company
          commit('auth_success', token)
          commit('update_company', company)
          return axios.get(`${constants.URL}/roles/find/current/${company}`);
        })
        .then(resp => {
          const role = resp.data.result;
          const view = role.view;
          commit('update_view', JSON.stringify(view));
          resolve(role)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
    })
  },
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post(`${constants.URL}/auth/register`, user)
        .then(resp => {
          const token = resp.data.token
          const company = resp.data.company
          // Add the following line:
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          axios.defaults.headers.common['Company'] = company
          commit('auth_success', token)
          commit('update_company', company)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err)
          localStorage.removeItem('token')
          localStorage.removeItem('company')
          reject(err)
        })
    })
  },
  updateCompany({ commit }, company) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.get(`${constants.URL}/roles/find/current/${company}`)
        .then(resp => {
          const role = resp.data.result;
          const view = role.view;
          axios.defaults.headers.common['Company'] = company;
          localStorage.setItem('company', company);
          commit('update_company', company);
          commit('update_view', JSON.stringify(view));
          resolve(resp)
        })
        .catch(err => {
          axios.defaults.headers.common['Company'] = company;
          localStorage.setItem('company', company);
          commit('update_company', company);
          reject(err)
        })
    })
  },
  setDefaulCompany({ commit }, company) {
    return new Promise((resolve, reject) => {
      axios.post(`${constants.URL}/users/workspace`, { workspace: company })
        .then(resp => {
          const { workspace } = resp.data;
          axios.defaults.headers.common['Company'] = workspace;
          localStorage.setItem('company', workspace);
          commit('update_company', workspace);
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('view')
      localStorage.removeItem('token')
      localStorage.removeItem('company')
      delete axios.defaults.headers.common['Authorization']
      delete axios.defaults.headers.common['Company']
      resolve()
    })
  },
  setContentSize({ commit }, action) {
    commit('content_size', action);
  }
};

export default actions;