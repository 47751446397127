import Vue from 'vue'

// import Vuex from 'vuex'
import VueRouter from 'vue-router'
import vClickOutside from 'v-click-outside'

// Plugins
import GlobalComponents from './gloablComponents'
import Notifications from './components/NotificationPlugin'
import SideBar from './components/SidebarPlugin'
import App from './App'

// router setup
import router from './routes'

// library imports
import Chartist from 'chartist'
import VeeValidate from 'vee-validate';
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.css'
import './assets/sass/paper-dashboard.scss'
import Axios from 'axios'
import store from './store/store'
import * as uiv from 'uiv'

// import * as firebase from 'firebase/app'
// import '@firebase/messaging'

// plugin setup 35.185.41.4
Vue.config.productionTip = false;
Vue.use(VueRouter)
Vue.use(GlobalComponents)
Vue.use(vClickOutside)
Vue.use(Notifications)
Vue.use(SideBar)
Vue.use(VeeValidate)
Vue.use(uiv)

// global library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
  get () {
    return this.$root.Chartist
  }
})
Object.defineProperty(Vue.prototype, '$http', {
  get () {
    return Axios
  }
})

Axios.interceptors.response.use(response => response, error => {
  if (error.response.data.statusCode === 401) {
    store.dispatch('logout')
    router.push('/login')
  }
  return Promise.reject(error)
})

const token = localStorage.getItem('token')
const company = localStorage.getItem('company')
if (token && company) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`
  Vue.prototype.$http.defaults.headers.common['Company'] = company
}

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GTQ',
    minimumFractionDigits: 2
  })
  return formatter.format(value).replace('GTQ', 'Q.')
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY hh:mm')
  }
})

Vue.filter('formatDateNoTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
})
// const firebaseConfig = {
//   apiKey: 'AIzaSyAG2EP6PXbTRwIu78YC9yCcdicv_7dePgc',
//   authDomain: 'crackling-fire-2277.firebaseapp.com',
//   databaseURL: 'https://crackling-fire-2277.firebaseio.com',
//   projectId: 'crackling-fire-2277',
//   storageBucket: 'crackling-fire-2277.appspot.com',
//   messagingSenderId: '967400665324',
//   appId: '1:967400665324:web:9a9e738abbb11e2cd5f5c9'
// }
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig)
// Vue.prototype.$messaging = firebase.messaging()
// Vue.prototype.$messaging.usePublicVapidKey('BCS2EjcNBUIZRj1BrmqH6c6-nKD19BG1cIOVjRNScstzsXLwuHG_MZTvjYHj4qJ2vYLqBNFHRMdr5dqtb-Ny7lM')

// navigator.serviceWorker.register('/static/firebase-messaging-sw.js')
//   .then((registration) => {
//     Vue.prototype.$messaging.useServiceWorker(registration)
//   }).catch(err => {
//     console.log(err)
//   })

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router,
  data: {
    Chartist: Chartist
  },
  methods: {},
  created () {
    // const messaging = firebase.messaging()

    // messaging
    // .requestPermission()
    // .then(() => firebase.messaging().getToken())
    // .then((token) => {
    //   console.log(token) // Receiver Token to use in the notification
    // })
    // .catch(function (err) {
    //   console.log('Unable to get permission to notify.', err)
    // })

    // messaging.onMessage(function (payload) {
    //   console.log('Message received. ', payload)
    // // ...
    // })
  }
})
